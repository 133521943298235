[data-theme='dark'] {
  background: #222;
  color: #f8f9fa; }
  [data-theme='dark'] :root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #3A52A2;
    --secondary: #6c757d;
    --success: #3AB54A;
    --info: #25AAE2;
    --warning: #FFE89B;
    --danger: #BF1E2E;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Open Sans", sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }
  [data-theme='dark'] *,
  [data-theme='dark'] *::before,
  [data-theme='dark'] *::after {
    box-sizing: border-box; }
  [data-theme='dark'] html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  [data-theme='dark'] article, [data-theme='dark'] aside, [data-theme='dark'] figcaption, [data-theme='dark'] figure, [data-theme='dark'] footer, [data-theme='dark'] header, [data-theme='dark'] hgroup, [data-theme='dark'] main, [data-theme='dark'] nav, [data-theme='dark'] section {
    display: block; }
  [data-theme='dark'] body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 1.5;
    color: #d3d3d3;
    text-align: left;
    background-color: #191d21; }
  [data-theme='dark'] [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important; }
  [data-theme='dark'] hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible; }
  [data-theme='dark'] h1, [data-theme='dark'] h2, [data-theme='dark'] h3, [data-theme='dark'] h4, [data-theme='dark'] h5, [data-theme='dark'] h6 {
    margin-top: 0;
    margin-bottom: 0.5rem; }
  [data-theme='dark'] p {
    margin-top: 0;
    margin-bottom: 1rem; }
  [data-theme='dark'] abbr[title],
  [data-theme='dark'] abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none; }
  [data-theme='dark'] address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit; }
  [data-theme='dark'] ol,
  [data-theme='dark'] ul,
  [data-theme='dark'] dl {
    margin-top: 0;
    margin-bottom: 1rem; }
  [data-theme='dark'] ol ol,
  [data-theme='dark'] ul ul,
  [data-theme='dark'] ol ul,
  [data-theme='dark'] ul ol {
    margin-bottom: 0; }
  [data-theme='dark'] dt {
    font-weight: 700; }
  [data-theme='dark'] dd {
    margin-bottom: .5rem;
    margin-left: 0; }
  [data-theme='dark'] blockquote {
    margin: 0 0 1rem; }
  [data-theme='dark'] b,
  [data-theme='dark'] strong {
    font-weight: bolder; }
  [data-theme='dark'] small {
    font-size: 80%; }
  [data-theme='dark'] sub,
  [data-theme='dark'] sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline; }
  [data-theme='dark'] sub {
    bottom: -.25em; }
  [data-theme='dark'] sup {
    top: -.5em; }
  [data-theme='dark'] a {
    color: #adadad;
    text-decoration: none;
    background-color: transparent; }
    [data-theme='dark'] a:hover {
      color: #878787;
      text-decoration: underline; }
  [data-theme='dark'] a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none; }
    [data-theme='dark'] a:not([href]):not([class]):hover {
      color: inherit;
      text-decoration: none; }
  [data-theme='dark'] pre,
  [data-theme='dark'] code,
  [data-theme='dark'] kbd,
  [data-theme='dark'] samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em; }
  [data-theme='dark'] pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar; }
  [data-theme='dark'] figure {
    margin: 0 0 1rem; }
  [data-theme='dark'] img {
    vertical-align: middle;
    border-style: none; }
  [data-theme='dark'] svg {
    overflow: hidden;
    vertical-align: middle; }
  [data-theme='dark'] table {
    border-collapse: collapse; }
  [data-theme='dark'] caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom; }
  [data-theme='dark'] th {
    text-align: inherit;
    text-align: -webkit-match-parent; }
  [data-theme='dark'] label {
    display: inline-block;
    margin-bottom: 0.5rem; }
  [data-theme='dark'] button {
    border-radius: 0; }
  [data-theme='dark'] button:focus:not(:focus-visible) {
    outline: 0; }
  [data-theme='dark'] input,
  [data-theme='dark'] button,
  [data-theme='dark'] select,
  [data-theme='dark'] optgroup,
  [data-theme='dark'] textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  [data-theme='dark'] button,
  [data-theme='dark'] input {
    overflow: visible; }
  [data-theme='dark'] button,
  [data-theme='dark'] select {
    text-transform: none; }
  [data-theme='dark'] [role="button"] {
    cursor: pointer; }
  [data-theme='dark'] select {
    word-wrap: normal; }
  [data-theme='dark'] button,
  [data-theme='dark'] [type="button"],
  [data-theme='dark'] [type="reset"],
  [data-theme='dark'] [type="submit"] {
    -webkit-appearance: button; }
  [data-theme='dark'] button:not(:disabled),
  [data-theme='dark'] [type="button"]:not(:disabled),
  [data-theme='dark'] [type="reset"]:not(:disabled),
  [data-theme='dark'] [type="submit"]:not(:disabled) {
    cursor: pointer; }
  [data-theme='dark'] button::-moz-focus-inner,
  [data-theme='dark'] [type="button"]::-moz-focus-inner,
  [data-theme='dark'] [type="reset"]::-moz-focus-inner,
  [data-theme='dark'] [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none; }
  [data-theme='dark'] input[type="radio"],
  [data-theme='dark'] input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0; }
  [data-theme='dark'] textarea {
    overflow: auto;
    resize: vertical; }
  [data-theme='dark'] fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }
  [data-theme='dark'] legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal; }
  [data-theme='dark'] progress {
    vertical-align: baseline; }
  [data-theme='dark'] [type="number"]::-webkit-inner-spin-button,
  [data-theme='dark'] [type="number"]::-webkit-outer-spin-button {
    height: auto; }
  [data-theme='dark'] [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none; }
  [data-theme='dark'] [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }
  [data-theme='dark'] ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button; }
  [data-theme='dark'] output {
    display: inline-block; }
  [data-theme='dark'] summary {
    display: list-item;
    cursor: pointer; }
  [data-theme='dark'] template {
    display: none; }
  [data-theme='dark'] [hidden] {
    display: none !important; }
  [data-theme='dark'] h1, [data-theme='dark'] h2, [data-theme='dark'] h3, [data-theme='dark'] h4, [data-theme='dark'] h5, [data-theme='dark'] h6,
  [data-theme='dark'] .h1, [data-theme='dark'] .h2, [data-theme='dark'] .h3, [data-theme='dark'] .h4, [data-theme='dark'] .h5, [data-theme='dark'] .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2; }
  [data-theme='dark'] h1, [data-theme='dark'] .h1 {
    font-size: 2.125rem; }
  [data-theme='dark'] h2, [data-theme='dark'] .h2 {
    font-size: 1.7rem; }
  [data-theme='dark'] h3, [data-theme='dark'] .h3 {
    font-size: 1.4875rem; }
  [data-theme='dark'] h4, [data-theme='dark'] .h4 {
    font-size: 1.275rem; }
  [data-theme='dark'] h5, [data-theme='dark'] .h5 {
    font-size: 1.0625rem; }
  [data-theme='dark'] h6, [data-theme='dark'] .h6 {
    font-size: 0.85rem; }
  [data-theme='dark'] .lead {
    font-size: 1.0625rem;
    font-weight: 300; }
  [data-theme='dark'] .display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2; }
  [data-theme='dark'] .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2; }
  [data-theme='dark'] .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2; }
  [data-theme='dark'] .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2; }
  [data-theme='dark'] hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  [data-theme='dark'] small,
  [data-theme='dark'] .small {
    font-size: 0.875em;
    font-weight: 400; }
  [data-theme='dark'] mark,
  [data-theme='dark'] .mark {
    padding: 0.2em;
    background-color: #fcf8e3; }
  [data-theme='dark'] .list-unstyled {
    padding-left: 0;
    list-style: none; }
  [data-theme='dark'] .list-inline {
    padding-left: 0;
    list-style: none; }
  [data-theme='dark'] .list-inline-item {
    display: inline-block; }
    [data-theme='dark'] .list-inline-item:not(:last-child) {
      margin-right: 0.5rem; }
  [data-theme='dark'] .initialism {
    font-size: 90%;
    text-transform: uppercase; }
  [data-theme='dark'] .blockquote {
    margin-bottom: 1rem;
    font-size: 1.0625rem; }
  [data-theme='dark'] .blockquote-footer {
    display: block;
    font-size: 0.875em;
    color: #6c757d; }
    [data-theme='dark'] .blockquote-footer::before {
      content: "\2014\00A0"; }
  [data-theme='dark'] .img-fluid {
    max-width: 100%;
    height: auto; }
  [data-theme='dark'] .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    max-width: 100%;
    height: auto; }
  [data-theme='dark'] .figure {
    display: inline-block; }
  [data-theme='dark'] .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1; }
  [data-theme='dark'] .figure-caption {
    font-size: 90%;
    color: #6c757d; }
  [data-theme='dark'] code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word; }
    a > [data-theme='dark'] code {
      color: inherit; }
  [data-theme='dark'] kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
    box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
    [data-theme='dark'] kbd kbd {
      padding: 0;
      font-size: 100%;
      font-weight: 700;
      box-shadow: none; }
  [data-theme='dark'] pre {
    display: block;
    font-size: 87.5%;
    color: #212529; }
    [data-theme='dark'] pre code {
      font-size: inherit;
      color: inherit;
      word-break: normal; }
  [data-theme='dark'] .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll; }
  [data-theme='dark'] .container,
  [data-theme='dark'] .container-fluid,
  [data-theme='dark'] .container-sm,
  [data-theme='dark'] .container-md,
  [data-theme='dark'] .container-lg,
  [data-theme='dark'] .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  @media (min-width: 576px) {
    [data-theme='dark'] .container, [data-theme='dark'] .container-sm {
      max-width: 540px; } }
  @media (min-width: 768px) {
    [data-theme='dark'] .container, [data-theme='dark'] .container-sm, [data-theme='dark'] .container-md {
      max-width: 720px; } }
  @media (min-width: 992px) {
    [data-theme='dark'] .container, [data-theme='dark'] .container-sm, [data-theme='dark'] .container-md, [data-theme='dark'] .container-lg {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    [data-theme='dark'] .container, [data-theme='dark'] .container-sm, [data-theme='dark'] .container-md, [data-theme='dark'] .container-lg, [data-theme='dark'] .container-xl {
      max-width: 1140px; } }
  [data-theme='dark'] .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  [data-theme='dark'] .no-gutters {
    margin-right: 0;
    margin-left: 0; }
    [data-theme='dark'] .no-gutters > .col,
    [data-theme='dark'] .no-gutters > [class*="col-"] {
      padding-right: 0;
      padding-left: 0; }
  [data-theme='dark'] .col-1, [data-theme='dark'] .col-2, [data-theme='dark'] .col-3, [data-theme='dark'] .col-4, [data-theme='dark'] .col-5, [data-theme='dark'] .col-6, [data-theme='dark'] .col-7, [data-theme='dark'] .col-8, [data-theme='dark'] .col-9, [data-theme='dark'] .col-10, [data-theme='dark'] .col-11, [data-theme='dark'] .col-12, [data-theme='dark'] .col,
  [data-theme='dark'] .col-auto, [data-theme='dark'] .col-sm-1, [data-theme='dark'] .col-sm-2, [data-theme='dark'] .col-sm-3, [data-theme='dark'] .col-sm-4, [data-theme='dark'] .col-sm-5, [data-theme='dark'] .col-sm-6, [data-theme='dark'] .col-sm-7, [data-theme='dark'] .col-sm-8, [data-theme='dark'] .col-sm-9, [data-theme='dark'] .col-sm-10, [data-theme='dark'] .col-sm-11, [data-theme='dark'] .col-sm-12, [data-theme='dark'] .col-sm,
  [data-theme='dark'] .col-sm-auto, [data-theme='dark'] .col-md-1, [data-theme='dark'] .col-md-2, [data-theme='dark'] .col-md-3, [data-theme='dark'] .col-md-4, [data-theme='dark'] .col-md-5, [data-theme='dark'] .col-md-6, [data-theme='dark'] .col-md-7, [data-theme='dark'] .col-md-8, [data-theme='dark'] .col-md-9, [data-theme='dark'] .col-md-10, [data-theme='dark'] .col-md-11, [data-theme='dark'] .col-md-12, [data-theme='dark'] .col-md,
  [data-theme='dark'] .col-md-auto, [data-theme='dark'] .col-lg-1, [data-theme='dark'] .col-lg-2, [data-theme='dark'] .col-lg-3, [data-theme='dark'] .col-lg-4, [data-theme='dark'] .col-lg-5, [data-theme='dark'] .col-lg-6, [data-theme='dark'] .col-lg-7, [data-theme='dark'] .col-lg-8, [data-theme='dark'] .col-lg-9, [data-theme='dark'] .col-lg-10, [data-theme='dark'] .col-lg-11, [data-theme='dark'] .col-lg-12, [data-theme='dark'] .col-lg,
  [data-theme='dark'] .col-lg-auto, [data-theme='dark'] .col-xl-1, [data-theme='dark'] .col-xl-2, [data-theme='dark'] .col-xl-3, [data-theme='dark'] .col-xl-4, [data-theme='dark'] .col-xl-5, [data-theme='dark'] .col-xl-6, [data-theme='dark'] .col-xl-7, [data-theme='dark'] .col-xl-8, [data-theme='dark'] .col-xl-9, [data-theme='dark'] .col-xl-10, [data-theme='dark'] .col-xl-11, [data-theme='dark'] .col-xl-12, [data-theme='dark'] .col-xl,
  [data-theme='dark'] .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
  [data-theme='dark'] .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  [data-theme='dark'] .row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  [data-theme='dark'] .row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  [data-theme='dark'] .row-cols-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  [data-theme='dark'] .row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  [data-theme='dark'] .row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  [data-theme='dark'] .row-cols-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  [data-theme='dark'] .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  [data-theme='dark'] .col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  [data-theme='dark'] .col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  [data-theme='dark'] .col-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  [data-theme='dark'] .col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  [data-theme='dark'] .col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  [data-theme='dark'] .col-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  [data-theme='dark'] .col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  [data-theme='dark'] .col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  [data-theme='dark'] .col-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  [data-theme='dark'] .col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  [data-theme='dark'] .col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  [data-theme='dark'] .col-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  [data-theme='dark'] .order-first {
    order: -1; }
  [data-theme='dark'] .order-last {
    order: 13; }
  [data-theme='dark'] .order-0 {
    order: 0; }
  [data-theme='dark'] .order-1 {
    order: 1; }
  [data-theme='dark'] .order-2 {
    order: 2; }
  [data-theme='dark'] .order-3 {
    order: 3; }
  [data-theme='dark'] .order-4 {
    order: 4; }
  [data-theme='dark'] .order-5 {
    order: 5; }
  [data-theme='dark'] .order-6 {
    order: 6; }
  [data-theme='dark'] .order-7 {
    order: 7; }
  [data-theme='dark'] .order-8 {
    order: 8; }
  [data-theme='dark'] .order-9 {
    order: 9; }
  [data-theme='dark'] .order-10 {
    order: 10; }
  [data-theme='dark'] .order-11 {
    order: 11; }
  [data-theme='dark'] .order-12 {
    order: 12; }
  [data-theme='dark'] .offset-1 {
    margin-left: 8.33333%; }
  [data-theme='dark'] .offset-2 {
    margin-left: 16.66667%; }
  [data-theme='dark'] .offset-3 {
    margin-left: 25%; }
  [data-theme='dark'] .offset-4 {
    margin-left: 33.33333%; }
  [data-theme='dark'] .offset-5 {
    margin-left: 41.66667%; }
  [data-theme='dark'] .offset-6 {
    margin-left: 50%; }
  [data-theme='dark'] .offset-7 {
    margin-left: 58.33333%; }
  [data-theme='dark'] .offset-8 {
    margin-left: 66.66667%; }
  [data-theme='dark'] .offset-9 {
    margin-left: 75%; }
  [data-theme='dark'] .offset-10 {
    margin-left: 83.33333%; }
  [data-theme='dark'] .offset-11 {
    margin-left: 91.66667%; }
  @media (min-width: 576px) {
    [data-theme='dark'] .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    [data-theme='dark'] .row-cols-sm-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    [data-theme='dark'] .row-cols-sm-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    [data-theme='dark'] .row-cols-sm-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    [data-theme='dark'] .row-cols-sm-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    [data-theme='dark'] .row-cols-sm-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    [data-theme='dark'] .row-cols-sm-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    [data-theme='dark'] .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    [data-theme='dark'] .col-sm-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    [data-theme='dark'] .col-sm-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    [data-theme='dark'] .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    [data-theme='dark'] .col-sm-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    [data-theme='dark'] .col-sm-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    [data-theme='dark'] .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    [data-theme='dark'] .col-sm-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    [data-theme='dark'] .col-sm-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    [data-theme='dark'] .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    [data-theme='dark'] .col-sm-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    [data-theme='dark'] .col-sm-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    [data-theme='dark'] .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    [data-theme='dark'] .order-sm-first {
      order: -1; }
    [data-theme='dark'] .order-sm-last {
      order: 13; }
    [data-theme='dark'] .order-sm-0 {
      order: 0; }
    [data-theme='dark'] .order-sm-1 {
      order: 1; }
    [data-theme='dark'] .order-sm-2 {
      order: 2; }
    [data-theme='dark'] .order-sm-3 {
      order: 3; }
    [data-theme='dark'] .order-sm-4 {
      order: 4; }
    [data-theme='dark'] .order-sm-5 {
      order: 5; }
    [data-theme='dark'] .order-sm-6 {
      order: 6; }
    [data-theme='dark'] .order-sm-7 {
      order: 7; }
    [data-theme='dark'] .order-sm-8 {
      order: 8; }
    [data-theme='dark'] .order-sm-9 {
      order: 9; }
    [data-theme='dark'] .order-sm-10 {
      order: 10; }
    [data-theme='dark'] .order-sm-11 {
      order: 11; }
    [data-theme='dark'] .order-sm-12 {
      order: 12; }
    [data-theme='dark'] .offset-sm-0 {
      margin-left: 0; }
    [data-theme='dark'] .offset-sm-1 {
      margin-left: 8.33333%; }
    [data-theme='dark'] .offset-sm-2 {
      margin-left: 16.66667%; }
    [data-theme='dark'] .offset-sm-3 {
      margin-left: 25%; }
    [data-theme='dark'] .offset-sm-4 {
      margin-left: 33.33333%; }
    [data-theme='dark'] .offset-sm-5 {
      margin-left: 41.66667%; }
    [data-theme='dark'] .offset-sm-6 {
      margin-left: 50%; }
    [data-theme='dark'] .offset-sm-7 {
      margin-left: 58.33333%; }
    [data-theme='dark'] .offset-sm-8 {
      margin-left: 66.66667%; }
    [data-theme='dark'] .offset-sm-9 {
      margin-left: 75%; }
    [data-theme='dark'] .offset-sm-10 {
      margin-left: 83.33333%; }
    [data-theme='dark'] .offset-sm-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 768px) {
    [data-theme='dark'] .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    [data-theme='dark'] .row-cols-md-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    [data-theme='dark'] .row-cols-md-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    [data-theme='dark'] .row-cols-md-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    [data-theme='dark'] .row-cols-md-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    [data-theme='dark'] .row-cols-md-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    [data-theme='dark'] .row-cols-md-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    [data-theme='dark'] .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    [data-theme='dark'] .col-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    [data-theme='dark'] .col-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    [data-theme='dark'] .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    [data-theme='dark'] .col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    [data-theme='dark'] .col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    [data-theme='dark'] .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    [data-theme='dark'] .col-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    [data-theme='dark'] .col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    [data-theme='dark'] .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    [data-theme='dark'] .col-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    [data-theme='dark'] .col-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    [data-theme='dark'] .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    [data-theme='dark'] .order-md-first {
      order: -1; }
    [data-theme='dark'] .order-md-last {
      order: 13; }
    [data-theme='dark'] .order-md-0 {
      order: 0; }
    [data-theme='dark'] .order-md-1 {
      order: 1; }
    [data-theme='dark'] .order-md-2 {
      order: 2; }
    [data-theme='dark'] .order-md-3 {
      order: 3; }
    [data-theme='dark'] .order-md-4 {
      order: 4; }
    [data-theme='dark'] .order-md-5 {
      order: 5; }
    [data-theme='dark'] .order-md-6 {
      order: 6; }
    [data-theme='dark'] .order-md-7 {
      order: 7; }
    [data-theme='dark'] .order-md-8 {
      order: 8; }
    [data-theme='dark'] .order-md-9 {
      order: 9; }
    [data-theme='dark'] .order-md-10 {
      order: 10; }
    [data-theme='dark'] .order-md-11 {
      order: 11; }
    [data-theme='dark'] .order-md-12 {
      order: 12; }
    [data-theme='dark'] .offset-md-0 {
      margin-left: 0; }
    [data-theme='dark'] .offset-md-1 {
      margin-left: 8.33333%; }
    [data-theme='dark'] .offset-md-2 {
      margin-left: 16.66667%; }
    [data-theme='dark'] .offset-md-3 {
      margin-left: 25%; }
    [data-theme='dark'] .offset-md-4 {
      margin-left: 33.33333%; }
    [data-theme='dark'] .offset-md-5 {
      margin-left: 41.66667%; }
    [data-theme='dark'] .offset-md-6 {
      margin-left: 50%; }
    [data-theme='dark'] .offset-md-7 {
      margin-left: 58.33333%; }
    [data-theme='dark'] .offset-md-8 {
      margin-left: 66.66667%; }
    [data-theme='dark'] .offset-md-9 {
      margin-left: 75%; }
    [data-theme='dark'] .offset-md-10 {
      margin-left: 83.33333%; }
    [data-theme='dark'] .offset-md-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 992px) {
    [data-theme='dark'] .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    [data-theme='dark'] .row-cols-lg-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    [data-theme='dark'] .row-cols-lg-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    [data-theme='dark'] .row-cols-lg-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    [data-theme='dark'] .row-cols-lg-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    [data-theme='dark'] .row-cols-lg-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    [data-theme='dark'] .row-cols-lg-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    [data-theme='dark'] .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    [data-theme='dark'] .col-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    [data-theme='dark'] .col-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    [data-theme='dark'] .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    [data-theme='dark'] .col-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    [data-theme='dark'] .col-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    [data-theme='dark'] .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    [data-theme='dark'] .col-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    [data-theme='dark'] .col-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    [data-theme='dark'] .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    [data-theme='dark'] .col-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    [data-theme='dark'] .col-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    [data-theme='dark'] .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    [data-theme='dark'] .order-lg-first {
      order: -1; }
    [data-theme='dark'] .order-lg-last {
      order: 13; }
    [data-theme='dark'] .order-lg-0 {
      order: 0; }
    [data-theme='dark'] .order-lg-1 {
      order: 1; }
    [data-theme='dark'] .order-lg-2 {
      order: 2; }
    [data-theme='dark'] .order-lg-3 {
      order: 3; }
    [data-theme='dark'] .order-lg-4 {
      order: 4; }
    [data-theme='dark'] .order-lg-5 {
      order: 5; }
    [data-theme='dark'] .order-lg-6 {
      order: 6; }
    [data-theme='dark'] .order-lg-7 {
      order: 7; }
    [data-theme='dark'] .order-lg-8 {
      order: 8; }
    [data-theme='dark'] .order-lg-9 {
      order: 9; }
    [data-theme='dark'] .order-lg-10 {
      order: 10; }
    [data-theme='dark'] .order-lg-11 {
      order: 11; }
    [data-theme='dark'] .order-lg-12 {
      order: 12; }
    [data-theme='dark'] .offset-lg-0 {
      margin-left: 0; }
    [data-theme='dark'] .offset-lg-1 {
      margin-left: 8.33333%; }
    [data-theme='dark'] .offset-lg-2 {
      margin-left: 16.66667%; }
    [data-theme='dark'] .offset-lg-3 {
      margin-left: 25%; }
    [data-theme='dark'] .offset-lg-4 {
      margin-left: 33.33333%; }
    [data-theme='dark'] .offset-lg-5 {
      margin-left: 41.66667%; }
    [data-theme='dark'] .offset-lg-6 {
      margin-left: 50%; }
    [data-theme='dark'] .offset-lg-7 {
      margin-left: 58.33333%; }
    [data-theme='dark'] .offset-lg-8 {
      margin-left: 66.66667%; }
    [data-theme='dark'] .offset-lg-9 {
      margin-left: 75%; }
    [data-theme='dark'] .offset-lg-10 {
      margin-left: 83.33333%; }
    [data-theme='dark'] .offset-lg-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 1200px) {
    [data-theme='dark'] .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    [data-theme='dark'] .row-cols-xl-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    [data-theme='dark'] .row-cols-xl-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    [data-theme='dark'] .row-cols-xl-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    [data-theme='dark'] .row-cols-xl-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    [data-theme='dark'] .row-cols-xl-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    [data-theme='dark'] .row-cols-xl-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    [data-theme='dark'] .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    [data-theme='dark'] .col-xl-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    [data-theme='dark'] .col-xl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    [data-theme='dark'] .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    [data-theme='dark'] .col-xl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    [data-theme='dark'] .col-xl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    [data-theme='dark'] .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    [data-theme='dark'] .col-xl-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    [data-theme='dark'] .col-xl-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    [data-theme='dark'] .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    [data-theme='dark'] .col-xl-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    [data-theme='dark'] .col-xl-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    [data-theme='dark'] .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    [data-theme='dark'] .order-xl-first {
      order: -1; }
    [data-theme='dark'] .order-xl-last {
      order: 13; }
    [data-theme='dark'] .order-xl-0 {
      order: 0; }
    [data-theme='dark'] .order-xl-1 {
      order: 1; }
    [data-theme='dark'] .order-xl-2 {
      order: 2; }
    [data-theme='dark'] .order-xl-3 {
      order: 3; }
    [data-theme='dark'] .order-xl-4 {
      order: 4; }
    [data-theme='dark'] .order-xl-5 {
      order: 5; }
    [data-theme='dark'] .order-xl-6 {
      order: 6; }
    [data-theme='dark'] .order-xl-7 {
      order: 7; }
    [data-theme='dark'] .order-xl-8 {
      order: 8; }
    [data-theme='dark'] .order-xl-9 {
      order: 9; }
    [data-theme='dark'] .order-xl-10 {
      order: 10; }
    [data-theme='dark'] .order-xl-11 {
      order: 11; }
    [data-theme='dark'] .order-xl-12 {
      order: 12; }
    [data-theme='dark'] .offset-xl-0 {
      margin-left: 0; }
    [data-theme='dark'] .offset-xl-1 {
      margin-left: 8.33333%; }
    [data-theme='dark'] .offset-xl-2 {
      margin-left: 16.66667%; }
    [data-theme='dark'] .offset-xl-3 {
      margin-left: 25%; }
    [data-theme='dark'] .offset-xl-4 {
      margin-left: 33.33333%; }
    [data-theme='dark'] .offset-xl-5 {
      margin-left: 41.66667%; }
    [data-theme='dark'] .offset-xl-6 {
      margin-left: 50%; }
    [data-theme='dark'] .offset-xl-7 {
      margin-left: 58.33333%; }
    [data-theme='dark'] .offset-xl-8 {
      margin-left: 66.66667%; }
    [data-theme='dark'] .offset-xl-9 {
      margin-left: 75%; }
    [data-theme='dark'] .offset-xl-10 {
      margin-left: 83.33333%; }
    [data-theme='dark'] .offset-xl-11 {
      margin-left: 91.66667%; } }
  [data-theme='dark'] .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #d3d3d3; }
    [data-theme='dark'] .table th,
    [data-theme='dark'] .table td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #343a40; }
    [data-theme='dark'] .table thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #343a40; }
    [data-theme='dark'] .table tbody + tbody {
      border-top: 2px solid #343a40; }
  [data-theme='dark'] .table-sm th,
  [data-theme='dark'] .table-sm td {
    padding: 0.3rem; }
  [data-theme='dark'] .table-bordered {
    border: 1px solid #343a40; }
    [data-theme='dark'] .table-bordered th,
    [data-theme='dark'] .table-bordered td {
      border: 1px solid #343a40; }
    [data-theme='dark'] .table-bordered thead th,
    [data-theme='dark'] .table-bordered thead td {
      border-bottom-width: 2px; }
  [data-theme='dark'] .table-borderless th,
  [data-theme='dark'] .table-borderless td,
  [data-theme='dark'] .table-borderless thead th,
  [data-theme='dark'] .table-borderless tbody + tbody {
    border: 0; }
  [data-theme='dark'] .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05); }
  [data-theme='dark'] .table-hover tbody tr:hover {
    color: #d3d3d3;
    background-color: rgba(0, 0, 0, 0.075); }
  [data-theme='dark'] .table-primary,
  [data-theme='dark'] .table-primary > th,
  [data-theme='dark'] .table-primary > td {
    background-color: #c8cfe5; }
  [data-theme='dark'] .table-primary th,
  [data-theme='dark'] .table-primary td,
  [data-theme='dark'] .table-primary thead th,
  [data-theme='dark'] .table-primary tbody + tbody {
    border-color: #99a5cf; }
  [data-theme='dark'] .table-hover .table-primary:hover {
    background-color: #b7c0dd; }
    [data-theme='dark'] .table-hover .table-primary:hover > td,
    [data-theme='dark'] .table-hover .table-primary:hover > th {
      background-color: #b7c0dd; }
  [data-theme='dark'] .table-secondary,
  [data-theme='dark'] .table-secondary > th,
  [data-theme='dark'] .table-secondary > td {
    background-color: #d6d8db; }
  [data-theme='dark'] .table-secondary th,
  [data-theme='dark'] .table-secondary td,
  [data-theme='dark'] .table-secondary thead th,
  [data-theme='dark'] .table-secondary tbody + tbody {
    border-color: #b3b7bb; }
  [data-theme='dark'] .table-hover .table-secondary:hover {
    background-color: #c8cbcf; }
    [data-theme='dark'] .table-hover .table-secondary:hover > td,
    [data-theme='dark'] .table-hover .table-secondary:hover > th {
      background-color: #c8cbcf; }
  [data-theme='dark'] .table-success,
  [data-theme='dark'] .table-success > th,
  [data-theme='dark'] .table-success > td {
    background-color: #c8eacc; }
  [data-theme='dark'] .table-success th,
  [data-theme='dark'] .table-success td,
  [data-theme='dark'] .table-success thead th,
  [data-theme='dark'] .table-success tbody + tbody {
    border-color: #99d9a1; }
  [data-theme='dark'] .table-hover .table-success:hover {
    background-color: #b6e3bb; }
    [data-theme='dark'] .table-hover .table-success:hover > td,
    [data-theme='dark'] .table-hover .table-success:hover > th {
      background-color: #b6e3bb; }
  [data-theme='dark'] .table-info,
  [data-theme='dark'] .table-info > th,
  [data-theme='dark'] .table-info > td {
    background-color: #c2e7f7; }
  [data-theme='dark'] .table-info th,
  [data-theme='dark'] .table-info td,
  [data-theme='dark'] .table-info thead th,
  [data-theme='dark'] .table-info tbody + tbody {
    border-color: #8ed3f0; }
  [data-theme='dark'] .table-hover .table-info:hover {
    background-color: #abdef4; }
    [data-theme='dark'] .table-hover .table-info:hover > td,
    [data-theme='dark'] .table-hover .table-info:hover > th {
      background-color: #abdef4; }
  [data-theme='dark'] .table-warning,
  [data-theme='dark'] .table-warning > th,
  [data-theme='dark'] .table-warning > td {
    background-color: #fff9e3; }
  [data-theme='dark'] .table-warning th,
  [data-theme='dark'] .table-warning td,
  [data-theme='dark'] .table-warning thead th,
  [data-theme='dark'] .table-warning tbody + tbody {
    border-color: #fff3cb; }
  [data-theme='dark'] .table-hover .table-warning:hover {
    background-color: #fff4ca; }
    [data-theme='dark'] .table-hover .table-warning:hover > td,
    [data-theme='dark'] .table-hover .table-warning:hover > th {
      background-color: #fff4ca; }
  [data-theme='dark'] .table-danger,
  [data-theme='dark'] .table-danger > th,
  [data-theme='dark'] .table-danger > td {
    background-color: #edc0c4; }
  [data-theme='dark'] .table-danger th,
  [data-theme='dark'] .table-danger td,
  [data-theme='dark'] .table-danger thead th,
  [data-theme='dark'] .table-danger tbody + tbody {
    border-color: #de8a92; }
  [data-theme='dark'] .table-hover .table-danger:hover {
    background-color: #e7acb1; }
    [data-theme='dark'] .table-hover .table-danger:hover > td,
    [data-theme='dark'] .table-hover .table-danger:hover > th {
      background-color: #e7acb1; }
  [data-theme='dark'] .table-light,
  [data-theme='dark'] .table-light > th,
  [data-theme='dark'] .table-light > td {
    background-color: #fdfdfe; }
  [data-theme='dark'] .table-light th,
  [data-theme='dark'] .table-light td,
  [data-theme='dark'] .table-light thead th,
  [data-theme='dark'] .table-light tbody + tbody {
    border-color: #fbfcfc; }
  [data-theme='dark'] .table-hover .table-light:hover {
    background-color: #ececf6; }
    [data-theme='dark'] .table-hover .table-light:hover > td,
    [data-theme='dark'] .table-hover .table-light:hover > th {
      background-color: #ececf6; }
  [data-theme='dark'] .table-dark,
  [data-theme='dark'] .table-dark > th,
  [data-theme='dark'] .table-dark > td {
    background-color: #c6c8ca; }
  [data-theme='dark'] .table-dark th,
  [data-theme='dark'] .table-dark td,
  [data-theme='dark'] .table-dark thead th,
  [data-theme='dark'] .table-dark tbody + tbody {
    border-color: #95999c; }
  [data-theme='dark'] .table-hover .table-dark:hover {
    background-color: #b9bbbe; }
    [data-theme='dark'] .table-hover .table-dark:hover > td,
    [data-theme='dark'] .table-hover .table-dark:hover > th {
      background-color: #b9bbbe; }
  [data-theme='dark'] .table-active,
  [data-theme='dark'] .table-active > th,
  [data-theme='dark'] .table-active > td {
    background-color: rgba(0, 0, 0, 0.075); }
  [data-theme='dark'] .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075); }
    [data-theme='dark'] .table-hover .table-active:hover > td,
    [data-theme='dark'] .table-hover .table-active:hover > th {
      background-color: rgba(0, 0, 0, 0.075); }
  [data-theme='dark'] .table .thead-dark th {
    color: #dee2e6;
    background-color: #343a40;
    border-color: #454d55; }
  [data-theme='dark'] .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #343a40; }
  [data-theme='dark'] .table-dark {
    color: #dee2e6;
    background-color: #343a40; }
    [data-theme='dark'] .table-dark th,
    [data-theme='dark'] .table-dark td,
    [data-theme='dark'] .table-dark thead th {
      border-color: #454d55; }
    [data-theme='dark'] .table-dark.table-bordered {
      border: 0; }
    [data-theme='dark'] .table-dark.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(255, 255, 255, 0.05); }
    [data-theme='dark'] .table-dark.table-hover tbody tr:hover {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.075); }
  @media (max-width: 575.98px) {
    [data-theme='dark'] .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      [data-theme='dark'] .table-responsive-sm > .table-bordered {
        border: 0; } }
  @media (max-width: 767.98px) {
    [data-theme='dark'] .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      [data-theme='dark'] .table-responsive-md > .table-bordered {
        border: 0; } }
  @media (max-width: 991.98px) {
    [data-theme='dark'] .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      [data-theme='dark'] .table-responsive-lg > .table-bordered {
        border: 0; } }
  @media (max-width: 1199.98px) {
    [data-theme='dark'] .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      [data-theme='dark'] .table-responsive-xl > .table-bordered {
        border: 0; } }
  [data-theme='dark'] .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    [data-theme='dark'] .table-responsive > .table-bordered {
      border: 0; }
  [data-theme='dark'] .table-primary,
  [data-theme='dark'] .table-primary > th,
  [data-theme='dark'] .table-primary > td {
    color: #212529; }
  [data-theme='dark'] .table-hover .table-primary:hover {
    color: #212529; }
    [data-theme='dark'] .table-hover .table-primary:hover > td,
    [data-theme='dark'] .table-hover .table-primary:hover > th {
      color: #212529; }
  [data-theme='dark'] .table-secondary,
  [data-theme='dark'] .table-secondary > th,
  [data-theme='dark'] .table-secondary > td {
    color: #212529; }
  [data-theme='dark'] .table-hover .table-secondary:hover {
    color: #212529; }
    [data-theme='dark'] .table-hover .table-secondary:hover > td,
    [data-theme='dark'] .table-hover .table-secondary:hover > th {
      color: #212529; }
  [data-theme='dark'] .table-success,
  [data-theme='dark'] .table-success > th,
  [data-theme='dark'] .table-success > td {
    color: #212529; }
  [data-theme='dark'] .table-hover .table-success:hover {
    color: #212529; }
    [data-theme='dark'] .table-hover .table-success:hover > td,
    [data-theme='dark'] .table-hover .table-success:hover > th {
      color: #212529; }
  [data-theme='dark'] .table-info,
  [data-theme='dark'] .table-info > th,
  [data-theme='dark'] .table-info > td {
    color: #212529; }
  [data-theme='dark'] .table-hover .table-info:hover {
    color: #212529; }
    [data-theme='dark'] .table-hover .table-info:hover > td,
    [data-theme='dark'] .table-hover .table-info:hover > th {
      color: #212529; }
  [data-theme='dark'] .table-warning,
  [data-theme='dark'] .table-warning > th,
  [data-theme='dark'] .table-warning > td {
    color: #212529; }
  [data-theme='dark'] .table-hover .table-warning:hover {
    color: #212529; }
    [data-theme='dark'] .table-hover .table-warning:hover > td,
    [data-theme='dark'] .table-hover .table-warning:hover > th {
      color: #212529; }
  [data-theme='dark'] .table-danger,
  [data-theme='dark'] .table-danger > th,
  [data-theme='dark'] .table-danger > td {
    color: #212529; }
  [data-theme='dark'] .table-hover .table-danger:hover {
    color: #212529; }
    [data-theme='dark'] .table-hover .table-danger:hover > td,
    [data-theme='dark'] .table-hover .table-danger:hover > th {
      color: #212529; }
  [data-theme='dark'] .table-light,
  [data-theme='dark'] .table-light > th,
  [data-theme='dark'] .table-light > td {
    color: #212529; }
  [data-theme='dark'] .table-hover .table-light:hover {
    color: #212529; }
    [data-theme='dark'] .table-hover .table-light:hover > td,
    [data-theme='dark'] .table-hover .table-light:hover > th {
      color: #212529; }
  [data-theme='dark'] .table-dark,
  [data-theme='dark'] .table-dark > th,
  [data-theme='dark'] .table-dark > td {
    color: #212529; }
  [data-theme='dark'] .table-hover .table-dark:hover {
    color: #212529; }
    [data-theme='dark'] .table-hover .table-dark:hover > td,
    [data-theme='dark'] .table-hover .table-dark:hover > th {
      color: #212529; }
  [data-theme='dark'] .table-active,
  [data-theme='dark'] .table-active > th,
  [data-theme='dark'] .table-active > td {
    color: #ced4da; }
  [data-theme='dark'] .table-hover .table-active:hover {
    color: #ced4da; }
    [data-theme='dark'] .table-hover .table-active:hover > td,
    [data-theme='dark'] .table-hover .table-active:hover > th {
      color: #ced4da; }
  [data-theme='dark'] .table-dark {
    color: #dee2e6; }
  [data-theme='dark'] .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 1.5;
    color: #dee2e6;
    background-color: #000;
    background-clip: padding-box;
    border: 1px solid #6c757d;
    border-radius: 0.25rem;
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      [data-theme='dark'] .form-control {
        transition: none; } }
    [data-theme='dark'] .form-control::-ms-expand {
      background-color: transparent;
      border: 0; }
    [data-theme='dark'] .form-control:focus {
      color: #dee2e6;
      background-color: #191d21;
      border-color: #b3d7ff;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.075), 0 0 0 0.2rem rgba(58, 82, 162, 0.25); }
    [data-theme='dark'] .form-control::placeholder {
      color: #6c757d;
      opacity: 1; }
    [data-theme='dark'] .form-control:disabled, [data-theme='dark'] .form-control[readonly] {
      background-color: #343a40;
      opacity: 1; }
  [data-theme='dark'] input[type="date"].form-control,
  [data-theme='dark'] input[type="time"].form-control,
  [data-theme='dark'] input[type="datetime-local"].form-control,
  [data-theme='dark'] input[type="month"].form-control {
    appearance: none; }
  [data-theme='dark'] select.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #dee2e6; }
  [data-theme='dark'] select.form-control:focus::-ms-value {
    color: #dee2e6;
    background-color: #000; }
  [data-theme='dark'] .form-control-file,
  [data-theme='dark'] .form-control-range {
    display: block;
    width: 100%; }
  [data-theme='dark'] .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5; }
  [data-theme='dark'] .col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.0625rem;
    line-height: 1.5; }
  [data-theme='dark'] .col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.74375rem;
    line-height: 1.5; }
  [data-theme='dark'] .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    font-size: 0.85rem;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0; }
    [data-theme='dark'] .form-control-plaintext.form-control-sm, [data-theme='dark'] .form-control-plaintext.form-control-lg {
      padding-right: 0;
      padding-left: 0; }
  [data-theme='dark'] .form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.74375rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  [data-theme='dark'] .form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.0625rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  [data-theme='dark'] select.form-control[size], [data-theme='dark'] select.form-control[multiple] {
    height: auto; }
  [data-theme='dark'] textarea.form-control {
    height: auto; }
  [data-theme='dark'] .form-group {
    margin-bottom: 1rem; }
  [data-theme='dark'] .form-text {
    display: block;
    margin-top: 0.25rem; }
  [data-theme='dark'] .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px; }
    [data-theme='dark'] .form-row > .col,
    [data-theme='dark'] .form-row > [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px; }
  [data-theme='dark'] .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem; }
  [data-theme='dark'] .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem; }
    [data-theme='dark'] .form-check-input[disabled] ~ .form-check-label,
    [data-theme='dark'] .form-check-input:disabled ~ .form-check-label {
      color: #6c757d; }
  [data-theme='dark'] .form-check-label {
    margin-bottom: 0; }
  [data-theme='dark'] .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem; }
    [data-theme='dark'] .form-check-inline .form-check-input {
      position: static;
      margin-top: 0;
      margin-right: 0.3125rem;
      margin-left: 0; }
  [data-theme='dark'] .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #3AB54A; }
  [data-theme='dark'] .valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.74375rem;
    line-height: 1.5;
    color: #ced4da;
    background-color: rgba(58, 181, 74, 0.9);
    border-radius: 0.25rem; }
    .form-row > .col > [data-theme='dark'] .valid-tooltip,
    .form-row > [class*="col-"] > [data-theme='dark'] .valid-tooltip {
      left: 5px; }
  .was-validated [data-theme='dark']:valid ~ .valid-feedback,
  .was-validated [data-theme='dark']:valid ~ .valid-tooltip, [data-theme='dark'].is-valid ~ .valid-feedback,
  [data-theme='dark'].is-valid ~ .valid-tooltip {
    display: block; }
  .was-validated [data-theme='dark'] .form-control:valid, [data-theme='dark'] .form-control.is-valid {
    border-color: #3AB54A;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233AB54A' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated [data-theme='dark'] .form-control:valid:focus, [data-theme='dark'] .form-control.is-valid:focus {
      border-color: #3AB54A;
      box-shadow: 0 0 0 0.2rem rgba(58, 181, 74, 0.25); }
  .was-validated [data-theme='dark'] select.form-control:valid, [data-theme='dark'] select.form-control.is-valid {
    padding-right: 3rem !important;
    background-position: right 1.5rem center; }
  .was-validated [data-theme='dark'] textarea.form-control:valid, [data-theme='dark'] textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
  .was-validated [data-theme='dark'] .custom-select:valid, [data-theme='dark'] .custom-select.is-valid {
    border-color: #3AB54A;
    padding-right: calc(0.75em + 2.3125rem) !important;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #000 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233AB54A' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
    .was-validated [data-theme='dark'] .custom-select:valid:focus, [data-theme='dark'] .custom-select.is-valid:focus {
      border-color: #3AB54A;
      box-shadow: 0 0 0 0.2rem rgba(58, 181, 74, 0.25); }
  .was-validated [data-theme='dark'] .form-check-input:valid ~ .form-check-label, [data-theme='dark'] .form-check-input.is-valid ~ .form-check-label {
    color: #3AB54A; }
  .was-validated [data-theme='dark'] .form-check-input:valid ~ .valid-feedback,
  .was-validated [data-theme='dark'] .form-check-input:valid ~ .valid-tooltip, [data-theme='dark'] .form-check-input.is-valid ~ .valid-feedback,
  [data-theme='dark'] .form-check-input.is-valid ~ .valid-tooltip {
    display: block; }
  .was-validated [data-theme='dark'] .custom-control-input:valid ~ .custom-control-label, [data-theme='dark'] .custom-control-input.is-valid ~ .custom-control-label {
    color: #3AB54A; }
    .was-validated [data-theme='dark'] .custom-control-input:valid ~ .custom-control-label::before, [data-theme='dark'] .custom-control-input.is-valid ~ .custom-control-label::before {
      border-color: #3AB54A; }
  .was-validated [data-theme='dark'] .custom-control-input:valid:checked ~ .custom-control-label::before, [data-theme='dark'] .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    border-color: #58ca67;
    background-color: #58ca67; }
  .was-validated [data-theme='dark'] .custom-control-input:valid:focus ~ .custom-control-label::before, [data-theme='dark'] .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(58, 181, 74, 0.25); }
  .was-validated [data-theme='dark'] .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, [data-theme='dark'] .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #3AB54A; }
  .was-validated [data-theme='dark'] .custom-file-input:valid ~ .custom-file-label, [data-theme='dark'] .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #3AB54A; }
  .was-validated [data-theme='dark'] .custom-file-input:valid:focus ~ .custom-file-label, [data-theme='dark'] .custom-file-input.is-valid:focus ~ .custom-file-label {
    border-color: #3AB54A;
    box-shadow: 0 0 0 0.2rem rgba(58, 181, 74, 0.25); }
  [data-theme='dark'] .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #BF1E2E; }
  [data-theme='dark'] .invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.74375rem;
    line-height: 1.5;
    color: #ced4da;
    background-color: rgba(191, 30, 46, 0.9);
    border-radius: 0.25rem; }
    .form-row > .col > [data-theme='dark'] .invalid-tooltip,
    .form-row > [class*="col-"] > [data-theme='dark'] .invalid-tooltip {
      left: 5px; }
  .was-validated [data-theme='dark']:invalid ~ .invalid-feedback,
  .was-validated [data-theme='dark']:invalid ~ .invalid-tooltip, [data-theme='dark'].is-invalid ~ .invalid-feedback,
  [data-theme='dark'].is-invalid ~ .invalid-tooltip {
    display: block; }
  .was-validated [data-theme='dark'] .form-control:invalid, [data-theme='dark'] .form-control.is-invalid {
    border-color: #BF1E2E;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23BF1E2E' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23BF1E2E' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated [data-theme='dark'] .form-control:invalid:focus, [data-theme='dark'] .form-control.is-invalid:focus {
      border-color: #BF1E2E;
      box-shadow: 0 0 0 0.2rem rgba(191, 30, 46, 0.25); }
  .was-validated [data-theme='dark'] select.form-control:invalid, [data-theme='dark'] select.form-control.is-invalid {
    padding-right: 3rem !important;
    background-position: right 1.5rem center; }
  .was-validated [data-theme='dark'] textarea.form-control:invalid, [data-theme='dark'] textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
  .was-validated [data-theme='dark'] .custom-select:invalid, [data-theme='dark'] .custom-select.is-invalid {
    border-color: #BF1E2E;
    padding-right: calc(0.75em + 2.3125rem) !important;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #000 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23BF1E2E' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23BF1E2E' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
    .was-validated [data-theme='dark'] .custom-select:invalid:focus, [data-theme='dark'] .custom-select.is-invalid:focus {
      border-color: #BF1E2E;
      box-shadow: 0 0 0 0.2rem rgba(191, 30, 46, 0.25); }
  .was-validated [data-theme='dark'] .form-check-input:invalid ~ .form-check-label, [data-theme='dark'] .form-check-input.is-invalid ~ .form-check-label {
    color: #BF1E2E; }
  .was-validated [data-theme='dark'] .form-check-input:invalid ~ .invalid-feedback,
  .was-validated [data-theme='dark'] .form-check-input:invalid ~ .invalid-tooltip, [data-theme='dark'] .form-check-input.is-invalid ~ .invalid-feedback,
  [data-theme='dark'] .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block; }
  .was-validated [data-theme='dark'] .custom-control-input:invalid ~ .custom-control-label, [data-theme='dark'] .custom-control-input.is-invalid ~ .custom-control-label {
    color: #BF1E2E; }
    .was-validated [data-theme='dark'] .custom-control-input:invalid ~ .custom-control-label::before, [data-theme='dark'] .custom-control-input.is-invalid ~ .custom-control-label::before {
      border-color: #BF1E2E; }
  .was-validated [data-theme='dark'] .custom-control-input:invalid:checked ~ .custom-control-label::before, [data-theme='dark'] .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    border-color: #df3143;
    background-color: #df3143; }
  .was-validated [data-theme='dark'] .custom-control-input:invalid:focus ~ .custom-control-label::before, [data-theme='dark'] .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(191, 30, 46, 0.25); }
  .was-validated [data-theme='dark'] .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, [data-theme='dark'] .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #BF1E2E; }
  .was-validated [data-theme='dark'] .custom-file-input:invalid ~ .custom-file-label, [data-theme='dark'] .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #BF1E2E; }
  .was-validated [data-theme='dark'] .custom-file-input:invalid:focus ~ .custom-file-label, [data-theme='dark'] .custom-file-input.is-invalid:focus ~ .custom-file-label {
    border-color: #BF1E2E;
    box-shadow: 0 0 0 0.2rem rgba(191, 30, 46, 0.25); }
  [data-theme='dark'] .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center; }
    [data-theme='dark'] .form-inline .form-check {
      width: 100%; }
    @media (min-width: 576px) {
      [data-theme='dark'] .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0; }
      [data-theme='dark'] .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0; }
      [data-theme='dark'] .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle; }
      [data-theme='dark'] .form-inline .form-control-plaintext {
        display: inline-block; }
      [data-theme='dark'] .form-inline .input-group,
      [data-theme='dark'] .form-inline .custom-select {
        width: auto; }
      [data-theme='dark'] .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0; }
      [data-theme='dark'] .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0; }
      [data-theme='dark'] .form-inline .custom-control {
        align-items: center;
        justify-content: center; }
      [data-theme='dark'] .form-inline .custom-control-label {
        margin-bottom: 0; } }
  [data-theme='dark'] .btn {
    display: inline-block;
    font-weight: 400;
    color: #d3d3d3;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.85rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      [data-theme='dark'] .btn {
        transition: none; } }
    [data-theme='dark'] .btn:hover {
      color: #d3d3d3;
      text-decoration: none; }
    [data-theme='dark'] .btn:focus, [data-theme='dark'] .btn.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(58, 82, 162, 0.25); }
    [data-theme='dark'] .btn.disabled, [data-theme='dark'] .btn:disabled {
      opacity: 0.65;
      box-shadow: none; }
    [data-theme='dark'] .btn:not(:disabled):not(.disabled) {
      cursor: pointer; }
      [data-theme='dark'] .btn:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn:not(:disabled):not(.disabled).active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
        [data-theme='dark'] .btn:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn:not(:disabled):not(.disabled).active:focus {
          box-shadow: 0 0 0 0.2rem rgba(58, 82, 162, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  [data-theme='dark'] a.btn.disabled,
  [data-theme='dark'] fieldset:disabled a.btn {
    pointer-events: none; }
  [data-theme='dark'] .btn-primary {
    color: #ced4da;
    background-color: #3A52A2;
    border-color: #3A52A2;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
    [data-theme='dark'] .btn-primary:hover {
      color: #ced4da;
      background-color: #304486;
      border-color: #2d3f7c; }
    [data-theme='dark'] .btn-primary:focus, [data-theme='dark'] .btn-primary.focus {
      color: #ced4da;
      background-color: #304486;
      border-color: #2d3f7c;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(80, 102, 170, 0.5); }
    [data-theme='dark'] .btn-primary.disabled, [data-theme='dark'] .btn-primary:disabled {
      color: #ced4da;
      background-color: #3A52A2;
      border-color: #3A52A2; }
    [data-theme='dark'] .btn-primary:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-primary:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-primary.dropdown-toggle {
      color: #ced4da;
      background-color: #2d3f7c;
      border-color: #293a73; }
      [data-theme='dark'] .btn-primary:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-primary:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-primary.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(80, 102, 170, 0.5); }
  [data-theme='dark'] .btn-secondary {
    color: #ced4da;
    background-color: #6c757d;
    border-color: #6c757d;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
    [data-theme='dark'] .btn-secondary:hover {
      color: #ced4da;
      background-color: #5a6268;
      border-color: #545b62; }
    [data-theme='dark'] .btn-secondary:focus, [data-theme='dark'] .btn-secondary.focus {
      color: #ced4da;
      background-color: #5a6268;
      border-color: #545b62;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(123, 131, 139, 0.5); }
    [data-theme='dark'] .btn-secondary.disabled, [data-theme='dark'] .btn-secondary:disabled {
      color: #ced4da;
      background-color: #6c757d;
      border-color: #6c757d; }
    [data-theme='dark'] .btn-secondary:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-secondary:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-secondary.dropdown-toggle {
      color: #ced4da;
      background-color: #545b62;
      border-color: #4e555b; }
      [data-theme='dark'] .btn-secondary:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-secondary:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-secondary.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(123, 131, 139, 0.5); }
  [data-theme='dark'] .btn-success {
    color: #ced4da;
    background-color: #3AB54A;
    border-color: #3AB54A;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
    [data-theme='dark'] .btn-success:hover {
      color: #ced4da;
      background-color: #31983e;
      border-color: #2e8e3a; }
    [data-theme='dark'] .btn-success:focus, [data-theme='dark'] .btn-success.focus {
      color: #ced4da;
      background-color: #31983e;
      border-color: #2e8e3a;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(80, 186, 96, 0.5); }
    [data-theme='dark'] .btn-success.disabled, [data-theme='dark'] .btn-success:disabled {
      color: #ced4da;
      background-color: #3AB54A;
      border-color: #3AB54A; }
    [data-theme='dark'] .btn-success:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-success:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-success.dropdown-toggle {
      color: #ced4da;
      background-color: #2e8e3a;
      border-color: #2b8536; }
      [data-theme='dark'] .btn-success:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-success:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-success.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(80, 186, 96, 0.5); }
  [data-theme='dark'] .btn-info {
    color: #ced4da;
    background-color: #25AAE2;
    border-color: #25AAE2;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
    [data-theme='dark'] .btn-info:hover {
      color: #ced4da;
      background-color: #1a93c6;
      border-color: #198bbb; }
    [data-theme='dark'] .btn-info:focus, [data-theme='dark'] .btn-info.focus {
      color: #ced4da;
      background-color: #1a93c6;
      border-color: #198bbb;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(62, 176, 225, 0.5); }
    [data-theme='dark'] .btn-info.disabled, [data-theme='dark'] .btn-info:disabled {
      color: #ced4da;
      background-color: #25AAE2;
      border-color: #25AAE2; }
    [data-theme='dark'] .btn-info:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-info:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-info.dropdown-toggle {
      color: #ced4da;
      background-color: #198bbb;
      border-color: #1783b0; }
      [data-theme='dark'] .btn-info:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-info:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-info.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(62, 176, 225, 0.5); }
  [data-theme='dark'] .btn-warning {
    color: #212529;
    background-color: #FFE89B;
    border-color: #FFE89B;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
    [data-theme='dark'] .btn-warning:hover {
      color: #212529;
      background-color: #ffdf75;
      border-color: #ffdc68; }
    [data-theme='dark'] .btn-warning:focus, [data-theme='dark'] .btn-warning.focus {
      color: #212529;
      background-color: #ffdf75;
      border-color: #ffdc68;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(222, 203, 138, 0.5); }
    [data-theme='dark'] .btn-warning.disabled, [data-theme='dark'] .btn-warning:disabled {
      color: #212529;
      background-color: #FFE89B;
      border-color: #FFE89B; }
    [data-theme='dark'] .btn-warning:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-warning:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-warning.dropdown-toggle {
      color: #212529;
      background-color: #ffdc68;
      border-color: #ffd95b; }
      [data-theme='dark'] .btn-warning:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-warning:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-warning.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(222, 203, 138, 0.5); }
  [data-theme='dark'] .btn-danger {
    color: #ced4da;
    background-color: #BF1E2E;
    border-color: #BF1E2E;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
    [data-theme='dark'] .btn-danger:hover {
      color: #ced4da;
      background-color: #9e1926;
      border-color: #931723; }
    [data-theme='dark'] .btn-danger:focus, [data-theme='dark'] .btn-danger.focus {
      color: #ced4da;
      background-color: #9e1926;
      border-color: #931723;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(193, 57, 72, 0.5); }
    [data-theme='dark'] .btn-danger.disabled, [data-theme='dark'] .btn-danger:disabled {
      color: #ced4da;
      background-color: #BF1E2E;
      border-color: #BF1E2E; }
    [data-theme='dark'] .btn-danger:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-danger:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-danger.dropdown-toggle {
      color: #ced4da;
      background-color: #931723;
      border-color: #881521; }
      [data-theme='dark'] .btn-danger:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-danger:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-danger.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(193, 57, 72, 0.5); }
  [data-theme='dark'] .btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
    [data-theme='dark'] .btn-light:hover {
      color: #212529;
      background-color: #e2e6ea;
      border-color: #dae0e5; }
    [data-theme='dark'] .btn-light:focus, [data-theme='dark'] .btn-light.focus {
      color: #212529;
      background-color: #e2e6ea;
      border-color: #dae0e5;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
    [data-theme='dark'] .btn-light.disabled, [data-theme='dark'] .btn-light:disabled {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
    [data-theme='dark'] .btn-light:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-light:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-light.dropdown-toggle {
      color: #212529;
      background-color: #dae0e5;
      border-color: #d3d9df; }
      [data-theme='dark'] .btn-light:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-light:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-light.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  [data-theme='dark'] .btn-dark {
    color: #ced4da;
    background-color: #343a40;
    border-color: #343a40;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
    [data-theme='dark'] .btn-dark:hover {
      color: #ced4da;
      background-color: #23272b;
      border-color: #1d2124; }
    [data-theme='dark'] .btn-dark:focus, [data-theme='dark'] .btn-dark.focus {
      color: #ced4da;
      background-color: #23272b;
      border-color: #1d2124;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(75, 81, 87, 0.5); }
    [data-theme='dark'] .btn-dark.disabled, [data-theme='dark'] .btn-dark:disabled {
      color: #ced4da;
      background-color: #343a40;
      border-color: #343a40; }
    [data-theme='dark'] .btn-dark:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-dark:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-dark.dropdown-toggle {
      color: #ced4da;
      background-color: #1d2124;
      border-color: #171a1d; }
      [data-theme='dark'] .btn-dark:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-dark:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-dark.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(75, 81, 87, 0.5); }
  [data-theme='dark'] .btn-outline-primary {
    color: #3A52A2;
    border-color: #3A52A2; }
    [data-theme='dark'] .btn-outline-primary:hover {
      color: #ced4da;
      background-color: #3A52A2;
      border-color: #3A52A2; }
    [data-theme='dark'] .btn-outline-primary:focus, [data-theme='dark'] .btn-outline-primary.focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 82, 162, 0.5); }
    [data-theme='dark'] .btn-outline-primary.disabled, [data-theme='dark'] .btn-outline-primary:disabled {
      color: #3A52A2;
      background-color: transparent; }
    [data-theme='dark'] .btn-outline-primary:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-outline-primary.dropdown-toggle {
      color: #ced4da;
      background-color: #3A52A2;
      border-color: #3A52A2; }
      [data-theme='dark'] .btn-outline-primary:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-outline-primary.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 82, 162, 0.5); }
  [data-theme='dark'] .btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d; }
    [data-theme='dark'] .btn-outline-secondary:hover {
      color: #ced4da;
      background-color: #6c757d;
      border-color: #6c757d; }
    [data-theme='dark'] .btn-outline-secondary:focus, [data-theme='dark'] .btn-outline-secondary.focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
    [data-theme='dark'] .btn-outline-secondary.disabled, [data-theme='dark'] .btn-outline-secondary:disabled {
      color: #6c757d;
      background-color: transparent; }
    [data-theme='dark'] .btn-outline-secondary:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-outline-secondary:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-outline-secondary.dropdown-toggle {
      color: #ced4da;
      background-color: #6c757d;
      border-color: #6c757d; }
      [data-theme='dark'] .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-outline-secondary.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  [data-theme='dark'] .btn-outline-success {
    color: #3AB54A;
    border-color: #3AB54A; }
    [data-theme='dark'] .btn-outline-success:hover {
      color: #ced4da;
      background-color: #3AB54A;
      border-color: #3AB54A; }
    [data-theme='dark'] .btn-outline-success:focus, [data-theme='dark'] .btn-outline-success.focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 181, 74, 0.5); }
    [data-theme='dark'] .btn-outline-success.disabled, [data-theme='dark'] .btn-outline-success:disabled {
      color: #3AB54A;
      background-color: transparent; }
    [data-theme='dark'] .btn-outline-success:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-outline-success:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-outline-success.dropdown-toggle {
      color: #ced4da;
      background-color: #3AB54A;
      border-color: #3AB54A; }
      [data-theme='dark'] .btn-outline-success:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-outline-success:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-outline-success.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 181, 74, 0.5); }
  [data-theme='dark'] .btn-outline-info {
    color: #25AAE2;
    border-color: #25AAE2; }
    [data-theme='dark'] .btn-outline-info:hover {
      color: #ced4da;
      background-color: #25AAE2;
      border-color: #25AAE2; }
    [data-theme='dark'] .btn-outline-info:focus, [data-theme='dark'] .btn-outline-info.focus {
      box-shadow: 0 0 0 0.2rem rgba(37, 170, 226, 0.5); }
    [data-theme='dark'] .btn-outline-info.disabled, [data-theme='dark'] .btn-outline-info:disabled {
      color: #25AAE2;
      background-color: transparent; }
    [data-theme='dark'] .btn-outline-info:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-outline-info:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-outline-info.dropdown-toggle {
      color: #ced4da;
      background-color: #25AAE2;
      border-color: #25AAE2; }
      [data-theme='dark'] .btn-outline-info:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-outline-info:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-outline-info.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(37, 170, 226, 0.5); }
  [data-theme='dark'] .btn-outline-warning {
    color: #FFE89B;
    border-color: #FFE89B; }
    [data-theme='dark'] .btn-outline-warning:hover {
      color: #212529;
      background-color: #FFE89B;
      border-color: #FFE89B; }
    [data-theme='dark'] .btn-outline-warning:focus, [data-theme='dark'] .btn-outline-warning.focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 232, 155, 0.5); }
    [data-theme='dark'] .btn-outline-warning.disabled, [data-theme='dark'] .btn-outline-warning:disabled {
      color: #FFE89B;
      background-color: transparent; }
    [data-theme='dark'] .btn-outline-warning:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-outline-warning:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-outline-warning.dropdown-toggle {
      color: #212529;
      background-color: #FFE89B;
      border-color: #FFE89B; }
      [data-theme='dark'] .btn-outline-warning:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-outline-warning.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 232, 155, 0.5); }
  [data-theme='dark'] .btn-outline-danger {
    color: #BF1E2E;
    border-color: #BF1E2E; }
    [data-theme='dark'] .btn-outline-danger:hover {
      color: #ced4da;
      background-color: #BF1E2E;
      border-color: #BF1E2E; }
    [data-theme='dark'] .btn-outline-danger:focus, [data-theme='dark'] .btn-outline-danger.focus {
      box-shadow: 0 0 0 0.2rem rgba(191, 30, 46, 0.5); }
    [data-theme='dark'] .btn-outline-danger.disabled, [data-theme='dark'] .btn-outline-danger:disabled {
      color: #BF1E2E;
      background-color: transparent; }
    [data-theme='dark'] .btn-outline-danger:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-outline-danger:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-outline-danger.dropdown-toggle {
      color: #ced4da;
      background-color: #BF1E2E;
      border-color: #BF1E2E; }
      [data-theme='dark'] .btn-outline-danger:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-outline-danger.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(191, 30, 46, 0.5); }
  [data-theme='dark'] .btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa; }
    [data-theme='dark'] .btn-outline-light:hover {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
    [data-theme='dark'] .btn-outline-light:focus, [data-theme='dark'] .btn-outline-light.focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
    [data-theme='dark'] .btn-outline-light.disabled, [data-theme='dark'] .btn-outline-light:disabled {
      color: #f8f9fa;
      background-color: transparent; }
    [data-theme='dark'] .btn-outline-light:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-outline-light:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-outline-light.dropdown-toggle {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
      [data-theme='dark'] .btn-outline-light:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-outline-light:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-outline-light.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  [data-theme='dark'] .btn-outline-dark {
    color: #343a40;
    border-color: #343a40; }
    [data-theme='dark'] .btn-outline-dark:hover {
      color: #ced4da;
      background-color: #343a40;
      border-color: #343a40; }
    [data-theme='dark'] .btn-outline-dark:focus, [data-theme='dark'] .btn-outline-dark.focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
    [data-theme='dark'] .btn-outline-dark.disabled, [data-theme='dark'] .btn-outline-dark:disabled {
      color: #343a40;
      background-color: transparent; }
    [data-theme='dark'] .btn-outline-dark:not(:disabled):not(.disabled):active, [data-theme='dark'] .btn-outline-dark:not(:disabled):not(.disabled).active,
    .show > [data-theme='dark'] .btn-outline-dark.dropdown-toggle {
      color: #ced4da;
      background-color: #343a40;
      border-color: #343a40; }
      [data-theme='dark'] .btn-outline-dark:not(:disabled):not(.disabled):active:focus, [data-theme='dark'] .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
      .show > [data-theme='dark'] .btn-outline-dark.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  [data-theme='dark'] .btn-link {
    font-weight: 400;
    color: #adadad;
    text-decoration: none; }
    [data-theme='dark'] .btn-link:hover {
      color: #878787;
      text-decoration: underline; }
    [data-theme='dark'] .btn-link:focus, [data-theme='dark'] .btn-link.focus {
      text-decoration: underline; }
    [data-theme='dark'] .btn-link:disabled, [data-theme='dark'] .btn-link.disabled {
      color: #6c757d;
      pointer-events: none; }
  [data-theme='dark'] .btn-lg, [data-theme='dark'] .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.0625rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  [data-theme='dark'] .btn-sm, [data-theme='dark'] .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.74375rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  [data-theme='dark'] .btn-block {
    display: block;
    width: 100%; }
    [data-theme='dark'] .btn-block + .btn-block {
      margin-top: 0.5rem; }
  [data-theme='dark'] input[type="submit"].btn-block,
  [data-theme='dark'] input[type="reset"].btn-block,
  [data-theme='dark'] input[type="button"].btn-block {
    width: 100%; }
  [data-theme='dark'] .fade {
    transition: opacity 0.15s linear; }
    @media (prefers-reduced-motion: reduce) {
      [data-theme='dark'] .fade {
        transition: none; } }
    [data-theme='dark'] .fade:not(.show) {
      opacity: 0; }
  [data-theme='dark'] .collapse:not(.show) {
    display: none; }
  [data-theme='dark'] .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      [data-theme='dark'] .collapsing {
        transition: none; } }
    [data-theme='dark'] .collapsing.width {
      width: 0;
      height: auto;
      transition: width 0.35s ease; }
      @media (prefers-reduced-motion: reduce) {
        [data-theme='dark'] .collapsing.width {
          transition: none; } }
  [data-theme='dark'] .dropup,
  [data-theme='dark'] .dropright,
  [data-theme='dark'] .dropdown,
  [data-theme='dark'] .dropleft {
    position: relative; }
  [data-theme='dark'] .dropdown-toggle {
    white-space: nowrap; }
    [data-theme='dark'] .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent; }
    [data-theme='dark'] .dropdown-toggle:empty::after {
      margin-left: 0; }
  [data-theme='dark'] .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.85rem;
    color: #d3d3d3;
    text-align: left;
    list-style: none;
    background-color: #000;
    background-clip: padding-box;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.175); }
  [data-theme='dark'] .dropdown-menu-left {
    right: auto;
    left: 0; }
  [data-theme='dark'] .dropdown-menu-right {
    right: 0;
    left: auto; }
  @media (min-width: 576px) {
    [data-theme='dark'] .dropdown-menu-sm-left {
      right: auto;
      left: 0; }
    [data-theme='dark'] .dropdown-menu-sm-right {
      right: 0;
      left: auto; } }
  @media (min-width: 768px) {
    [data-theme='dark'] .dropdown-menu-md-left {
      right: auto;
      left: 0; }
    [data-theme='dark'] .dropdown-menu-md-right {
      right: 0;
      left: auto; } }
  @media (min-width: 992px) {
    [data-theme='dark'] .dropdown-menu-lg-left {
      right: auto;
      left: 0; }
    [data-theme='dark'] .dropdown-menu-lg-right {
      right: 0;
      left: auto; } }
  @media (min-width: 1200px) {
    [data-theme='dark'] .dropdown-menu-xl-left {
      right: auto;
      left: 0; }
    [data-theme='dark'] .dropdown-menu-xl-right {
      right: 0;
      left: auto; } }
  [data-theme='dark'] .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem; }
  [data-theme='dark'] .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent; }
  [data-theme='dark'] .dropup .dropdown-toggle:empty::after {
    margin-left: 0; }
  [data-theme='dark'] .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem; }
  [data-theme='dark'] .dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid; }
  [data-theme='dark'] .dropright .dropdown-toggle:empty::after {
    margin-left: 0; }
  [data-theme='dark'] .dropright .dropdown-toggle::after {
    vertical-align: 0; }
  [data-theme='dark'] .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem; }
  [data-theme='dark'] .dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: ""; }
  [data-theme='dark'] .dropleft .dropdown-toggle::after {
    display: none; }
  [data-theme='dark'] .dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent; }
  [data-theme='dark'] .dropleft .dropdown-toggle:empty::after {
    margin-left: 0; }
  [data-theme='dark'] .dropleft .dropdown-toggle::before {
    vertical-align: 0; }
  [data-theme='dark'] .dropdown-menu[x-placement^="top"], [data-theme='dark'] .dropdown-menu[x-placement^="right"], [data-theme='dark'] .dropdown-menu[x-placement^="bottom"], [data-theme='dark'] .dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto; }
  [data-theme='dark'] .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #343a40; }
  [data-theme='dark'] .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #f8f9fa;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
    [data-theme='dark'] .dropdown-item:hover, [data-theme='dark'] .dropdown-item:focus {
      color: white;
      text-decoration: none;
      background-color: #212529; }
    [data-theme='dark'] .dropdown-item.active, [data-theme='dark'] .dropdown-item:active {
      color: #000;
      text-decoration: none;
      background-color: #3395ff; }
    [data-theme='dark'] .dropdown-item.disabled, [data-theme='dark'] .dropdown-item:disabled {
      color: #ced4da;
      pointer-events: none;
      background-color: transparent; }
  [data-theme='dark'] .dropdown-menu.show {
    display: block; }
  [data-theme='dark'] .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.74375rem;
    color: #ced4da;
    white-space: nowrap; }
  [data-theme='dark'] .dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #f8f9fa; }
  [data-theme='dark'] .btn-group,
  [data-theme='dark'] .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle; }
    [data-theme='dark'] .btn-group > .btn,
    [data-theme='dark'] .btn-group-vertical > .btn {
      position: relative;
      flex: 1 1 auto; }
      [data-theme='dark'] .btn-group > .btn:hover,
      [data-theme='dark'] .btn-group-vertical > .btn:hover {
        z-index: 1; }
      [data-theme='dark'] .btn-group > .btn:focus, [data-theme='dark'] .btn-group > .btn:active, [data-theme='dark'] .btn-group > .btn.active,
      [data-theme='dark'] .btn-group-vertical > .btn:focus,
      [data-theme='dark'] .btn-group-vertical > .btn:active,
      [data-theme='dark'] .btn-group-vertical > .btn.active {
        z-index: 1; }
  [data-theme='dark'] .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    [data-theme='dark'] .btn-toolbar .input-group {
      width: auto; }
  [data-theme='dark'] .btn-group > .btn:not(:first-child),
  [data-theme='dark'] .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  [data-theme='dark'] .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  [data-theme='dark'] .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  [data-theme='dark'] .btn-group > .btn:not(:first-child),
  [data-theme='dark'] .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  [data-theme='dark'] .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem; }
    [data-theme='dark'] .dropdown-toggle-split::after,
    .dropup [data-theme='dark'] .dropdown-toggle-split::after,
    .dropright [data-theme='dark'] .dropdown-toggle-split::after {
      margin-left: 0; }
    .dropleft [data-theme='dark'] .dropdown-toggle-split::before {
      margin-right: 0; }
  [data-theme='dark'] .btn-sm + .dropdown-toggle-split, [data-theme='dark'] .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem; }
  [data-theme='dark'] .btn-lg + .dropdown-toggle-split, [data-theme='dark'] .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  [data-theme='dark'] .btn-group.show .dropdown-toggle {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    [data-theme='dark'] .btn-group.show .dropdown-toggle.btn-link {
      box-shadow: none; }
  [data-theme='dark'] .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    [data-theme='dark'] .btn-group-vertical > .btn,
    [data-theme='dark'] .btn-group-vertical > .btn-group {
      width: 100%; }
    [data-theme='dark'] .btn-group-vertical > .btn:not(:first-child),
    [data-theme='dark'] .btn-group-vertical > .btn-group:not(:first-child) {
      margin-top: -1px; }
    [data-theme='dark'] .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
    [data-theme='dark'] .btn-group-vertical > .btn-group:not(:last-child) > .btn {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    [data-theme='dark'] .btn-group-vertical > .btn:not(:first-child),
    [data-theme='dark'] .btn-group-vertical > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
  [data-theme='dark'] .btn-group-toggle > .btn,
  [data-theme='dark'] .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0; }
    [data-theme='dark'] .btn-group-toggle > .btn input[type="radio"],
    [data-theme='dark'] .btn-group-toggle > .btn input[type="checkbox"],
    [data-theme='dark'] .btn-group-toggle > .btn-group > .btn input[type="radio"],
    [data-theme='dark'] .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none; }
  [data-theme='dark'] .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%; }
    [data-theme='dark'] .input-group > .form-control,
    [data-theme='dark'] .input-group > .form-control-plaintext,
    [data-theme='dark'] .input-group > .custom-select,
    [data-theme='dark'] .input-group > .custom-file {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
      margin-bottom: 0; }
      [data-theme='dark'] .input-group > .form-control + .form-control,
      [data-theme='dark'] .input-group > .form-control + .custom-select,
      [data-theme='dark'] .input-group > .form-control + .custom-file,
      [data-theme='dark'] .input-group > .form-control-plaintext + .form-control,
      [data-theme='dark'] .input-group > .form-control-plaintext + .custom-select,
      [data-theme='dark'] .input-group > .form-control-plaintext + .custom-file,
      [data-theme='dark'] .input-group > .custom-select + .form-control,
      [data-theme='dark'] .input-group > .custom-select + .custom-select,
      [data-theme='dark'] .input-group > .custom-select + .custom-file,
      [data-theme='dark'] .input-group > .custom-file + .form-control,
      [data-theme='dark'] .input-group > .custom-file + .custom-select,
      [data-theme='dark'] .input-group > .custom-file + .custom-file {
        margin-left: -1px; }
    [data-theme='dark'] .input-group > .form-control:focus,
    [data-theme='dark'] .input-group > .custom-select:focus,
    [data-theme='dark'] .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
      z-index: 3; }
    [data-theme='dark'] .input-group > .custom-file .custom-file-input:focus {
      z-index: 4; }
    [data-theme='dark'] .input-group > .form-control:not(:first-child),
    [data-theme='dark'] .input-group > .custom-select:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    [data-theme='dark'] .input-group > .custom-file {
      display: flex;
      align-items: center; }
      [data-theme='dark'] .input-group > .custom-file:not(:last-child) .custom-file-label,
      [data-theme='dark'] .input-group > .custom-file:not(:last-child) .custom-file-label::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      [data-theme='dark'] .input-group > .custom-file:not(:first-child) .custom-file-label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
    [data-theme='dark'] .input-group:not(.has-validation) > .form-control:not(:last-child),
    [data-theme='dark'] .input-group:not(.has-validation) > .custom-select:not(:last-child),
    [data-theme='dark'] .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
    [data-theme='dark'] .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    [data-theme='dark'] .input-group.has-validation > .form-control:nth-last-child(n + 3),
    [data-theme='dark'] .input-group.has-validation > .custom-select:nth-last-child(n + 3),
    [data-theme='dark'] .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
    [data-theme='dark'] .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
  [data-theme='dark'] .input-group-prepend,
  [data-theme='dark'] .input-group-append {
    display: flex; }
    [data-theme='dark'] .input-group-prepend .btn,
    [data-theme='dark'] .input-group-append .btn {
      position: relative;
      z-index: 2; }
      [data-theme='dark'] .input-group-prepend .btn:focus,
      [data-theme='dark'] .input-group-append .btn:focus {
        z-index: 3; }
    [data-theme='dark'] .input-group-prepend .btn + .btn,
    [data-theme='dark'] .input-group-prepend .btn + .input-group-text,
    [data-theme='dark'] .input-group-prepend .input-group-text + .input-group-text,
    [data-theme='dark'] .input-group-prepend .input-group-text + .btn,
    [data-theme='dark'] .input-group-append .btn + .btn,
    [data-theme='dark'] .input-group-append .btn + .input-group-text,
    [data-theme='dark'] .input-group-append .input-group-text + .input-group-text,
    [data-theme='dark'] .input-group-append .input-group-text + .btn {
      margin-left: -1px; }
  [data-theme='dark'] .input-group-prepend {
    margin-right: -1px; }
  [data-theme='dark'] .input-group-append {
    margin-left: -1px; }
  [data-theme='dark'] .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 1.5;
    color: #dee2e6;
    text-align: center;
    white-space: nowrap;
    background-color: #343a40;
    border: 1px solid #6c757d;
    border-radius: 0.25rem; }
    [data-theme='dark'] .input-group-text input[type="radio"],
    [data-theme='dark'] .input-group-text input[type="checkbox"] {
      margin-top: 0; }
  [data-theme='dark'] .input-group-lg > .form-control:not(textarea),
  [data-theme='dark'] .input-group-lg > .custom-select {
    height: calc(1.5em + 1rem + 2px); }
  [data-theme='dark'] .input-group-lg > .form-control,
  [data-theme='dark'] .input-group-lg > .custom-select,
  [data-theme='dark'] .input-group-lg > .input-group-prepend > .input-group-text,
  [data-theme='dark'] .input-group-lg > .input-group-append > .input-group-text,
  [data-theme='dark'] .input-group-lg > .input-group-prepend > .btn,
  [data-theme='dark'] .input-group-lg > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.0625rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  [data-theme='dark'] .input-group-sm > .form-control:not(textarea),
  [data-theme='dark'] .input-group-sm > .custom-select {
    height: calc(1.5em + 0.5rem + 2px); }
  [data-theme='dark'] .input-group-sm > .form-control,
  [data-theme='dark'] .input-group-sm > .custom-select,
  [data-theme='dark'] .input-group-sm > .input-group-prepend > .input-group-text,
  [data-theme='dark'] .input-group-sm > .input-group-append > .input-group-text,
  [data-theme='dark'] .input-group-sm > .input-group-prepend > .btn,
  [data-theme='dark'] .input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.74375rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  [data-theme='dark'] .input-group-lg > .custom-select,
  [data-theme='dark'] .input-group-sm > .custom-select {
    padding-right: 1.75rem; }
  [data-theme='dark'] .input-group > .input-group-prepend > .btn,
  [data-theme='dark'] .input-group > .input-group-prepend > .input-group-text,
  [data-theme='dark'] .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
  [data-theme='dark'] .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
  [data-theme='dark'] .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
  [data-theme='dark'] .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
  [data-theme='dark'] .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  [data-theme='dark'] .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  [data-theme='dark'] .input-group > .input-group-append > .btn,
  [data-theme='dark'] .input-group > .input-group-append > .input-group-text,
  [data-theme='dark'] .input-group > .input-group-prepend:not(:first-child) > .btn,
  [data-theme='dark'] .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  [data-theme='dark'] .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  [data-theme='dark'] .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  [data-theme='dark'] .input-group > .input-group-append > .custom-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  [data-theme='dark'] .input-group > .input-group-prepend > .custom-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  [data-theme='dark'] .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.275rem;
    padding-left: 1.5rem;
    print-color-adjust: exact; }
  [data-theme='dark'] .custom-control-inline {
    display: inline-flex;
    margin-right: 1rem; }
  [data-theme='dark'] .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.1375rem;
    opacity: 0; }
    [data-theme='dark'] .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #3A52A2;
      background-color: #3A52A2;
      box-shadow:; }
    [data-theme='dark'] .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.075), 0 0 0 0.2rem rgba(58, 82, 162, 0.25); }
    [data-theme='dark'] .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #8799d4; }
    [data-theme='dark'] .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
      color: #fff;
      background-color: #adb9e2;
      border-color: #adb9e2;
      box-shadow:; }
    [data-theme='dark'] .custom-control-input[disabled] ~ .custom-control-label, [data-theme='dark'] .custom-control-input:disabled ~ .custom-control-label {
      color: #6c757d; }
      [data-theme='dark'] .custom-control-input[disabled] ~ .custom-control-label::before, [data-theme='dark'] .custom-control-input:disabled ~ .custom-control-label::before {
        background-color: #e9ecef; }
  [data-theme='dark'] .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top; }
    [data-theme='dark'] .custom-control-label::before {
      position: absolute;
      top: 0.1375rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      pointer-events: none;
      content: "";
      background-color: #fff;
      border: 1px solid #adb5bd;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
    [data-theme='dark'] .custom-control-label::after {
      position: absolute;
      top: 0.1375rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
      background: 50% / 50% 50% no-repeat; }
  [data-theme='dark'] .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem; }
  [data-theme='dark'] .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }
  [data-theme='dark'] .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #3A52A2;
    background-color: #3A52A2;
    box-shadow:; }
  [data-theme='dark'] .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }
  [data-theme='dark'] .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(58, 82, 162, 0.5); }
  [data-theme='dark'] .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(58, 82, 162, 0.5); }
  [data-theme='dark'] .custom-radio .custom-control-label::before {
    border-radius: 50%; }
  [data-theme='dark'] .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  [data-theme='dark'] .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(58, 82, 162, 0.5); }
  [data-theme='dark'] .custom-switch {
    padding-left: 2.25rem; }
    [data-theme='dark'] .custom-switch .custom-control-label::before {
      left: -2.25rem;
      width: 1.75rem;
      pointer-events: all;
      border-radius: 0.5rem; }
    [data-theme='dark'] .custom-switch .custom-control-label::after {
      top: calc(0.1375rem + 2px);
      left: calc(-2.25rem + 2px);
      width: calc(1rem - 4px);
      height: calc(1rem - 4px);
      background-color: #adb5bd;
      border-radius: 0.5rem;
      transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        [data-theme='dark'] .custom-switch .custom-control-label::after {
          transition: none; } }
    [data-theme='dark'] .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #fff;
      transform: translateX(0.75rem); }
    [data-theme='dark'] .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
      background-color: rgba(58, 82, 162, 0.5); }
  [data-theme='dark'] .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 1.5;
    color: #dee2e6;
    vertical-align: middle;
    background: #000 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
    border: 1px solid #6c757d;
    border-radius: 0.25rem;
    box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.075);
    appearance: none; }
    [data-theme='dark'] .custom-select:focus {
      border-color: #8799d4;
      outline: 0;
      box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.075), 0 0 0 0.2rem rgba(58, 82, 162, 0.25); }
      [data-theme='dark'] .custom-select:focus::-ms-value {
        color: #dee2e6;
        background-color: #000; }
    [data-theme='dark'] .custom-select[multiple], [data-theme='dark'] .custom-select[size]:not([size="1"]) {
      height: auto;
      padding-right: 0.75rem;
      background-image: none; }
    [data-theme='dark'] .custom-select:disabled {
      color: #ced4da;
      background-color: #343a40; }
    [data-theme='dark'] .custom-select::-ms-expand {
      display: none; }
    [data-theme='dark'] .custom-select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #dee2e6; }
  [data-theme='dark'] .custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.74375rem; }
  [data-theme='dark'] .custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.0625rem; }
  [data-theme='dark'] .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0; }
  [data-theme='dark'] .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    overflow: hidden;
    opacity: 0; }
    [data-theme='dark'] .custom-file-input:focus ~ .custom-file-label {
      border-color: #8799d4;
      box-shadow: 0 0 0 0.2rem rgba(58, 82, 162, 0.25); }
    [data-theme='dark'] .custom-file-input[disabled] ~ .custom-file-label,
    [data-theme='dark'] .custom-file-input:disabled ~ .custom-file-label {
      background-color: #e9ecef; }
    [data-theme='dark'] .custom-file-input:lang(en) ~ .custom-file-label::after {
      content: "Browse"; }
    [data-theme='dark'] .custom-file-input ~ .custom-file-label[data-browse]::after {
      content: attr(data-browse); }
  [data-theme='dark'] .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
    [data-theme='dark'] .custom-file-label::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      height: calc(1.5em + 0.75rem);
      padding: 0.375rem 0.75rem;
      line-height: 1.5;
      color: #495057;
      content: "Browse";
      background-color: #e9ecef;
      border-left: inherit;
      border-radius: 0 0.25rem 0.25rem 0; }
  [data-theme='dark'] .custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    appearance: none; }
    [data-theme='dark'] .custom-range:focus {
      outline: 0; }
      [data-theme='dark'] .custom-range:focus::-webkit-slider-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(58, 82, 162, 0.25); }
      [data-theme='dark'] .custom-range:focus::-moz-range-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(58, 82, 162, 0.25); }
      [data-theme='dark'] .custom-range:focus::-ms-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(58, 82, 162, 0.25); }
    [data-theme='dark'] .custom-range::-moz-focus-outer {
      border: 0; }
    [data-theme='dark'] .custom-range::-webkit-slider-thumb {
      width: 1rem;
      height: 1rem;
      margin-top: -0.25rem;
      background-color: #3A52A2;
      border: 0;
      border-radius: 1rem;
      box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        [data-theme='dark'] .custom-range::-webkit-slider-thumb {
          transition: none; } }
      [data-theme='dark'] .custom-range::-webkit-slider-thumb:active {
        background-color: #adb9e2; }
    [data-theme='dark'] .custom-range::-webkit-slider-runnable-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: #dee2e6;
      border-color: transparent;
      border-radius: 1rem;
      box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
    [data-theme='dark'] .custom-range::-moz-range-thumb {
      width: 1rem;
      height: 1rem;
      background-color: #3A52A2;
      border: 0;
      border-radius: 1rem;
      box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        [data-theme='dark'] .custom-range::-moz-range-thumb {
          transition: none; } }
      [data-theme='dark'] .custom-range::-moz-range-thumb:active {
        background-color: #adb9e2; }
    [data-theme='dark'] .custom-range::-moz-range-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: #dee2e6;
      border-color: transparent;
      border-radius: 1rem;
      box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
    [data-theme='dark'] .custom-range::-ms-thumb {
      width: 1rem;
      height: 1rem;
      margin-top: 0;
      margin-right: 0.2rem;
      margin-left: 0.2rem;
      background-color: #3A52A2;
      border: 0;
      border-radius: 1rem;
      box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        [data-theme='dark'] .custom-range::-ms-thumb {
          transition: none; } }
      [data-theme='dark'] .custom-range::-ms-thumb:active {
        background-color: #adb9e2; }
    [data-theme='dark'] .custom-range::-ms-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: transparent;
      border-color: transparent;
      border-width: 0.5rem;
      box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
    [data-theme='dark'] .custom-range::-ms-fill-lower {
      background-color: #dee2e6;
      border-radius: 1rem; }
    [data-theme='dark'] .custom-range::-ms-fill-upper {
      margin-right: 15px;
      background-color: #dee2e6;
      border-radius: 1rem; }
    [data-theme='dark'] .custom-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    [data-theme='dark'] .custom-range:disabled::-webkit-slider-runnable-track {
      cursor: default; }
    [data-theme='dark'] .custom-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }
    [data-theme='dark'] .custom-range:disabled::-moz-range-track {
      cursor: default; }
    [data-theme='dark'] .custom-range:disabled::-ms-thumb {
      background-color: #adb5bd; }
  [data-theme='dark'] .custom-control-label::before,
  [data-theme='dark'] .custom-file-label,
  [data-theme='dark'] .custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      [data-theme='dark'] .custom-control-label::before,
      [data-theme='dark'] .custom-file-label,
      [data-theme='dark'] .custom-select {
        transition: none; } }
  [data-theme='dark'] .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  [data-theme='dark'] .nav-link {
    display: block;
    padding: 0.5rem 1rem; }
    [data-theme='dark'] .nav-link:hover, [data-theme='dark'] .nav-link:focus {
      text-decoration: none; }
    [data-theme='dark'] .nav-link.disabled {
      color: #6c757d;
      pointer-events: none;
      cursor: default; }
  [data-theme='dark'] .nav-tabs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.125); }
    [data-theme='dark'] .nav-tabs .nav-link {
      margin-bottom: -1px;
      background-color: transparent;
      border: 1px solid transparent;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
      [data-theme='dark'] .nav-tabs .nav-link:hover, [data-theme='dark'] .nav-tabs .nav-link:focus {
        isolation: isolate;
        border-color: #495057 #495057 rgba(255, 255, 255, 0.125); }
      [data-theme='dark'] .nav-tabs .nav-link.disabled {
        color: #6c757d;
        background-color: transparent;
        border-color: transparent; }
    [data-theme='dark'] .nav-tabs .nav-link.active,
    [data-theme='dark'] .nav-tabs .nav-item.show .nav-link {
      color: #f8f9fa;
      background-color: #191d21;
      border-color: #495057 #495057 #191d21; }
    [data-theme='dark'] .nav-tabs .dropdown-menu {
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
  [data-theme='dark'] .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 0.25rem; }
  [data-theme='dark'] .nav-pills .nav-link.active,
  [data-theme='dark'] .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #3A52A2; }
  [data-theme='dark'] .nav-fill > .nav-link,
  [data-theme='dark'] .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center; }
  [data-theme='dark'] .nav-justified > .nav-link,
  [data-theme='dark'] .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center; }
  [data-theme='dark'] .tab-content > .tab-pane {
    display: none; }
  [data-theme='dark'] .tab-content > .active {
    display: block; }
  [data-theme='dark'] .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem; }
    [data-theme='dark'] .navbar .container,
    [data-theme='dark'] .navbar .container-fluid, [data-theme='dark'] .navbar .container-sm, [data-theme='dark'] .navbar .container-md, [data-theme='dark'] .navbar .container-lg, [data-theme='dark'] .navbar .container-xl {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between; }
  [data-theme='dark'] .navbar-brand {
    display: inline-block;
    padding-top: 0.34062rem;
    padding-bottom: 0.34062rem;
    margin-right: 1rem;
    font-size: 1.0625rem;
    line-height: inherit;
    white-space: nowrap; }
    [data-theme='dark'] .navbar-brand:hover, [data-theme='dark'] .navbar-brand:focus {
      text-decoration: none; }
  [data-theme='dark'] .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    [data-theme='dark'] .navbar-nav .nav-link {
      padding-right: 0;
      padding-left: 0; }
    [data-theme='dark'] .navbar-nav .dropdown-menu {
      position: static;
      float: none; }
  [data-theme='dark'] .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  [data-theme='dark'] .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center; }
  [data-theme='dark'] .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.0625rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem; }
    [data-theme='dark'] .navbar-toggler:hover, [data-theme='dark'] .navbar-toggler:focus {
      text-decoration: none; }
  [data-theme='dark'] .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: 50% / 100% 100% no-repeat; }
  [data-theme='dark'] .navbar-nav-scroll {
    max-height: 75vh;
    overflow-y: auto; }
  @media (max-width: 575.98px) {
    [data-theme='dark'] .navbar-expand-sm > .container,
    [data-theme='dark'] .navbar-expand-sm > .container-fluid, [data-theme='dark'] .navbar-expand-sm > .container-sm, [data-theme='dark'] .navbar-expand-sm > .container-md, [data-theme='dark'] .navbar-expand-sm > .container-lg, [data-theme='dark'] .navbar-expand-sm > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 576px) {
    [data-theme='dark'] .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start; }
      [data-theme='dark'] .navbar-expand-sm .navbar-nav {
        flex-direction: row; }
        [data-theme='dark'] .navbar-expand-sm .navbar-nav .dropdown-menu {
          position: absolute; }
        [data-theme='dark'] .navbar-expand-sm .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      [data-theme='dark'] .navbar-expand-sm > .container,
      [data-theme='dark'] .navbar-expand-sm > .container-fluid, [data-theme='dark'] .navbar-expand-sm > .container-sm, [data-theme='dark'] .navbar-expand-sm > .container-md, [data-theme='dark'] .navbar-expand-sm > .container-lg, [data-theme='dark'] .navbar-expand-sm > .container-xl {
        flex-wrap: nowrap; }
      [data-theme='dark'] .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible; }
      [data-theme='dark'] .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
      [data-theme='dark'] .navbar-expand-sm .navbar-toggler {
        display: none; } }
  @media (max-width: 767.98px) {
    [data-theme='dark'] .navbar-expand-md > .container,
    [data-theme='dark'] .navbar-expand-md > .container-fluid, [data-theme='dark'] .navbar-expand-md > .container-sm, [data-theme='dark'] .navbar-expand-md > .container-md, [data-theme='dark'] .navbar-expand-md > .container-lg, [data-theme='dark'] .navbar-expand-md > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 768px) {
    [data-theme='dark'] .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start; }
      [data-theme='dark'] .navbar-expand-md .navbar-nav {
        flex-direction: row; }
        [data-theme='dark'] .navbar-expand-md .navbar-nav .dropdown-menu {
          position: absolute; }
        [data-theme='dark'] .navbar-expand-md .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      [data-theme='dark'] .navbar-expand-md > .container,
      [data-theme='dark'] .navbar-expand-md > .container-fluid, [data-theme='dark'] .navbar-expand-md > .container-sm, [data-theme='dark'] .navbar-expand-md > .container-md, [data-theme='dark'] .navbar-expand-md > .container-lg, [data-theme='dark'] .navbar-expand-md > .container-xl {
        flex-wrap: nowrap; }
      [data-theme='dark'] .navbar-expand-md .navbar-nav-scroll {
        overflow: visible; }
      [data-theme='dark'] .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
      [data-theme='dark'] .navbar-expand-md .navbar-toggler {
        display: none; } }
  @media (max-width: 991.98px) {
    [data-theme='dark'] .navbar-expand-lg > .container,
    [data-theme='dark'] .navbar-expand-lg > .container-fluid, [data-theme='dark'] .navbar-expand-lg > .container-sm, [data-theme='dark'] .navbar-expand-lg > .container-md, [data-theme='dark'] .navbar-expand-lg > .container-lg, [data-theme='dark'] .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 992px) {
    [data-theme='dark'] .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start; }
      [data-theme='dark'] .navbar-expand-lg .navbar-nav {
        flex-direction: row; }
        [data-theme='dark'] .navbar-expand-lg .navbar-nav .dropdown-menu {
          position: absolute; }
        [data-theme='dark'] .navbar-expand-lg .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      [data-theme='dark'] .navbar-expand-lg > .container,
      [data-theme='dark'] .navbar-expand-lg > .container-fluid, [data-theme='dark'] .navbar-expand-lg > .container-sm, [data-theme='dark'] .navbar-expand-lg > .container-md, [data-theme='dark'] .navbar-expand-lg > .container-lg, [data-theme='dark'] .navbar-expand-lg > .container-xl {
        flex-wrap: nowrap; }
      [data-theme='dark'] .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible; }
      [data-theme='dark'] .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
      [data-theme='dark'] .navbar-expand-lg .navbar-toggler {
        display: none; } }
  @media (max-width: 1199.98px) {
    [data-theme='dark'] .navbar-expand-xl > .container,
    [data-theme='dark'] .navbar-expand-xl > .container-fluid, [data-theme='dark'] .navbar-expand-xl > .container-sm, [data-theme='dark'] .navbar-expand-xl > .container-md, [data-theme='dark'] .navbar-expand-xl > .container-lg, [data-theme='dark'] .navbar-expand-xl > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 1200px) {
    [data-theme='dark'] .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start; }
      [data-theme='dark'] .navbar-expand-xl .navbar-nav {
        flex-direction: row; }
        [data-theme='dark'] .navbar-expand-xl .navbar-nav .dropdown-menu {
          position: absolute; }
        [data-theme='dark'] .navbar-expand-xl .navbar-nav .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem; }
      [data-theme='dark'] .navbar-expand-xl > .container,
      [data-theme='dark'] .navbar-expand-xl > .container-fluid, [data-theme='dark'] .navbar-expand-xl > .container-sm, [data-theme='dark'] .navbar-expand-xl > .container-md, [data-theme='dark'] .navbar-expand-xl > .container-lg, [data-theme='dark'] .navbar-expand-xl > .container-xl {
        flex-wrap: nowrap; }
      [data-theme='dark'] .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible; }
      [data-theme='dark'] .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto; }
      [data-theme='dark'] .navbar-expand-xl .navbar-toggler {
        display: none; } }
  [data-theme='dark'] .navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    [data-theme='dark'] .navbar-expand > .container,
    [data-theme='dark'] .navbar-expand > .container-fluid, [data-theme='dark'] .navbar-expand > .container-sm, [data-theme='dark'] .navbar-expand > .container-md, [data-theme='dark'] .navbar-expand > .container-lg, [data-theme='dark'] .navbar-expand > .container-xl {
      padding-right: 0;
      padding-left: 0; }
    [data-theme='dark'] .navbar-expand .navbar-nav {
      flex-direction: row; }
      [data-theme='dark'] .navbar-expand .navbar-nav .dropdown-menu {
        position: absolute; }
      [data-theme='dark'] .navbar-expand .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    [data-theme='dark'] .navbar-expand > .container,
    [data-theme='dark'] .navbar-expand > .container-fluid, [data-theme='dark'] .navbar-expand > .container-sm, [data-theme='dark'] .navbar-expand > .container-md, [data-theme='dark'] .navbar-expand > .container-lg, [data-theme='dark'] .navbar-expand > .container-xl {
      flex-wrap: nowrap; }
    [data-theme='dark'] .navbar-expand .navbar-nav-scroll {
      overflow: visible; }
    [data-theme='dark'] .navbar-expand .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    [data-theme='dark'] .navbar-expand .navbar-toggler {
      display: none; }
  [data-theme='dark'] .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9); }
    [data-theme='dark'] .navbar-light .navbar-brand:hover, [data-theme='dark'] .navbar-light .navbar-brand:focus {
      color: rgba(0, 0, 0, 0.9); }
  [data-theme='dark'] .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5); }
    [data-theme='dark'] .navbar-light .navbar-nav .nav-link:hover, [data-theme='dark'] .navbar-light .navbar-nav .nav-link:focus {
      color: rgba(0, 0, 0, 0.7); }
    [data-theme='dark'] .navbar-light .navbar-nav .nav-link.disabled {
      color: rgba(0, 0, 0, 0.3); }
  [data-theme='dark'] .navbar-light .navbar-nav .show > .nav-link,
  [data-theme='dark'] .navbar-light .navbar-nav .active > .nav-link,
  [data-theme='dark'] .navbar-light .navbar-nav .nav-link.show,
  [data-theme='dark'] .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9); }
  [data-theme='dark'] .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1); }
  [data-theme='dark'] .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  [data-theme='dark'] .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5); }
    [data-theme='dark'] .navbar-light .navbar-text a {
      color: rgba(0, 0, 0, 0.9); }
      [data-theme='dark'] .navbar-light .navbar-text a:hover, [data-theme='dark'] .navbar-light .navbar-text a:focus {
        color: rgba(0, 0, 0, 0.9); }
  [data-theme='dark'] .navbar-dark .navbar-brand, [data-theme='dark'] .navbar-themed .navbar-brand {
    color: #fff; }
    [data-theme='dark'] .navbar-dark .navbar-brand:hover, [data-theme='dark'] .navbar-themed .navbar-brand:hover, [data-theme='dark'] .navbar-dark .navbar-brand:focus, [data-theme='dark'] .navbar-themed .navbar-brand:focus {
      color: #fff; }
  [data-theme='dark'] .navbar-dark .navbar-nav .nav-link, [data-theme='dark'] .navbar-themed .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5); }
    [data-theme='dark'] .navbar-dark .navbar-nav .nav-link:hover, [data-theme='dark'] .navbar-themed .navbar-nav .nav-link:hover, [data-theme='dark'] .navbar-dark .navbar-nav .nav-link:focus, [data-theme='dark'] .navbar-themed .navbar-nav .nav-link:focus {
      color: rgba(255, 255, 255, 0.75); }
    [data-theme='dark'] .navbar-dark .navbar-nav .nav-link.disabled, [data-theme='dark'] .navbar-themed .navbar-nav .nav-link.disabled {
      color: rgba(255, 255, 255, 0.25); }
  [data-theme='dark'] .navbar-dark .navbar-nav .show > .nav-link, [data-theme='dark'] .navbar-themed .navbar-nav .show > .nav-link,
  [data-theme='dark'] .navbar-dark .navbar-nav .active > .nav-link,
  [data-theme='dark'] .navbar-themed .navbar-nav .active > .nav-link,
  [data-theme='dark'] .navbar-dark .navbar-nav .nav-link.show,
  [data-theme='dark'] .navbar-themed .navbar-nav .nav-link.show,
  [data-theme='dark'] .navbar-dark .navbar-nav .nav-link.active,
  [data-theme='dark'] .navbar-themed .navbar-nav .nav-link.active {
    color: #fff; }
  [data-theme='dark'] .navbar-dark .navbar-toggler, [data-theme='dark'] .navbar-themed .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1); }
  [data-theme='dark'] .navbar-dark .navbar-toggler-icon, [data-theme='dark'] .navbar-themed .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  [data-theme='dark'] .navbar-dark .navbar-text, [data-theme='dark'] .navbar-themed .navbar-text {
    color: rgba(255, 255, 255, 0.5); }
    [data-theme='dark'] .navbar-dark .navbar-text a, [data-theme='dark'] .navbar-themed .navbar-text a {
      color: #fff; }
      [data-theme='dark'] .navbar-dark .navbar-text a:hover, [data-theme='dark'] .navbar-themed .navbar-text a:hover, [data-theme='dark'] .navbar-dark .navbar-text a:focus, [data-theme='dark'] .navbar-themed .navbar-text a:focus {
        color: #fff; }
  [data-theme='dark'] .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #212529;
    background-clip: border-box;
    border: 1px solid rgba(255, 255, 255, 0.125);
    border-radius: 0.25rem; }
    [data-theme='dark'] .card > hr {
      margin-right: 0;
      margin-left: 0; }
    [data-theme='dark'] .card > .list-group {
      border-top: inherit;
      border-bottom: inherit; }
      [data-theme='dark'] .card > .list-group:first-child {
        border-top-width: 0;
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px); }
      [data-theme='dark'] .card > .list-group:last-child {
        border-bottom-width: 0;
        border-bottom-right-radius: calc(0.25rem - 1px);
        border-bottom-left-radius: calc(0.25rem - 1px); }
    [data-theme='dark'] .card > .card-header + .list-group,
    [data-theme='dark'] .card > .list-group + .card-footer {
      border-top: 0; }
  [data-theme='dark'] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem; }
  [data-theme='dark'] .card-title {
    margin-bottom: 0.75rem; }
  [data-theme='dark'] .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0; }
  [data-theme='dark'] .card-text:last-child {
    margin-bottom: 0; }
  [data-theme='dark'] .card-link:hover {
    text-decoration: none; }
  [data-theme='dark'] .card-link + .card-link {
    margin-left: 1.25rem; }
  [data-theme='dark'] .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(255, 255, 255, 0.03);
    border-bottom: 1px solid rgba(255, 255, 255, 0.125); }
    [data-theme='dark'] .card-header:first-child {
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  [data-theme='dark'] .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(255, 255, 255, 0.03);
    border-top: 1px solid rgba(255, 255, 255, 0.125); }
    [data-theme='dark'] .card-footer:last-child {
      border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }
  [data-theme='dark'] .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0; }
  [data-theme='dark'] .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
  [data-theme='dark'] .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
    border-radius: calc(0.25rem - 1px); }
  [data-theme='dark'] .card-img,
  [data-theme='dark'] .card-img-top,
  [data-theme='dark'] .card-img-bottom {
    flex-shrink: 0;
    width: 100%; }
  [data-theme='dark'] .card-img,
  [data-theme='dark'] .card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  [data-theme='dark'] .card-img,
  [data-theme='dark'] .card-img-bottom {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  [data-theme='dark'] .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    [data-theme='dark'] .card-deck {
      display: flex;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      [data-theme='dark'] .card-deck .card {
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }
  [data-theme='dark'] .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    [data-theme='dark'] .card-group {
      display: flex;
      flex-flow: row wrap; }
      [data-theme='dark'] .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        [data-theme='dark'] .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        [data-theme='dark'] .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          [data-theme='dark'] .card-group > .card:not(:last-child) .card-img-top,
          [data-theme='dark'] .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          [data-theme='dark'] .card-group > .card:not(:last-child) .card-img-bottom,
          [data-theme='dark'] .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        [data-theme='dark'] .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          [data-theme='dark'] .card-group > .card:not(:first-child) .card-img-top,
          [data-theme='dark'] .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          [data-theme='dark'] .card-group > .card:not(:first-child) .card-img-bottom,
          [data-theme='dark'] .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }
  [data-theme='dark'] .card-columns .card {
    margin-bottom: 0.75rem; }
  @media (min-width: 576px) {
    [data-theme='dark'] .card-columns {
      column-count: 3;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1; }
      [data-theme='dark'] .card-columns .card {
        display: inline-block;
        width: 100%; } }
  [data-theme='dark'] .accordion {
    overflow-anchor: none; }
    [data-theme='dark'] .accordion > .card {
      overflow: hidden; }
      [data-theme='dark'] .accordion > .card:not(:last-of-type) {
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }
      [data-theme='dark'] .accordion > .card:not(:first-of-type) {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
      [data-theme='dark'] .accordion > .card > .card-header {
        border-radius: 0;
        margin-bottom: -1px; }
  [data-theme='dark'] .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #343a40;
    border-radius: 0.25rem; }
  [data-theme='dark'] .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    [data-theme='dark'] .breadcrumb-item + .breadcrumb-item::before {
      float: left;
      padding-right: 0.5rem;
      color: #ced4da;
      content: "/"; }
  [data-theme='dark'] .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  [data-theme='dark'] .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  [data-theme='dark'] .breadcrumb-item.active {
    color: #ced4da; }
  [data-theme='dark'] .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem; }
  [data-theme='dark'] .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #adadad;
    background-color: #000;
    border: 1px solid #495057; }
    [data-theme='dark'] .page-link:hover {
      z-index: 2;
      color: #878787;
      text-decoration: none;
      background-color: #343a40;
      border-color: #495057; }
    [data-theme='dark'] .page-link:focus {
      z-index: 3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(58, 82, 162, 0.25); }
  [data-theme='dark'] .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  [data-theme='dark'] .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }
  [data-theme='dark'] .page-item.active .page-link {
    z-index: 3;
    color: #000;
    background-color: #3395ff;
    border-color: #3395ff; }
  [data-theme='dark'] .page-item.disabled .page-link {
    color: #ced4da;
    pointer-events: none;
    cursor: auto;
    background-color: #000;
    border-color: #495057; }
  [data-theme='dark'] .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.0625rem;
    line-height: 1.5; }
  [data-theme='dark'] .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem; }
  [data-theme='dark'] .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem; }
  [data-theme='dark'] .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.74375rem;
    line-height: 1.5; }
  [data-theme='dark'] .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem; }
  [data-theme='dark'] .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem; }
  [data-theme='dark'] .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      [data-theme='dark'] .badge {
        transition: none; } }
    a[data-theme='dark'] .badge:hover, a[data-theme='dark'] .badge:focus {
      text-decoration: none; }
    [data-theme='dark'] .badge:empty {
      display: none; }
  [data-theme='dark'] .btn .badge {
    position: relative;
    top: -1px; }
  [data-theme='dark'] .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem; }
  [data-theme='dark'] .badge-primary {
    color: #ced4da;
    background-color: #3A52A2; }
    a[data-theme='dark'] .badge-primary:hover, a[data-theme='dark'] .badge-primary:focus {
      color: #ced4da;
      background-color: #2d3f7c; }
    a[data-theme='dark'] .badge-primary:focus, a[data-theme='dark'] .badge-primary.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(58, 82, 162, 0.5); }
  [data-theme='dark'] .badge-secondary {
    color: #ced4da;
    background-color: #6c757d; }
    a[data-theme='dark'] .badge-secondary:hover, a[data-theme='dark'] .badge-secondary:focus {
      color: #ced4da;
      background-color: #545b62; }
    a[data-theme='dark'] .badge-secondary:focus, a[data-theme='dark'] .badge-secondary.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  [data-theme='dark'] .badge-success {
    color: #ced4da;
    background-color: #3AB54A; }
    a[data-theme='dark'] .badge-success:hover, a[data-theme='dark'] .badge-success:focus {
      color: #ced4da;
      background-color: #2e8e3a; }
    a[data-theme='dark'] .badge-success:focus, a[data-theme='dark'] .badge-success.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(58, 181, 74, 0.5); }
  [data-theme='dark'] .badge-info {
    color: #ced4da;
    background-color: #25AAE2; }
    a[data-theme='dark'] .badge-info:hover, a[data-theme='dark'] .badge-info:focus {
      color: #ced4da;
      background-color: #198bbb; }
    a[data-theme='dark'] .badge-info:focus, a[data-theme='dark'] .badge-info.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(37, 170, 226, 0.5); }
  [data-theme='dark'] .badge-warning {
    color: #212529;
    background-color: #FFE89B; }
    a[data-theme='dark'] .badge-warning:hover, a[data-theme='dark'] .badge-warning:focus {
      color: #212529;
      background-color: #ffdc68; }
    a[data-theme='dark'] .badge-warning:focus, a[data-theme='dark'] .badge-warning.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(255, 232, 155, 0.5); }
  [data-theme='dark'] .badge-danger {
    color: #ced4da;
    background-color: #BF1E2E; }
    a[data-theme='dark'] .badge-danger:hover, a[data-theme='dark'] .badge-danger:focus {
      color: #ced4da;
      background-color: #931723; }
    a[data-theme='dark'] .badge-danger:focus, a[data-theme='dark'] .badge-danger.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(191, 30, 46, 0.5); }
  [data-theme='dark'] .badge-light {
    color: #212529;
    background-color: #f8f9fa; }
    a[data-theme='dark'] .badge-light:hover, a[data-theme='dark'] .badge-light:focus {
      color: #212529;
      background-color: #dae0e5; }
    a[data-theme='dark'] .badge-light:focus, a[data-theme='dark'] .badge-light.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  [data-theme='dark'] .badge-dark {
    color: #ced4da;
    background-color: #343a40; }
    a[data-theme='dark'] .badge-dark:hover, a[data-theme='dark'] .badge-dark:focus {
      color: #ced4da;
      background-color: #1d2124; }
    a[data-theme='dark'] .badge-dark:focus, a[data-theme='dark'] .badge-dark.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  [data-theme='dark'] .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #343a40;
    border-radius: 0.3rem; }
    @media (min-width: 576px) {
      [data-theme='dark'] .jumbotron {
        padding: 4rem 2rem; } }
  [data-theme='dark'] .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0; }
  [data-theme='dark'] .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem; }
  [data-theme='dark'] .alert-heading {
    color: inherit; }
  [data-theme='dark'] .alert-link {
    font-weight: 700; }
  [data-theme='dark'] .alert-dismissible {
    padding-right: 3.775rem; }
    [data-theme='dark'] .alert-dismissible .close {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      padding: 0.75rem 1.25rem;
      color: inherit; }
  [data-theme='dark'] .alert-primary {
    color: #0c1020;
    background-color: #d8dcec;
    border-color: #c8cfe5; }
    [data-theme='dark'] .alert-primary hr {
      border-top-color: #b7c0dd; }
    [data-theme='dark'] .alert-primary .alert-link {
      color: black; }
  [data-theme='dark'] .alert-secondary {
    color: #161719;
    background-color: #e2e3e5;
    border-color: #d6d8db; }
    [data-theme='dark'] .alert-secondary hr {
      border-top-color: #c8cbcf; }
    [data-theme='dark'] .alert-secondary .alert-link {
      color: black; }
  [data-theme='dark'] .alert-success {
    color: #0c240f;
    background-color: #d8f0db;
    border-color: #c8eacc; }
    [data-theme='dark'] .alert-success hr {
      border-top-color: #b6e3bb; }
    [data-theme='dark'] .alert-success .alert-link {
      color: black; }
  [data-theme='dark'] .alert-info {
    color: #07222d;
    background-color: #d3eef9;
    border-color: #c2e7f7; }
    [data-theme='dark'] .alert-info hr {
      border-top-color: #abdef4; }
    [data-theme='dark'] .alert-info .alert-link {
      color: #000101; }
  [data-theme='dark'] .alert-warning {
    color: #332e1f;
    background-color: #fffaeb;
    border-color: #fff9e3; }
    [data-theme='dark'] .alert-warning hr {
      border-top-color: #fff4ca; }
    [data-theme='dark'] .alert-warning .alert-link {
      color: #13110c; }
  [data-theme='dark'] .alert-danger {
    color: #260609;
    background-color: #f2d2d5;
    border-color: #edc0c4; }
    [data-theme='dark'] .alert-danger hr {
      border-top-color: #e7acb1; }
    [data-theme='dark'] .alert-danger .alert-link {
      color: black; }
  [data-theme='dark'] .alert-light, [data-theme='dark'] .alert-themed-inverted {
    color: #323232;
    background-color: #fefefe;
    border-color: #fdfdfe; }
    [data-theme='dark'] .alert-light hr, [data-theme='dark'] .alert-themed-inverted hr {
      border-top-color: #ececf6; }
    [data-theme='dark'] .alert-light .alert-link, [data-theme='dark'] .alert-themed-inverted .alert-link {
      color: #191919; }
  [data-theme='dark'] .alert-dark, [data-theme='dark'] .alert-themed {
    color: #0a0c0d;
    background-color: #d6d8d9;
    border-color: #c6c8ca; }
    [data-theme='dark'] .alert-dark hr, [data-theme='dark'] .alert-themed hr {
      border-top-color: #b9bbbe; }
    [data-theme='dark'] .alert-dark .alert-link, [data-theme='dark'] .alert-themed .alert-link {
      color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }
  [data-theme='dark'] .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.6375rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
    box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1); }
  [data-theme='dark'] .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #3A52A2;
    transition: width 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      [data-theme='dark'] .progress-bar {
        transition: none; } }
  [data-theme='dark'] .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem; }
  [data-theme='dark'] .progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes; }
    @media (prefers-reduced-motion: reduce) {
      [data-theme='dark'] .progress-bar-animated {
        animation: none; } }
  [data-theme='dark'] .media {
    display: flex;
    align-items: flex-start; }
  [data-theme='dark'] .media-body {
    flex: 1; }
  [data-theme='dark'] .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem; }
  [data-theme='dark'] .list-group-item-action {
    width: 100%;
    color: #dee2e6;
    text-align: inherit; }
    [data-theme='dark'] .list-group-item-action:hover, [data-theme='dark'] .list-group-item-action:focus {
      z-index: 1;
      color: #dee2e6;
      text-decoration: none;
      background-color: #212529; }
    [data-theme='dark'] .list-group-item-action:active {
      color: #d3d3d3;
      background-color: #343a40; }
  [data-theme='dark'] .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: rgba(25, 29, 33, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.125); }
    [data-theme='dark'] .list-group-item:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit; }
    [data-theme='dark'] .list-group-item:last-child {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit; }
    [data-theme='dark'] .list-group-item.disabled, [data-theme='dark'] .list-group-item:disabled {
      color: #ced4da;
      pointer-events: none;
      background-color: rgba(25, 29, 33, 0.05); }
    [data-theme='dark'] .list-group-item.active {
      z-index: 2;
      color: #000;
      background-color: #3395ff;
      border-color: #3395ff; }
    [data-theme='dark'] .list-group-item + [data-theme='dark'] .list-group-item {
      border-top-width: 0; }
      [data-theme='dark'] .list-group-item + [data-theme='dark'] .list-group-item.active {
        margin-top: -1px;
        border-top-width: 1px; }
  [data-theme='dark'] .list-group-horizontal {
    flex-direction: row; }
    [data-theme='dark'] .list-group-horizontal > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    [data-theme='dark'] .list-group-horizontal > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    [data-theme='dark'] .list-group-horizontal > .list-group-item.active {
      margin-top: 0; }
    [data-theme='dark'] .list-group-horizontal > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      [data-theme='dark'] .list-group-horizontal > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; }
  @media (min-width: 576px) {
    [data-theme='dark'] .list-group-horizontal-sm {
      flex-direction: row; }
      [data-theme='dark'] .list-group-horizontal-sm > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      [data-theme='dark'] .list-group-horizontal-sm > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      [data-theme='dark'] .list-group-horizontal-sm > .list-group-item.active {
        margin-top: 0; }
      [data-theme='dark'] .list-group-horizontal-sm > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        [data-theme='dark'] .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  @media (min-width: 768px) {
    [data-theme='dark'] .list-group-horizontal-md {
      flex-direction: row; }
      [data-theme='dark'] .list-group-horizontal-md > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      [data-theme='dark'] .list-group-horizontal-md > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      [data-theme='dark'] .list-group-horizontal-md > .list-group-item.active {
        margin-top: 0; }
      [data-theme='dark'] .list-group-horizontal-md > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        [data-theme='dark'] .list-group-horizontal-md > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  @media (min-width: 992px) {
    [data-theme='dark'] .list-group-horizontal-lg {
      flex-direction: row; }
      [data-theme='dark'] .list-group-horizontal-lg > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      [data-theme='dark'] .list-group-horizontal-lg > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      [data-theme='dark'] .list-group-horizontal-lg > .list-group-item.active {
        margin-top: 0; }
      [data-theme='dark'] .list-group-horizontal-lg > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        [data-theme='dark'] .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  @media (min-width: 1200px) {
    [data-theme='dark'] .list-group-horizontal-xl {
      flex-direction: row; }
      [data-theme='dark'] .list-group-horizontal-xl > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      [data-theme='dark'] .list-group-horizontal-xl > .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0; }
      [data-theme='dark'] .list-group-horizontal-xl > .list-group-item.active {
        margin-top: 0; }
      [data-theme='dark'] .list-group-horizontal-xl > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0; }
        [data-theme='dark'] .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
          margin-left: -1px;
          border-left-width: 1px; } }
  [data-theme='dark'] .list-group-flush {
    border-radius: 0; }
    [data-theme='dark'] .list-group-flush > .list-group-item {
      border-width: 0 0 1px; }
      [data-theme='dark'] .list-group-flush > .list-group-item:last-child {
        border-bottom-width: 0; }
  [data-theme='dark'] .list-group-item-primary {
    color: #1e2b54;
    background-color: #c8cfe5; }
    [data-theme='dark'] .list-group-item-primary.list-group-item-action:hover, [data-theme='dark'] .list-group-item-primary.list-group-item-action:focus {
      color: #1e2b54;
      background-color: #b7c0dd; }
    [data-theme='dark'] .list-group-item-primary.list-group-item-action.active {
      color: #fff;
      background-color: #1e2b54;
      border-color: #1e2b54; }
  [data-theme='dark'] .list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db; }
    [data-theme='dark'] .list-group-item-secondary.list-group-item-action:hover, [data-theme='dark'] .list-group-item-secondary.list-group-item-action:focus {
      color: #383d41;
      background-color: #c8cbcf; }
    [data-theme='dark'] .list-group-item-secondary.list-group-item-action.active {
      color: #fff;
      background-color: #383d41;
      border-color: #383d41; }
  [data-theme='dark'] .list-group-item-success {
    color: #1e5e26;
    background-color: #c8eacc; }
    [data-theme='dark'] .list-group-item-success.list-group-item-action:hover, [data-theme='dark'] .list-group-item-success.list-group-item-action:focus {
      color: #1e5e26;
      background-color: #b6e3bb; }
    [data-theme='dark'] .list-group-item-success.list-group-item-action.active {
      color: #fff;
      background-color: #1e5e26;
      border-color: #1e5e26; }
  [data-theme='dark'] .list-group-item-info {
    color: #135876;
    background-color: #c2e7f7; }
    [data-theme='dark'] .list-group-item-info.list-group-item-action:hover, [data-theme='dark'] .list-group-item-info.list-group-item-action:focus {
      color: #135876;
      background-color: #abdef4; }
    [data-theme='dark'] .list-group-item-info.list-group-item-action.active {
      color: #fff;
      background-color: #135876;
      border-color: #135876; }
  [data-theme='dark'] .list-group-item-warning {
    color: #857951;
    background-color: #fff9e3; }
    [data-theme='dark'] .list-group-item-warning.list-group-item-action:hover, [data-theme='dark'] .list-group-item-warning.list-group-item-action:focus {
      color: #857951;
      background-color: #fff4ca; }
    [data-theme='dark'] .list-group-item-warning.list-group-item-action.active {
      color: #fff;
      background-color: #857951;
      border-color: #857951; }
  [data-theme='dark'] .list-group-item-danger {
    color: #631018;
    background-color: #edc0c4; }
    [data-theme='dark'] .list-group-item-danger.list-group-item-action:hover, [data-theme='dark'] .list-group-item-danger.list-group-item-action:focus {
      color: #631018;
      background-color: #e7acb1; }
    [data-theme='dark'] .list-group-item-danger.list-group-item-action.active {
      color: #fff;
      background-color: #631018;
      border-color: #631018; }
  [data-theme='dark'] .list-group-item-light {
    color: #818182;
    background-color: #fdfdfe; }
    [data-theme='dark'] .list-group-item-light.list-group-item-action:hover, [data-theme='dark'] .list-group-item-light.list-group-item-action:focus {
      color: #818182;
      background-color: #ececf6; }
    [data-theme='dark'] .list-group-item-light.list-group-item-action.active {
      color: #fff;
      background-color: #818182;
      border-color: #818182; }
  [data-theme='dark'] .list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca; }
    [data-theme='dark'] .list-group-item-dark.list-group-item-action:hover, [data-theme='dark'] .list-group-item-dark.list-group-item-action:focus {
      color: #1b1e21;
      background-color: #b9bbbe; }
    [data-theme='dark'] .list-group-item-dark.list-group-item-action.active {
      color: #fff;
      background-color: #1b1e21;
      border-color: #1b1e21; }
  [data-theme='dark'] .close {
    float: right;
    font-size: 1.275rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-shadow: 0 1px 0 #000;
    opacity: .5; }
    [data-theme='dark'] .close:hover {
      color: #fff;
      text-decoration: none; }
    [data-theme='dark'] .close:not(:disabled):not(.disabled):hover, [data-theme='dark'] .close:not(:disabled):not(.disabled):focus {
      opacity: .75; }
  [data-theme='dark'] button.close {
    padding: 0;
    background-color: transparent;
    border: 0; }
  [data-theme='dark'] a.close.disabled {
    pointer-events: none; }
  [data-theme='dark'] .toast {
    flex-basis: 350px;
    max-width: 350px;
    font-size: 0.875rem;
    background-color: rgba(0, 0, 0, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(255, 255, 255, 0.1);
    opacity: 0;
    border-radius: 0.25rem; }
    [data-theme='dark'] .toast:not(:last-child) {
      margin-bottom: 0.75rem; }
    [data-theme='dark'] .toast.showing {
      opacity: 1; }
    [data-theme='dark'] .toast.show {
      display: block;
      opacity: 1; }
    [data-theme='dark'] .toast.hide {
      display: none; }
  [data-theme='dark'] .toast-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #ced4da;
    background-color: rgba(0, 0, 0, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  [data-theme='dark'] .toast-body {
    padding: 0.75rem; }
  [data-theme='dark'] .modal-open {
    overflow: hidden; }
    [data-theme='dark'] .modal-open .modal {
      overflow-x: hidden;
      overflow-y: auto; }
  [data-theme='dark'] .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0; }
  [data-theme='dark'] .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none; }
    .modal.fade [data-theme='dark'] .modal-dialog {
      transition: transform 0.3s ease-out;
      transform: translate(0, -50px); }
      @media (prefers-reduced-motion: reduce) {
        .modal.fade [data-theme='dark'] .modal-dialog {
          transition: none; } }
    .modal.show [data-theme='dark'] .modal-dialog {
      transform: none; }
    .modal.modal-static [data-theme='dark'] .modal-dialog {
      transform: scale(1.02); }
  [data-theme='dark'] .modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem); }
    [data-theme='dark'] .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 1rem);
      overflow: hidden; }
    [data-theme='dark'] .modal-dialog-scrollable .modal-header,
    [data-theme='dark'] .modal-dialog-scrollable .modal-footer {
      flex-shrink: 0; }
    [data-theme='dark'] .modal-dialog-scrollable .modal-body {
      overflow-y: auto; }
  [data-theme='dark'] .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem); }
    [data-theme='dark'] .modal-dialog-centered::before {
      display: block;
      height: calc(100vh - 1rem);
      height: min-content;
      content: ""; }
    [data-theme='dark'] .modal-dialog-centered.modal-dialog-scrollable {
      flex-direction: column;
      justify-content: center;
      height: 100%; }
      [data-theme='dark'] .modal-dialog-centered.modal-dialog-scrollable .modal-content {
        max-height: none; }
      [data-theme='dark'] .modal-dialog-centered.modal-dialog-scrollable::before {
        content: none; }
  [data-theme='dark'] .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #191d21;
    background-clip: padding-box;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0.3rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
    outline: 0; }
  [data-theme='dark'] .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000; }
    [data-theme='dark'] .modal-backdrop.fade {
      opacity: 0; }
    [data-theme='dark'] .modal-backdrop.show {
      opacity: 0.5; }
  [data-theme='dark'] .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #343a40;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
    [data-theme='dark'] .modal-header .close {
      padding: 1rem 1rem;
      margin: -1rem -1rem -1rem auto; }
  [data-theme='dark'] .modal-title {
    margin-bottom: 0;
    line-height: 1.5; }
  [data-theme='dark'] .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem; }
  [data-theme='dark'] .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #343a40;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px); }
    [data-theme='dark'] .modal-footer > * {
      margin: 0.25rem; }
  [data-theme='dark'] .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll; }
  @media (min-width: 576px) {
    [data-theme='dark'] .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto; }
    [data-theme='dark'] .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem); }
      [data-theme='dark'] .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem); }
    [data-theme='dark'] .modal-dialog-centered {
      min-height: calc(100% - 3.5rem); }
      [data-theme='dark'] .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
        height: min-content; }
    [data-theme='dark'] .modal-content {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
    [data-theme='dark'] .modal-sm {
      max-width: 300px; } }
  @media (min-width: 992px) {
    [data-theme='dark'] .modal-lg,
    [data-theme='dark'] .modal-xl {
      max-width: 800px; } }
  @media (min-width: 1200px) {
    [data-theme='dark'] .modal-xl {
      max-width: 1140px; } }
  [data-theme='dark'] .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: 0.74375rem;
    word-wrap: break-word;
    opacity: 0; }
    [data-theme='dark'] .tooltip.show {
      opacity: 0.9; }
    [data-theme='dark'] .tooltip .arrow {
      position: absolute;
      display: block;
      width: 0.8rem;
      height: 0.4rem; }
      [data-theme='dark'] .tooltip .arrow::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid; }
  [data-theme='dark'] .bs-tooltip-top, [data-theme='dark'] .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0; }
    [data-theme='dark'] .bs-tooltip-top .arrow, [data-theme='dark'] .bs-tooltip-auto[x-placement^="top"] .arrow {
      bottom: 0; }
      [data-theme='dark'] .bs-tooltip-top .arrow::before, [data-theme='dark'] .bs-tooltip-auto[x-placement^="top"] .arrow::before {
        top: 0;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: #000; }
  [data-theme='dark'] .bs-tooltip-right, [data-theme='dark'] .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem; }
    [data-theme='dark'] .bs-tooltip-right .arrow, [data-theme='dark'] .bs-tooltip-auto[x-placement^="right"] .arrow {
      left: 0;
      width: 0.4rem;
      height: 0.8rem; }
      [data-theme='dark'] .bs-tooltip-right .arrow::before, [data-theme='dark'] .bs-tooltip-auto[x-placement^="right"] .arrow::before {
        right: 0;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-right-color: #000; }
  [data-theme='dark'] .bs-tooltip-bottom, [data-theme='dark'] .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0; }
    [data-theme='dark'] .bs-tooltip-bottom .arrow, [data-theme='dark'] .bs-tooltip-auto[x-placement^="bottom"] .arrow {
      top: 0; }
      [data-theme='dark'] .bs-tooltip-bottom .arrow::before, [data-theme='dark'] .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
        bottom: 0;
        border-width: 0 0.4rem 0.4rem;
        border-bottom-color: #000; }
  [data-theme='dark'] .bs-tooltip-left, [data-theme='dark'] .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem; }
    [data-theme='dark'] .bs-tooltip-left .arrow, [data-theme='dark'] .bs-tooltip-auto[x-placement^="left"] .arrow {
      right: 0;
      width: 0.4rem;
      height: 0.8rem; }
      [data-theme='dark'] .bs-tooltip-left .arrow::before, [data-theme='dark'] .bs-tooltip-auto[x-placement^="left"] .arrow::before {
        left: 0;
        border-width: 0.4rem 0 0.4rem 0.4rem;
        border-left-color: #000; }
  [data-theme='dark'] .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem; }
  [data-theme='dark'] .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    white-space: normal;
    word-spacing: normal;
    line-break: auto;
    font-size: 0.74375rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2); }
    [data-theme='dark'] .popover .arrow {
      position: absolute;
      display: block;
      width: 1rem;
      height: 0.5rem;
      margin: 0 0.3rem; }
      [data-theme='dark'] .popover .arrow::before, [data-theme='dark'] .popover .arrow::after {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid; }
  [data-theme='dark'] .bs-popover-top, [data-theme='dark'] .bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem; }
    [data-theme='dark'] .bs-popover-top > .arrow, [data-theme='dark'] .bs-popover-auto[x-placement^="top"] > .arrow {
      bottom: calc(-0.5rem - 1px); }
      [data-theme='dark'] .bs-popover-top > .arrow::before, [data-theme='dark'] .bs-popover-auto[x-placement^="top"] > .arrow::before {
        bottom: 0;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: rgba(0, 0, 0, 0.25); }
      [data-theme='dark'] .bs-popover-top > .arrow::after, [data-theme='dark'] .bs-popover-auto[x-placement^="top"] > .arrow::after {
        bottom: 1px;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: #fff; }
  [data-theme='dark'] .bs-popover-right, [data-theme='dark'] .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem; }
    [data-theme='dark'] .bs-popover-right > .arrow, [data-theme='dark'] .bs-popover-auto[x-placement^="right"] > .arrow {
      left: calc(-0.5rem - 1px);
      width: 0.5rem;
      height: 1rem;
      margin: 0.3rem 0; }
      [data-theme='dark'] .bs-popover-right > .arrow::before, [data-theme='dark'] .bs-popover-auto[x-placement^="right"] > .arrow::before {
        left: 0;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: rgba(0, 0, 0, 0.25); }
      [data-theme='dark'] .bs-popover-right > .arrow::after, [data-theme='dark'] .bs-popover-auto[x-placement^="right"] > .arrow::after {
        left: 1px;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: #fff; }
  [data-theme='dark'] .bs-popover-bottom, [data-theme='dark'] .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem; }
    [data-theme='dark'] .bs-popover-bottom > .arrow, [data-theme='dark'] .bs-popover-auto[x-placement^="bottom"] > .arrow {
      top: calc(-0.5rem - 1px); }
      [data-theme='dark'] .bs-popover-bottom > .arrow::before, [data-theme='dark'] .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
        top: 0;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: rgba(0, 0, 0, 0.25); }
      [data-theme='dark'] .bs-popover-bottom > .arrow::after, [data-theme='dark'] .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
        top: 1px;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: #fff; }
    [data-theme='dark'] .bs-popover-bottom .popover-header::before, [data-theme='dark'] .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 1rem;
      margin-left: -0.5rem;
      content: "";
      border-bottom: 1px solid #f7f7f7; }
  [data-theme='dark'] .bs-popover-left, [data-theme='dark'] .bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem; }
    [data-theme='dark'] .bs-popover-left > .arrow, [data-theme='dark'] .bs-popover-auto[x-placement^="left"] > .arrow {
      right: calc(-0.5rem - 1px);
      width: 0.5rem;
      height: 1rem;
      margin: 0.3rem 0; }
      [data-theme='dark'] .bs-popover-left > .arrow::before, [data-theme='dark'] .bs-popover-auto[x-placement^="left"] > .arrow::before {
        right: 0;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: rgba(0, 0, 0, 0.25); }
      [data-theme='dark'] .bs-popover-left > .arrow::after, [data-theme='dark'] .bs-popover-auto[x-placement^="left"] > .arrow::after {
        right: 1px;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: #fff; }
  [data-theme='dark'] .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.85rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
    [data-theme='dark'] .popover-header:empty {
      display: none; }
  [data-theme='dark'] .popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529; }
  [data-theme='dark'] .carousel {
    position: relative; }
  [data-theme='dark'] .carousel.pointer-event {
    touch-action: pan-y; }
  [data-theme='dark'] .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden; }
    [data-theme='dark'] .carousel-inner::after {
      display: block;
      clear: both;
      content: ""; }
  [data-theme='dark'] .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      [data-theme='dark'] .carousel-item {
        transition: none; } }
  [data-theme='dark'] .carousel-item.active,
  [data-theme='dark'] .carousel-item-next,
  [data-theme='dark'] .carousel-item-prev {
    display: block; }
  [data-theme='dark'] .carousel-item-next:not(.carousel-item-left),
  [data-theme='dark'] .active.carousel-item-right {
    transform: translateX(100%); }
  [data-theme='dark'] .carousel-item-prev:not(.carousel-item-right),
  [data-theme='dark'] .active.carousel-item-left {
    transform: translateX(-100%); }
  [data-theme='dark'] .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none; }
  [data-theme='dark'] .carousel-fade .carousel-item.active,
  [data-theme='dark'] .carousel-fade .carousel-item-next.carousel-item-left,
  [data-theme='dark'] .carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1; }
  [data-theme='dark'] .carousel-fade .active.carousel-item-left,
  [data-theme='dark'] .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s; }
    @media (prefers-reduced-motion: reduce) {
      [data-theme='dark'] .carousel-fade .active.carousel-item-left,
      [data-theme='dark'] .carousel-fade .active.carousel-item-right {
        transition: none; } }
  [data-theme='dark'] .carousel-control-prev,
  [data-theme='dark'] .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease; }
    @media (prefers-reduced-motion: reduce) {
      [data-theme='dark'] .carousel-control-prev,
      [data-theme='dark'] .carousel-control-next {
        transition: none; } }
    [data-theme='dark'] .carousel-control-prev:hover, [data-theme='dark'] .carousel-control-prev:focus,
    [data-theme='dark'] .carousel-control-next:hover,
    [data-theme='dark'] .carousel-control-next:focus {
      color: #fff;
      text-decoration: none;
      outline: 0;
      opacity: 0.9; }
  [data-theme='dark'] .carousel-control-prev {
    left: 0; }
  [data-theme='dark'] .carousel-control-next {
    right: 0; }
  [data-theme='dark'] .carousel-control-prev-icon,
  [data-theme='dark'] .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: 50% / 100% 100% no-repeat; }
  [data-theme='dark'] .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }
  [data-theme='dark'] .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }
  [data-theme='dark'] .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none; }
    [data-theme='dark'] .carousel-indicators li {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: 30px;
      height: 3px;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      cursor: pointer;
      background-color: #fff;
      background-clip: padding-box;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      opacity: .5;
      transition: opacity 0.6s ease; }
      @media (prefers-reduced-motion: reduce) {
        [data-theme='dark'] .carousel-indicators li {
          transition: none; } }
    [data-theme='dark'] .carousel-indicators .active {
      opacity: 1; }
  [data-theme='dark'] .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }
  [data-theme='dark'] .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentcolor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border; }
  [data-theme='dark'] .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }
  [data-theme='dark'] .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    background-color: currentcolor;
    border-radius: 50%;
    opacity: 0;
    animation: .75s linear infinite spinner-grow; }
  [data-theme='dark'] .spinner-grow-sm {
    width: 1rem;
    height: 1rem; }
  @media (prefers-reduced-motion: reduce) {
    [data-theme='dark'] .spinner-border,
    [data-theme='dark'] .spinner-grow {
      animation-duration: 1.5s; } }
  [data-theme='dark'] .align-baseline {
    vertical-align: baseline !important; }
  [data-theme='dark'] .align-top {
    vertical-align: top !important; }
  [data-theme='dark'] .align-middle {
    vertical-align: middle !important; }
  [data-theme='dark'] .align-bottom {
    vertical-align: bottom !important; }
  [data-theme='dark'] .align-text-bottom {
    vertical-align: text-bottom !important; }
  [data-theme='dark'] .align-text-top {
    vertical-align: text-top !important; }
  [data-theme='dark'] .bg-primary {
    background-color: #3A52A2 !important; }
  [data-theme='dark'] a.bg-primary:hover, [data-theme='dark'] a.bg-primary:focus,
  [data-theme='dark'] button.bg-primary:hover,
  [data-theme='dark'] button.bg-primary:focus {
    background-color: #2d3f7c !important; }
  [data-theme='dark'] .bg-secondary {
    background-color: #6c757d !important; }
  [data-theme='dark'] a.bg-secondary:hover, [data-theme='dark'] a.bg-secondary:focus,
  [data-theme='dark'] button.bg-secondary:hover,
  [data-theme='dark'] button.bg-secondary:focus {
    background-color: #545b62 !important; }
  [data-theme='dark'] .bg-success {
    background-color: #3AB54A !important; }
  [data-theme='dark'] a.bg-success:hover, [data-theme='dark'] a.bg-success:focus,
  [data-theme='dark'] button.bg-success:hover,
  [data-theme='dark'] button.bg-success:focus {
    background-color: #2e8e3a !important; }
  [data-theme='dark'] .bg-info {
    background-color: #25AAE2 !important; }
  [data-theme='dark'] a.bg-info:hover, [data-theme='dark'] a.bg-info:focus,
  [data-theme='dark'] button.bg-info:hover,
  [data-theme='dark'] button.bg-info:focus {
    background-color: #198bbb !important; }
  [data-theme='dark'] .bg-warning {
    background-color: #FFE89B !important; }
  [data-theme='dark'] a.bg-warning:hover, [data-theme='dark'] a.bg-warning:focus,
  [data-theme='dark'] button.bg-warning:hover,
  [data-theme='dark'] button.bg-warning:focus {
    background-color: #ffdc68 !important; }
  [data-theme='dark'] .bg-danger {
    background-color: #BF1E2E !important; }
  [data-theme='dark'] a.bg-danger:hover, [data-theme='dark'] a.bg-danger:focus,
  [data-theme='dark'] button.bg-danger:hover,
  [data-theme='dark'] button.bg-danger:focus {
    background-color: #931723 !important; }
  [data-theme='dark'] .bg-light, [data-theme='dark'] .bg-themed-inverted {
    background-color: #f8f9fa !important; }
  [data-theme='dark'] a.bg-light:hover, [data-theme='dark'] a.bg-themed-inverted:hover, [data-theme='dark'] a.bg-light:focus, [data-theme='dark'] a.bg-themed-inverted:focus,
  [data-theme='dark'] button.bg-light:hover,
  [data-theme='dark'] button.bg-themed-inverted:hover,
  [data-theme='dark'] button.bg-light:focus,
  [data-theme='dark'] button.bg-themed-inverted:focus {
    background-color: #dae0e5 !important; }
  [data-theme='dark'] .bg-dark, [data-theme='dark'] .navbar-themed, [data-theme='dark'] .bg-themed {
    background-color: #343a40 !important; }
  [data-theme='dark'] a.bg-dark:hover, [data-theme='dark'] a.navbar-themed:hover, [data-theme='dark'] a.bg-themed:hover, [data-theme='dark'] a.bg-dark:focus, [data-theme='dark'] a.navbar-themed:focus, [data-theme='dark'] a.bg-themed:focus,
  [data-theme='dark'] button.bg-dark:hover,
  [data-theme='dark'] button.navbar-themed:hover,
  [data-theme='dark'] button.bg-themed:hover,
  [data-theme='dark'] button.bg-dark:focus,
  [data-theme='dark'] button.navbar-themed:focus,
  [data-theme='dark'] button.bg-themed:focus {
    background-color: #1d2124 !important; }
  [data-theme='dark'] .bg-white {
    background-color: #fff !important; }
  [data-theme='dark'] .bg-transparent {
    background-color: transparent !important; }
  [data-theme='dark'] .border {
    border: 1px solid #343a40 !important; }
  [data-theme='dark'] .border-top {
    border-top: 1px solid #343a40 !important; }
  [data-theme='dark'] .border-right {
    border-right: 1px solid #343a40 !important; }
  [data-theme='dark'] .border-bottom {
    border-bottom: 1px solid #343a40 !important; }
  [data-theme='dark'] .border-left {
    border-left: 1px solid #343a40 !important; }
  [data-theme='dark'] .border-0 {
    border: 0 !important; }
  [data-theme='dark'] .border-top-0 {
    border-top: 0 !important; }
  [data-theme='dark'] .border-right-0 {
    border-right: 0 !important; }
  [data-theme='dark'] .border-bottom-0 {
    border-bottom: 0 !important; }
  [data-theme='dark'] .border-left-0 {
    border-left: 0 !important; }
  [data-theme='dark'] .border-primary {
    border-color: #3A52A2 !important; }
  [data-theme='dark'] .border-secondary {
    border-color: #6c757d !important; }
  [data-theme='dark'] .border-success {
    border-color: #3AB54A !important; }
  [data-theme='dark'] .border-info {
    border-color: #25AAE2 !important; }
  [data-theme='dark'] .border-warning {
    border-color: #FFE89B !important; }
  [data-theme='dark'] .border-danger {
    border-color: #BF1E2E !important; }
  [data-theme='dark'] .border-light {
    border-color: #f8f9fa !important; }
  [data-theme='dark'] .border-dark {
    border-color: #343a40 !important; }
  [data-theme='dark'] .border-white {
    border-color: #fff !important; }
  [data-theme='dark'] .rounded-sm {
    border-radius: 0.2rem !important; }
  [data-theme='dark'] .rounded {
    border-radius: 0.25rem !important; }
  [data-theme='dark'] .rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important; }
  [data-theme='dark'] .rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important; }
  [data-theme='dark'] .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important; }
  [data-theme='dark'] .rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important; }
  [data-theme='dark'] .rounded-lg {
    border-radius: 0.3rem !important; }
  [data-theme='dark'] .rounded-circle {
    border-radius: 50% !important; }
  [data-theme='dark'] .rounded-pill {
    border-radius: 50rem !important; }
  [data-theme='dark'] .rounded-0 {
    border-radius: 0 !important; }
  [data-theme='dark'] .clearfix::after {
    display: block;
    clear: both;
    content: ""; }
  [data-theme='dark'] .d-none {
    display: none !important; }
  [data-theme='dark'] .d-inline {
    display: inline !important; }
  [data-theme='dark'] .d-inline-block {
    display: inline-block !important; }
  [data-theme='dark'] .d-block {
    display: block !important; }
  [data-theme='dark'] .d-table {
    display: table !important; }
  [data-theme='dark'] .d-table-row {
    display: table-row !important; }
  [data-theme='dark'] .d-table-cell {
    display: table-cell !important; }
  [data-theme='dark'] .d-flex {
    display: flex !important; }
  [data-theme='dark'] .d-inline-flex {
    display: inline-flex !important; }
  @media (min-width: 576px) {
    [data-theme='dark'] .d-sm-none {
      display: none !important; }
    [data-theme='dark'] .d-sm-inline {
      display: inline !important; }
    [data-theme='dark'] .d-sm-inline-block {
      display: inline-block !important; }
    [data-theme='dark'] .d-sm-block {
      display: block !important; }
    [data-theme='dark'] .d-sm-table {
      display: table !important; }
    [data-theme='dark'] .d-sm-table-row {
      display: table-row !important; }
    [data-theme='dark'] .d-sm-table-cell {
      display: table-cell !important; }
    [data-theme='dark'] .d-sm-flex {
      display: flex !important; }
    [data-theme='dark'] .d-sm-inline-flex {
      display: inline-flex !important; } }
  @media (min-width: 768px) {
    [data-theme='dark'] .d-md-none {
      display: none !important; }
    [data-theme='dark'] .d-md-inline {
      display: inline !important; }
    [data-theme='dark'] .d-md-inline-block {
      display: inline-block !important; }
    [data-theme='dark'] .d-md-block {
      display: block !important; }
    [data-theme='dark'] .d-md-table {
      display: table !important; }
    [data-theme='dark'] .d-md-table-row {
      display: table-row !important; }
    [data-theme='dark'] .d-md-table-cell {
      display: table-cell !important; }
    [data-theme='dark'] .d-md-flex {
      display: flex !important; }
    [data-theme='dark'] .d-md-inline-flex {
      display: inline-flex !important; } }
  @media (min-width: 992px) {
    [data-theme='dark'] .d-lg-none {
      display: none !important; }
    [data-theme='dark'] .d-lg-inline {
      display: inline !important; }
    [data-theme='dark'] .d-lg-inline-block {
      display: inline-block !important; }
    [data-theme='dark'] .d-lg-block {
      display: block !important; }
    [data-theme='dark'] .d-lg-table {
      display: table !important; }
    [data-theme='dark'] .d-lg-table-row {
      display: table-row !important; }
    [data-theme='dark'] .d-lg-table-cell {
      display: table-cell !important; }
    [data-theme='dark'] .d-lg-flex {
      display: flex !important; }
    [data-theme='dark'] .d-lg-inline-flex {
      display: inline-flex !important; } }
  @media (min-width: 1200px) {
    [data-theme='dark'] .d-xl-none {
      display: none !important; }
    [data-theme='dark'] .d-xl-inline {
      display: inline !important; }
    [data-theme='dark'] .d-xl-inline-block {
      display: inline-block !important; }
    [data-theme='dark'] .d-xl-block {
      display: block !important; }
    [data-theme='dark'] .d-xl-table {
      display: table !important; }
    [data-theme='dark'] .d-xl-table-row {
      display: table-row !important; }
    [data-theme='dark'] .d-xl-table-cell {
      display: table-cell !important; }
    [data-theme='dark'] .d-xl-flex {
      display: flex !important; }
    [data-theme='dark'] .d-xl-inline-flex {
      display: inline-flex !important; } }
  @media print {
    [data-theme='dark'] .d-print-none {
      display: none !important; }
    [data-theme='dark'] .d-print-inline {
      display: inline !important; }
    [data-theme='dark'] .d-print-inline-block {
      display: inline-block !important; }
    [data-theme='dark'] .d-print-block {
      display: block !important; }
    [data-theme='dark'] .d-print-table {
      display: table !important; }
    [data-theme='dark'] .d-print-table-row {
      display: table-row !important; }
    [data-theme='dark'] .d-print-table-cell {
      display: table-cell !important; }
    [data-theme='dark'] .d-print-flex {
      display: flex !important; }
    [data-theme='dark'] .d-print-inline-flex {
      display: inline-flex !important; } }
  [data-theme='dark'] .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden; }
    [data-theme='dark'] .embed-responsive::before {
      display: block;
      content: ""; }
    [data-theme='dark'] .embed-responsive .embed-responsive-item,
    [data-theme='dark'] .embed-responsive iframe,
    [data-theme='dark'] .embed-responsive embed,
    [data-theme='dark'] .embed-responsive object,
    [data-theme='dark'] .embed-responsive video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0; }
  [data-theme='dark'] .embed-responsive-21by9::before {
    padding-top: 42.85714%; }
  [data-theme='dark'] .embed-responsive-16by9::before {
    padding-top: 56.25%; }
  [data-theme='dark'] .embed-responsive-4by3::before {
    padding-top: 75%; }
  [data-theme='dark'] .embed-responsive-1by1::before {
    padding-top: 100%; }
  [data-theme='dark'] .flex-row {
    flex-direction: row !important; }
  [data-theme='dark'] .flex-column {
    flex-direction: column !important; }
  [data-theme='dark'] .flex-row-reverse {
    flex-direction: row-reverse !important; }
  [data-theme='dark'] .flex-column-reverse {
    flex-direction: column-reverse !important; }
  [data-theme='dark'] .flex-wrap {
    flex-wrap: wrap !important; }
  [data-theme='dark'] .flex-nowrap {
    flex-wrap: nowrap !important; }
  [data-theme='dark'] .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  [data-theme='dark'] .flex-fill {
    flex: 1 1 auto !important; }
  [data-theme='dark'] .flex-grow-0 {
    flex-grow: 0 !important; }
  [data-theme='dark'] .flex-grow-1 {
    flex-grow: 1 !important; }
  [data-theme='dark'] .flex-shrink-0 {
    flex-shrink: 0 !important; }
  [data-theme='dark'] .flex-shrink-1 {
    flex-shrink: 1 !important; }
  [data-theme='dark'] .justify-content-start {
    justify-content: flex-start !important; }
  [data-theme='dark'] .justify-content-end {
    justify-content: flex-end !important; }
  [data-theme='dark'] .justify-content-center {
    justify-content: center !important; }
  [data-theme='dark'] .justify-content-between {
    justify-content: space-between !important; }
  [data-theme='dark'] .justify-content-around {
    justify-content: space-around !important; }
  [data-theme='dark'] .align-items-start {
    align-items: flex-start !important; }
  [data-theme='dark'] .align-items-end {
    align-items: flex-end !important; }
  [data-theme='dark'] .align-items-center {
    align-items: center !important; }
  [data-theme='dark'] .align-items-baseline {
    align-items: baseline !important; }
  [data-theme='dark'] .align-items-stretch {
    align-items: stretch !important; }
  [data-theme='dark'] .align-content-start {
    align-content: flex-start !important; }
  [data-theme='dark'] .align-content-end {
    align-content: flex-end !important; }
  [data-theme='dark'] .align-content-center {
    align-content: center !important; }
  [data-theme='dark'] .align-content-between {
    align-content: space-between !important; }
  [data-theme='dark'] .align-content-around {
    align-content: space-around !important; }
  [data-theme='dark'] .align-content-stretch {
    align-content: stretch !important; }
  [data-theme='dark'] .align-self-auto {
    align-self: auto !important; }
  [data-theme='dark'] .align-self-start {
    align-self: flex-start !important; }
  [data-theme='dark'] .align-self-end {
    align-self: flex-end !important; }
  [data-theme='dark'] .align-self-center {
    align-self: center !important; }
  [data-theme='dark'] .align-self-baseline {
    align-self: baseline !important; }
  [data-theme='dark'] .align-self-stretch {
    align-self: stretch !important; }
  @media (min-width: 576px) {
    [data-theme='dark'] .flex-sm-row {
      flex-direction: row !important; }
    [data-theme='dark'] .flex-sm-column {
      flex-direction: column !important; }
    [data-theme='dark'] .flex-sm-row-reverse {
      flex-direction: row-reverse !important; }
    [data-theme='dark'] .flex-sm-column-reverse {
      flex-direction: column-reverse !important; }
    [data-theme='dark'] .flex-sm-wrap {
      flex-wrap: wrap !important; }
    [data-theme='dark'] .flex-sm-nowrap {
      flex-wrap: nowrap !important; }
    [data-theme='dark'] .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    [data-theme='dark'] .flex-sm-fill {
      flex: 1 1 auto !important; }
    [data-theme='dark'] .flex-sm-grow-0 {
      flex-grow: 0 !important; }
    [data-theme='dark'] .flex-sm-grow-1 {
      flex-grow: 1 !important; }
    [data-theme='dark'] .flex-sm-shrink-0 {
      flex-shrink: 0 !important; }
    [data-theme='dark'] .flex-sm-shrink-1 {
      flex-shrink: 1 !important; }
    [data-theme='dark'] .justify-content-sm-start {
      justify-content: flex-start !important; }
    [data-theme='dark'] .justify-content-sm-end {
      justify-content: flex-end !important; }
    [data-theme='dark'] .justify-content-sm-center {
      justify-content: center !important; }
    [data-theme='dark'] .justify-content-sm-between {
      justify-content: space-between !important; }
    [data-theme='dark'] .justify-content-sm-around {
      justify-content: space-around !important; }
    [data-theme='dark'] .align-items-sm-start {
      align-items: flex-start !important; }
    [data-theme='dark'] .align-items-sm-end {
      align-items: flex-end !important; }
    [data-theme='dark'] .align-items-sm-center {
      align-items: center !important; }
    [data-theme='dark'] .align-items-sm-baseline {
      align-items: baseline !important; }
    [data-theme='dark'] .align-items-sm-stretch {
      align-items: stretch !important; }
    [data-theme='dark'] .align-content-sm-start {
      align-content: flex-start !important; }
    [data-theme='dark'] .align-content-sm-end {
      align-content: flex-end !important; }
    [data-theme='dark'] .align-content-sm-center {
      align-content: center !important; }
    [data-theme='dark'] .align-content-sm-between {
      align-content: space-between !important; }
    [data-theme='dark'] .align-content-sm-around {
      align-content: space-around !important; }
    [data-theme='dark'] .align-content-sm-stretch {
      align-content: stretch !important; }
    [data-theme='dark'] .align-self-sm-auto {
      align-self: auto !important; }
    [data-theme='dark'] .align-self-sm-start {
      align-self: flex-start !important; }
    [data-theme='dark'] .align-self-sm-end {
      align-self: flex-end !important; }
    [data-theme='dark'] .align-self-sm-center {
      align-self: center !important; }
    [data-theme='dark'] .align-self-sm-baseline {
      align-self: baseline !important; }
    [data-theme='dark'] .align-self-sm-stretch {
      align-self: stretch !important; } }
  @media (min-width: 768px) {
    [data-theme='dark'] .flex-md-row {
      flex-direction: row !important; }
    [data-theme='dark'] .flex-md-column {
      flex-direction: column !important; }
    [data-theme='dark'] .flex-md-row-reverse {
      flex-direction: row-reverse !important; }
    [data-theme='dark'] .flex-md-column-reverse {
      flex-direction: column-reverse !important; }
    [data-theme='dark'] .flex-md-wrap {
      flex-wrap: wrap !important; }
    [data-theme='dark'] .flex-md-nowrap {
      flex-wrap: nowrap !important; }
    [data-theme='dark'] .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    [data-theme='dark'] .flex-md-fill {
      flex: 1 1 auto !important; }
    [data-theme='dark'] .flex-md-grow-0 {
      flex-grow: 0 !important; }
    [data-theme='dark'] .flex-md-grow-1 {
      flex-grow: 1 !important; }
    [data-theme='dark'] .flex-md-shrink-0 {
      flex-shrink: 0 !important; }
    [data-theme='dark'] .flex-md-shrink-1 {
      flex-shrink: 1 !important; }
    [data-theme='dark'] .justify-content-md-start {
      justify-content: flex-start !important; }
    [data-theme='dark'] .justify-content-md-end {
      justify-content: flex-end !important; }
    [data-theme='dark'] .justify-content-md-center {
      justify-content: center !important; }
    [data-theme='dark'] .justify-content-md-between {
      justify-content: space-between !important; }
    [data-theme='dark'] .justify-content-md-around {
      justify-content: space-around !important; }
    [data-theme='dark'] .align-items-md-start {
      align-items: flex-start !important; }
    [data-theme='dark'] .align-items-md-end {
      align-items: flex-end !important; }
    [data-theme='dark'] .align-items-md-center {
      align-items: center !important; }
    [data-theme='dark'] .align-items-md-baseline {
      align-items: baseline !important; }
    [data-theme='dark'] .align-items-md-stretch {
      align-items: stretch !important; }
    [data-theme='dark'] .align-content-md-start {
      align-content: flex-start !important; }
    [data-theme='dark'] .align-content-md-end {
      align-content: flex-end !important; }
    [data-theme='dark'] .align-content-md-center {
      align-content: center !important; }
    [data-theme='dark'] .align-content-md-between {
      align-content: space-between !important; }
    [data-theme='dark'] .align-content-md-around {
      align-content: space-around !important; }
    [data-theme='dark'] .align-content-md-stretch {
      align-content: stretch !important; }
    [data-theme='dark'] .align-self-md-auto {
      align-self: auto !important; }
    [data-theme='dark'] .align-self-md-start {
      align-self: flex-start !important; }
    [data-theme='dark'] .align-self-md-end {
      align-self: flex-end !important; }
    [data-theme='dark'] .align-self-md-center {
      align-self: center !important; }
    [data-theme='dark'] .align-self-md-baseline {
      align-self: baseline !important; }
    [data-theme='dark'] .align-self-md-stretch {
      align-self: stretch !important; } }
  @media (min-width: 992px) {
    [data-theme='dark'] .flex-lg-row {
      flex-direction: row !important; }
    [data-theme='dark'] .flex-lg-column {
      flex-direction: column !important; }
    [data-theme='dark'] .flex-lg-row-reverse {
      flex-direction: row-reverse !important; }
    [data-theme='dark'] .flex-lg-column-reverse {
      flex-direction: column-reverse !important; }
    [data-theme='dark'] .flex-lg-wrap {
      flex-wrap: wrap !important; }
    [data-theme='dark'] .flex-lg-nowrap {
      flex-wrap: nowrap !important; }
    [data-theme='dark'] .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    [data-theme='dark'] .flex-lg-fill {
      flex: 1 1 auto !important; }
    [data-theme='dark'] .flex-lg-grow-0 {
      flex-grow: 0 !important; }
    [data-theme='dark'] .flex-lg-grow-1 {
      flex-grow: 1 !important; }
    [data-theme='dark'] .flex-lg-shrink-0 {
      flex-shrink: 0 !important; }
    [data-theme='dark'] .flex-lg-shrink-1 {
      flex-shrink: 1 !important; }
    [data-theme='dark'] .justify-content-lg-start {
      justify-content: flex-start !important; }
    [data-theme='dark'] .justify-content-lg-end {
      justify-content: flex-end !important; }
    [data-theme='dark'] .justify-content-lg-center {
      justify-content: center !important; }
    [data-theme='dark'] .justify-content-lg-between {
      justify-content: space-between !important; }
    [data-theme='dark'] .justify-content-lg-around {
      justify-content: space-around !important; }
    [data-theme='dark'] .align-items-lg-start {
      align-items: flex-start !important; }
    [data-theme='dark'] .align-items-lg-end {
      align-items: flex-end !important; }
    [data-theme='dark'] .align-items-lg-center {
      align-items: center !important; }
    [data-theme='dark'] .align-items-lg-baseline {
      align-items: baseline !important; }
    [data-theme='dark'] .align-items-lg-stretch {
      align-items: stretch !important; }
    [data-theme='dark'] .align-content-lg-start {
      align-content: flex-start !important; }
    [data-theme='dark'] .align-content-lg-end {
      align-content: flex-end !important; }
    [data-theme='dark'] .align-content-lg-center {
      align-content: center !important; }
    [data-theme='dark'] .align-content-lg-between {
      align-content: space-between !important; }
    [data-theme='dark'] .align-content-lg-around {
      align-content: space-around !important; }
    [data-theme='dark'] .align-content-lg-stretch {
      align-content: stretch !important; }
    [data-theme='dark'] .align-self-lg-auto {
      align-self: auto !important; }
    [data-theme='dark'] .align-self-lg-start {
      align-self: flex-start !important; }
    [data-theme='dark'] .align-self-lg-end {
      align-self: flex-end !important; }
    [data-theme='dark'] .align-self-lg-center {
      align-self: center !important; }
    [data-theme='dark'] .align-self-lg-baseline {
      align-self: baseline !important; }
    [data-theme='dark'] .align-self-lg-stretch {
      align-self: stretch !important; } }
  @media (min-width: 1200px) {
    [data-theme='dark'] .flex-xl-row {
      flex-direction: row !important; }
    [data-theme='dark'] .flex-xl-column {
      flex-direction: column !important; }
    [data-theme='dark'] .flex-xl-row-reverse {
      flex-direction: row-reverse !important; }
    [data-theme='dark'] .flex-xl-column-reverse {
      flex-direction: column-reverse !important; }
    [data-theme='dark'] .flex-xl-wrap {
      flex-wrap: wrap !important; }
    [data-theme='dark'] .flex-xl-nowrap {
      flex-wrap: nowrap !important; }
    [data-theme='dark'] .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    [data-theme='dark'] .flex-xl-fill {
      flex: 1 1 auto !important; }
    [data-theme='dark'] .flex-xl-grow-0 {
      flex-grow: 0 !important; }
    [data-theme='dark'] .flex-xl-grow-1 {
      flex-grow: 1 !important; }
    [data-theme='dark'] .flex-xl-shrink-0 {
      flex-shrink: 0 !important; }
    [data-theme='dark'] .flex-xl-shrink-1 {
      flex-shrink: 1 !important; }
    [data-theme='dark'] .justify-content-xl-start {
      justify-content: flex-start !important; }
    [data-theme='dark'] .justify-content-xl-end {
      justify-content: flex-end !important; }
    [data-theme='dark'] .justify-content-xl-center {
      justify-content: center !important; }
    [data-theme='dark'] .justify-content-xl-between {
      justify-content: space-between !important; }
    [data-theme='dark'] .justify-content-xl-around {
      justify-content: space-around !important; }
    [data-theme='dark'] .align-items-xl-start {
      align-items: flex-start !important; }
    [data-theme='dark'] .align-items-xl-end {
      align-items: flex-end !important; }
    [data-theme='dark'] .align-items-xl-center {
      align-items: center !important; }
    [data-theme='dark'] .align-items-xl-baseline {
      align-items: baseline !important; }
    [data-theme='dark'] .align-items-xl-stretch {
      align-items: stretch !important; }
    [data-theme='dark'] .align-content-xl-start {
      align-content: flex-start !important; }
    [data-theme='dark'] .align-content-xl-end {
      align-content: flex-end !important; }
    [data-theme='dark'] .align-content-xl-center {
      align-content: center !important; }
    [data-theme='dark'] .align-content-xl-between {
      align-content: space-between !important; }
    [data-theme='dark'] .align-content-xl-around {
      align-content: space-around !important; }
    [data-theme='dark'] .align-content-xl-stretch {
      align-content: stretch !important; }
    [data-theme='dark'] .align-self-xl-auto {
      align-self: auto !important; }
    [data-theme='dark'] .align-self-xl-start {
      align-self: flex-start !important; }
    [data-theme='dark'] .align-self-xl-end {
      align-self: flex-end !important; }
    [data-theme='dark'] .align-self-xl-center {
      align-self: center !important; }
    [data-theme='dark'] .align-self-xl-baseline {
      align-self: baseline !important; }
    [data-theme='dark'] .align-self-xl-stretch {
      align-self: stretch !important; } }
  [data-theme='dark'] .float-left {
    float: left !important; }
  [data-theme='dark'] .float-right {
    float: right !important; }
  [data-theme='dark'] .float-none {
    float: none !important; }
  @media (min-width: 576px) {
    [data-theme='dark'] .float-sm-left {
      float: left !important; }
    [data-theme='dark'] .float-sm-right {
      float: right !important; }
    [data-theme='dark'] .float-sm-none {
      float: none !important; } }
  @media (min-width: 768px) {
    [data-theme='dark'] .float-md-left {
      float: left !important; }
    [data-theme='dark'] .float-md-right {
      float: right !important; }
    [data-theme='dark'] .float-md-none {
      float: none !important; } }
  @media (min-width: 992px) {
    [data-theme='dark'] .float-lg-left {
      float: left !important; }
    [data-theme='dark'] .float-lg-right {
      float: right !important; }
    [data-theme='dark'] .float-lg-none {
      float: none !important; } }
  @media (min-width: 1200px) {
    [data-theme='dark'] .float-xl-left {
      float: left !important; }
    [data-theme='dark'] .float-xl-right {
      float: right !important; }
    [data-theme='dark'] .float-xl-none {
      float: none !important; } }
  [data-theme='dark'] .user-select-all {
    user-select: all !important; }
  [data-theme='dark'] .user-select-auto {
    user-select: auto !important; }
  [data-theme='dark'] .user-select-none {
    user-select: none !important; }
  [data-theme='dark'] .overflow-auto {
    overflow: auto !important; }
  [data-theme='dark'] .overflow-hidden {
    overflow: hidden !important; }
  [data-theme='dark'] .position-static {
    position: static !important; }
  [data-theme='dark'] .position-relative {
    position: relative !important; }
  [data-theme='dark'] .position-absolute {
    position: absolute !important; }
  [data-theme='dark'] .position-fixed {
    position: fixed !important; }
  [data-theme='dark'] .position-sticky {
    position: sticky !important; }
  [data-theme='dark'] .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }
  [data-theme='dark'] .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030; }
  @supports (position: sticky) {
    [data-theme='dark'] .sticky-top {
      position: sticky;
      top: 0;
      z-index: 1020; } }
  [data-theme='dark'] .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0; }
  [data-theme='dark'] .sr-only-focusable:active, [data-theme='dark'] .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal; }
  [data-theme='dark'] .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }
  [data-theme='dark'] .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }
  [data-theme='dark'] .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  [data-theme='dark'] .shadow-none {
    box-shadow: none !important; }
  [data-theme='dark'] .w-25 {
    width: 25% !important; }
  [data-theme='dark'] .w-50 {
    width: 50% !important; }
  [data-theme='dark'] .w-75 {
    width: 75% !important; }
  [data-theme='dark'] .w-100 {
    width: 100% !important; }
  [data-theme='dark'] .w-auto {
    width: auto !important; }
  [data-theme='dark'] .h-25 {
    height: 25% !important; }
  [data-theme='dark'] .h-50 {
    height: 50% !important; }
  [data-theme='dark'] .h-75 {
    height: 75% !important; }
  [data-theme='dark'] .h-100 {
    height: 100% !important; }
  [data-theme='dark'] .h-auto {
    height: auto !important; }
  [data-theme='dark'] .mw-100 {
    max-width: 100% !important; }
  [data-theme='dark'] .mh-100 {
    max-height: 100% !important; }
  [data-theme='dark'] .min-vw-100 {
    min-width: 100vw !important; }
  [data-theme='dark'] .min-vh-100 {
    min-height: 100vh !important; }
  [data-theme='dark'] .vw-100 {
    width: 100vw !important; }
  [data-theme='dark'] .vh-100 {
    height: 100vh !important; }
  [data-theme='dark'] .m-0 {
    margin: 0 !important; }
  [data-theme='dark'] .mt-0,
  [data-theme='dark'] .my-0 {
    margin-top: 0 !important; }
  [data-theme='dark'] .mr-0,
  [data-theme='dark'] .mx-0 {
    margin-right: 0 !important; }
  [data-theme='dark'] .mb-0,
  [data-theme='dark'] .my-0 {
    margin-bottom: 0 !important; }
  [data-theme='dark'] .ml-0,
  [data-theme='dark'] .mx-0 {
    margin-left: 0 !important; }
  [data-theme='dark'] .m-1 {
    margin: 0.25rem !important; }
  [data-theme='dark'] .mt-1,
  [data-theme='dark'] .my-1 {
    margin-top: 0.25rem !important; }
  [data-theme='dark'] .mr-1,
  [data-theme='dark'] .mx-1 {
    margin-right: 0.25rem !important; }
  [data-theme='dark'] .mb-1,
  [data-theme='dark'] .my-1 {
    margin-bottom: 0.25rem !important; }
  [data-theme='dark'] .ml-1,
  [data-theme='dark'] .mx-1 {
    margin-left: 0.25rem !important; }
  [data-theme='dark'] .m-2 {
    margin: 0.5rem !important; }
  [data-theme='dark'] .mt-2,
  [data-theme='dark'] .my-2 {
    margin-top: 0.5rem !important; }
  [data-theme='dark'] .mr-2,
  [data-theme='dark'] .mx-2 {
    margin-right: 0.5rem !important; }
  [data-theme='dark'] .mb-2,
  [data-theme='dark'] .my-2 {
    margin-bottom: 0.5rem !important; }
  [data-theme='dark'] .ml-2,
  [data-theme='dark'] .mx-2 {
    margin-left: 0.5rem !important; }
  [data-theme='dark'] .m-3 {
    margin: 1rem !important; }
  [data-theme='dark'] .mt-3,
  [data-theme='dark'] .my-3 {
    margin-top: 1rem !important; }
  [data-theme='dark'] .mr-3,
  [data-theme='dark'] .mx-3 {
    margin-right: 1rem !important; }
  [data-theme='dark'] .mb-3,
  [data-theme='dark'] .my-3 {
    margin-bottom: 1rem !important; }
  [data-theme='dark'] .ml-3,
  [data-theme='dark'] .mx-3 {
    margin-left: 1rem !important; }
  [data-theme='dark'] .m-4 {
    margin: 1.5rem !important; }
  [data-theme='dark'] .mt-4,
  [data-theme='dark'] .my-4 {
    margin-top: 1.5rem !important; }
  [data-theme='dark'] .mr-4,
  [data-theme='dark'] .mx-4 {
    margin-right: 1.5rem !important; }
  [data-theme='dark'] .mb-4,
  [data-theme='dark'] .my-4 {
    margin-bottom: 1.5rem !important; }
  [data-theme='dark'] .ml-4,
  [data-theme='dark'] .mx-4 {
    margin-left: 1.5rem !important; }
  [data-theme='dark'] .m-5 {
    margin: 3rem !important; }
  [data-theme='dark'] .mt-5,
  [data-theme='dark'] .my-5 {
    margin-top: 3rem !important; }
  [data-theme='dark'] .mr-5,
  [data-theme='dark'] .mx-5 {
    margin-right: 3rem !important; }
  [data-theme='dark'] .mb-5,
  [data-theme='dark'] .my-5 {
    margin-bottom: 3rem !important; }
  [data-theme='dark'] .ml-5,
  [data-theme='dark'] .mx-5 {
    margin-left: 3rem !important; }
  [data-theme='dark'] .p-0 {
    padding: 0 !important; }
  [data-theme='dark'] .pt-0,
  [data-theme='dark'] .py-0 {
    padding-top: 0 !important; }
  [data-theme='dark'] .pr-0,
  [data-theme='dark'] .px-0 {
    padding-right: 0 !important; }
  [data-theme='dark'] .pb-0,
  [data-theme='dark'] .py-0 {
    padding-bottom: 0 !important; }
  [data-theme='dark'] .pl-0,
  [data-theme='dark'] .px-0 {
    padding-left: 0 !important; }
  [data-theme='dark'] .p-1 {
    padding: 0.25rem !important; }
  [data-theme='dark'] .pt-1,
  [data-theme='dark'] .py-1 {
    padding-top: 0.25rem !important; }
  [data-theme='dark'] .pr-1,
  [data-theme='dark'] .px-1 {
    padding-right: 0.25rem !important; }
  [data-theme='dark'] .pb-1,
  [data-theme='dark'] .py-1 {
    padding-bottom: 0.25rem !important; }
  [data-theme='dark'] .pl-1,
  [data-theme='dark'] .px-1 {
    padding-left: 0.25rem !important; }
  [data-theme='dark'] .p-2 {
    padding: 0.5rem !important; }
  [data-theme='dark'] .pt-2,
  [data-theme='dark'] .py-2 {
    padding-top: 0.5rem !important; }
  [data-theme='dark'] .pr-2,
  [data-theme='dark'] .px-2 {
    padding-right: 0.5rem !important; }
  [data-theme='dark'] .pb-2,
  [data-theme='dark'] .py-2 {
    padding-bottom: 0.5rem !important; }
  [data-theme='dark'] .pl-2,
  [data-theme='dark'] .px-2 {
    padding-left: 0.5rem !important; }
  [data-theme='dark'] .p-3 {
    padding: 1rem !important; }
  [data-theme='dark'] .pt-3,
  [data-theme='dark'] .py-3 {
    padding-top: 1rem !important; }
  [data-theme='dark'] .pr-3,
  [data-theme='dark'] .px-3 {
    padding-right: 1rem !important; }
  [data-theme='dark'] .pb-3,
  [data-theme='dark'] .py-3 {
    padding-bottom: 1rem !important; }
  [data-theme='dark'] .pl-3,
  [data-theme='dark'] .px-3 {
    padding-left: 1rem !important; }
  [data-theme='dark'] .p-4 {
    padding: 1.5rem !important; }
  [data-theme='dark'] .pt-4,
  [data-theme='dark'] .py-4 {
    padding-top: 1.5rem !important; }
  [data-theme='dark'] .pr-4,
  [data-theme='dark'] .px-4 {
    padding-right: 1.5rem !important; }
  [data-theme='dark'] .pb-4,
  [data-theme='dark'] .py-4 {
    padding-bottom: 1.5rem !important; }
  [data-theme='dark'] .pl-4,
  [data-theme='dark'] .px-4 {
    padding-left: 1.5rem !important; }
  [data-theme='dark'] .p-5 {
    padding: 3rem !important; }
  [data-theme='dark'] .pt-5,
  [data-theme='dark'] .py-5 {
    padding-top: 3rem !important; }
  [data-theme='dark'] .pr-5,
  [data-theme='dark'] .px-5 {
    padding-right: 3rem !important; }
  [data-theme='dark'] .pb-5,
  [data-theme='dark'] .py-5 {
    padding-bottom: 3rem !important; }
  [data-theme='dark'] .pl-5,
  [data-theme='dark'] .px-5 {
    padding-left: 3rem !important; }
  [data-theme='dark'] .m-n1 {
    margin: -0.25rem !important; }
  [data-theme='dark'] .mt-n1,
  [data-theme='dark'] .my-n1 {
    margin-top: -0.25rem !important; }
  [data-theme='dark'] .mr-n1,
  [data-theme='dark'] .mx-n1 {
    margin-right: -0.25rem !important; }
  [data-theme='dark'] .mb-n1,
  [data-theme='dark'] .my-n1 {
    margin-bottom: -0.25rem !important; }
  [data-theme='dark'] .ml-n1,
  [data-theme='dark'] .mx-n1 {
    margin-left: -0.25rem !important; }
  [data-theme='dark'] .m-n2 {
    margin: -0.5rem !important; }
  [data-theme='dark'] .mt-n2,
  [data-theme='dark'] .my-n2 {
    margin-top: -0.5rem !important; }
  [data-theme='dark'] .mr-n2,
  [data-theme='dark'] .mx-n2 {
    margin-right: -0.5rem !important; }
  [data-theme='dark'] .mb-n2,
  [data-theme='dark'] .my-n2 {
    margin-bottom: -0.5rem !important; }
  [data-theme='dark'] .ml-n2,
  [data-theme='dark'] .mx-n2 {
    margin-left: -0.5rem !important; }
  [data-theme='dark'] .m-n3 {
    margin: -1rem !important; }
  [data-theme='dark'] .mt-n3,
  [data-theme='dark'] .my-n3 {
    margin-top: -1rem !important; }
  [data-theme='dark'] .mr-n3,
  [data-theme='dark'] .mx-n3 {
    margin-right: -1rem !important; }
  [data-theme='dark'] .mb-n3,
  [data-theme='dark'] .my-n3 {
    margin-bottom: -1rem !important; }
  [data-theme='dark'] .ml-n3,
  [data-theme='dark'] .mx-n3 {
    margin-left: -1rem !important; }
  [data-theme='dark'] .m-n4 {
    margin: -1.5rem !important; }
  [data-theme='dark'] .mt-n4,
  [data-theme='dark'] .my-n4 {
    margin-top: -1.5rem !important; }
  [data-theme='dark'] .mr-n4,
  [data-theme='dark'] .mx-n4 {
    margin-right: -1.5rem !important; }
  [data-theme='dark'] .mb-n4,
  [data-theme='dark'] .my-n4 {
    margin-bottom: -1.5rem !important; }
  [data-theme='dark'] .ml-n4,
  [data-theme='dark'] .mx-n4 {
    margin-left: -1.5rem !important; }
  [data-theme='dark'] .m-n5 {
    margin: -3rem !important; }
  [data-theme='dark'] .mt-n5,
  [data-theme='dark'] .my-n5 {
    margin-top: -3rem !important; }
  [data-theme='dark'] .mr-n5,
  [data-theme='dark'] .mx-n5 {
    margin-right: -3rem !important; }
  [data-theme='dark'] .mb-n5,
  [data-theme='dark'] .my-n5 {
    margin-bottom: -3rem !important; }
  [data-theme='dark'] .ml-n5,
  [data-theme='dark'] .mx-n5 {
    margin-left: -3rem !important; }
  [data-theme='dark'] .m-auto {
    margin: auto !important; }
  [data-theme='dark'] .mt-auto,
  [data-theme='dark'] .my-auto {
    margin-top: auto !important; }
  [data-theme='dark'] .mr-auto,
  [data-theme='dark'] .mx-auto {
    margin-right: auto !important; }
  [data-theme='dark'] .mb-auto,
  [data-theme='dark'] .my-auto {
    margin-bottom: auto !important; }
  [data-theme='dark'] .ml-auto,
  [data-theme='dark'] .mx-auto {
    margin-left: auto !important; }
  @media (min-width: 576px) {
    [data-theme='dark'] .m-sm-0 {
      margin: 0 !important; }
    [data-theme='dark'] .mt-sm-0,
    [data-theme='dark'] .my-sm-0 {
      margin-top: 0 !important; }
    [data-theme='dark'] .mr-sm-0,
    [data-theme='dark'] .mx-sm-0 {
      margin-right: 0 !important; }
    [data-theme='dark'] .mb-sm-0,
    [data-theme='dark'] .my-sm-0 {
      margin-bottom: 0 !important; }
    [data-theme='dark'] .ml-sm-0,
    [data-theme='dark'] .mx-sm-0 {
      margin-left: 0 !important; }
    [data-theme='dark'] .m-sm-1 {
      margin: 0.25rem !important; }
    [data-theme='dark'] .mt-sm-1,
    [data-theme='dark'] .my-sm-1 {
      margin-top: 0.25rem !important; }
    [data-theme='dark'] .mr-sm-1,
    [data-theme='dark'] .mx-sm-1 {
      margin-right: 0.25rem !important; }
    [data-theme='dark'] .mb-sm-1,
    [data-theme='dark'] .my-sm-1 {
      margin-bottom: 0.25rem !important; }
    [data-theme='dark'] .ml-sm-1,
    [data-theme='dark'] .mx-sm-1 {
      margin-left: 0.25rem !important; }
    [data-theme='dark'] .m-sm-2 {
      margin: 0.5rem !important; }
    [data-theme='dark'] .mt-sm-2,
    [data-theme='dark'] .my-sm-2 {
      margin-top: 0.5rem !important; }
    [data-theme='dark'] .mr-sm-2,
    [data-theme='dark'] .mx-sm-2 {
      margin-right: 0.5rem !important; }
    [data-theme='dark'] .mb-sm-2,
    [data-theme='dark'] .my-sm-2 {
      margin-bottom: 0.5rem !important; }
    [data-theme='dark'] .ml-sm-2,
    [data-theme='dark'] .mx-sm-2 {
      margin-left: 0.5rem !important; }
    [data-theme='dark'] .m-sm-3 {
      margin: 1rem !important; }
    [data-theme='dark'] .mt-sm-3,
    [data-theme='dark'] .my-sm-3 {
      margin-top: 1rem !important; }
    [data-theme='dark'] .mr-sm-3,
    [data-theme='dark'] .mx-sm-3 {
      margin-right: 1rem !important; }
    [data-theme='dark'] .mb-sm-3,
    [data-theme='dark'] .my-sm-3 {
      margin-bottom: 1rem !important; }
    [data-theme='dark'] .ml-sm-3,
    [data-theme='dark'] .mx-sm-3 {
      margin-left: 1rem !important; }
    [data-theme='dark'] .m-sm-4 {
      margin: 1.5rem !important; }
    [data-theme='dark'] .mt-sm-4,
    [data-theme='dark'] .my-sm-4 {
      margin-top: 1.5rem !important; }
    [data-theme='dark'] .mr-sm-4,
    [data-theme='dark'] .mx-sm-4 {
      margin-right: 1.5rem !important; }
    [data-theme='dark'] .mb-sm-4,
    [data-theme='dark'] .my-sm-4 {
      margin-bottom: 1.5rem !important; }
    [data-theme='dark'] .ml-sm-4,
    [data-theme='dark'] .mx-sm-4 {
      margin-left: 1.5rem !important; }
    [data-theme='dark'] .m-sm-5 {
      margin: 3rem !important; }
    [data-theme='dark'] .mt-sm-5,
    [data-theme='dark'] .my-sm-5 {
      margin-top: 3rem !important; }
    [data-theme='dark'] .mr-sm-5,
    [data-theme='dark'] .mx-sm-5 {
      margin-right: 3rem !important; }
    [data-theme='dark'] .mb-sm-5,
    [data-theme='dark'] .my-sm-5 {
      margin-bottom: 3rem !important; }
    [data-theme='dark'] .ml-sm-5,
    [data-theme='dark'] .mx-sm-5 {
      margin-left: 3rem !important; }
    [data-theme='dark'] .p-sm-0 {
      padding: 0 !important; }
    [data-theme='dark'] .pt-sm-0,
    [data-theme='dark'] .py-sm-0 {
      padding-top: 0 !important; }
    [data-theme='dark'] .pr-sm-0,
    [data-theme='dark'] .px-sm-0 {
      padding-right: 0 !important; }
    [data-theme='dark'] .pb-sm-0,
    [data-theme='dark'] .py-sm-0 {
      padding-bottom: 0 !important; }
    [data-theme='dark'] .pl-sm-0,
    [data-theme='dark'] .px-sm-0 {
      padding-left: 0 !important; }
    [data-theme='dark'] .p-sm-1 {
      padding: 0.25rem !important; }
    [data-theme='dark'] .pt-sm-1,
    [data-theme='dark'] .py-sm-1 {
      padding-top: 0.25rem !important; }
    [data-theme='dark'] .pr-sm-1,
    [data-theme='dark'] .px-sm-1 {
      padding-right: 0.25rem !important; }
    [data-theme='dark'] .pb-sm-1,
    [data-theme='dark'] .py-sm-1 {
      padding-bottom: 0.25rem !important; }
    [data-theme='dark'] .pl-sm-1,
    [data-theme='dark'] .px-sm-1 {
      padding-left: 0.25rem !important; }
    [data-theme='dark'] .p-sm-2 {
      padding: 0.5rem !important; }
    [data-theme='dark'] .pt-sm-2,
    [data-theme='dark'] .py-sm-2 {
      padding-top: 0.5rem !important; }
    [data-theme='dark'] .pr-sm-2,
    [data-theme='dark'] .px-sm-2 {
      padding-right: 0.5rem !important; }
    [data-theme='dark'] .pb-sm-2,
    [data-theme='dark'] .py-sm-2 {
      padding-bottom: 0.5rem !important; }
    [data-theme='dark'] .pl-sm-2,
    [data-theme='dark'] .px-sm-2 {
      padding-left: 0.5rem !important; }
    [data-theme='dark'] .p-sm-3 {
      padding: 1rem !important; }
    [data-theme='dark'] .pt-sm-3,
    [data-theme='dark'] .py-sm-3 {
      padding-top: 1rem !important; }
    [data-theme='dark'] .pr-sm-3,
    [data-theme='dark'] .px-sm-3 {
      padding-right: 1rem !important; }
    [data-theme='dark'] .pb-sm-3,
    [data-theme='dark'] .py-sm-3 {
      padding-bottom: 1rem !important; }
    [data-theme='dark'] .pl-sm-3,
    [data-theme='dark'] .px-sm-3 {
      padding-left: 1rem !important; }
    [data-theme='dark'] .p-sm-4 {
      padding: 1.5rem !important; }
    [data-theme='dark'] .pt-sm-4,
    [data-theme='dark'] .py-sm-4 {
      padding-top: 1.5rem !important; }
    [data-theme='dark'] .pr-sm-4,
    [data-theme='dark'] .px-sm-4 {
      padding-right: 1.5rem !important; }
    [data-theme='dark'] .pb-sm-4,
    [data-theme='dark'] .py-sm-4 {
      padding-bottom: 1.5rem !important; }
    [data-theme='dark'] .pl-sm-4,
    [data-theme='dark'] .px-sm-4 {
      padding-left: 1.5rem !important; }
    [data-theme='dark'] .p-sm-5 {
      padding: 3rem !important; }
    [data-theme='dark'] .pt-sm-5,
    [data-theme='dark'] .py-sm-5 {
      padding-top: 3rem !important; }
    [data-theme='dark'] .pr-sm-5,
    [data-theme='dark'] .px-sm-5 {
      padding-right: 3rem !important; }
    [data-theme='dark'] .pb-sm-5,
    [data-theme='dark'] .py-sm-5 {
      padding-bottom: 3rem !important; }
    [data-theme='dark'] .pl-sm-5,
    [data-theme='dark'] .px-sm-5 {
      padding-left: 3rem !important; }
    [data-theme='dark'] .m-sm-n1 {
      margin: -0.25rem !important; }
    [data-theme='dark'] .mt-sm-n1,
    [data-theme='dark'] .my-sm-n1 {
      margin-top: -0.25rem !important; }
    [data-theme='dark'] .mr-sm-n1,
    [data-theme='dark'] .mx-sm-n1 {
      margin-right: -0.25rem !important; }
    [data-theme='dark'] .mb-sm-n1,
    [data-theme='dark'] .my-sm-n1 {
      margin-bottom: -0.25rem !important; }
    [data-theme='dark'] .ml-sm-n1,
    [data-theme='dark'] .mx-sm-n1 {
      margin-left: -0.25rem !important; }
    [data-theme='dark'] .m-sm-n2 {
      margin: -0.5rem !important; }
    [data-theme='dark'] .mt-sm-n2,
    [data-theme='dark'] .my-sm-n2 {
      margin-top: -0.5rem !important; }
    [data-theme='dark'] .mr-sm-n2,
    [data-theme='dark'] .mx-sm-n2 {
      margin-right: -0.5rem !important; }
    [data-theme='dark'] .mb-sm-n2,
    [data-theme='dark'] .my-sm-n2 {
      margin-bottom: -0.5rem !important; }
    [data-theme='dark'] .ml-sm-n2,
    [data-theme='dark'] .mx-sm-n2 {
      margin-left: -0.5rem !important; }
    [data-theme='dark'] .m-sm-n3 {
      margin: -1rem !important; }
    [data-theme='dark'] .mt-sm-n3,
    [data-theme='dark'] .my-sm-n3 {
      margin-top: -1rem !important; }
    [data-theme='dark'] .mr-sm-n3,
    [data-theme='dark'] .mx-sm-n3 {
      margin-right: -1rem !important; }
    [data-theme='dark'] .mb-sm-n3,
    [data-theme='dark'] .my-sm-n3 {
      margin-bottom: -1rem !important; }
    [data-theme='dark'] .ml-sm-n3,
    [data-theme='dark'] .mx-sm-n3 {
      margin-left: -1rem !important; }
    [data-theme='dark'] .m-sm-n4 {
      margin: -1.5rem !important; }
    [data-theme='dark'] .mt-sm-n4,
    [data-theme='dark'] .my-sm-n4 {
      margin-top: -1.5rem !important; }
    [data-theme='dark'] .mr-sm-n4,
    [data-theme='dark'] .mx-sm-n4 {
      margin-right: -1.5rem !important; }
    [data-theme='dark'] .mb-sm-n4,
    [data-theme='dark'] .my-sm-n4 {
      margin-bottom: -1.5rem !important; }
    [data-theme='dark'] .ml-sm-n4,
    [data-theme='dark'] .mx-sm-n4 {
      margin-left: -1.5rem !important; }
    [data-theme='dark'] .m-sm-n5 {
      margin: -3rem !important; }
    [data-theme='dark'] .mt-sm-n5,
    [data-theme='dark'] .my-sm-n5 {
      margin-top: -3rem !important; }
    [data-theme='dark'] .mr-sm-n5,
    [data-theme='dark'] .mx-sm-n5 {
      margin-right: -3rem !important; }
    [data-theme='dark'] .mb-sm-n5,
    [data-theme='dark'] .my-sm-n5 {
      margin-bottom: -3rem !important; }
    [data-theme='dark'] .ml-sm-n5,
    [data-theme='dark'] .mx-sm-n5 {
      margin-left: -3rem !important; }
    [data-theme='dark'] .m-sm-auto {
      margin: auto !important; }
    [data-theme='dark'] .mt-sm-auto,
    [data-theme='dark'] .my-sm-auto {
      margin-top: auto !important; }
    [data-theme='dark'] .mr-sm-auto,
    [data-theme='dark'] .mx-sm-auto {
      margin-right: auto !important; }
    [data-theme='dark'] .mb-sm-auto,
    [data-theme='dark'] .my-sm-auto {
      margin-bottom: auto !important; }
    [data-theme='dark'] .ml-sm-auto,
    [data-theme='dark'] .mx-sm-auto {
      margin-left: auto !important; } }
  @media (min-width: 768px) {
    [data-theme='dark'] .m-md-0 {
      margin: 0 !important; }
    [data-theme='dark'] .mt-md-0,
    [data-theme='dark'] .my-md-0 {
      margin-top: 0 !important; }
    [data-theme='dark'] .mr-md-0,
    [data-theme='dark'] .mx-md-0 {
      margin-right: 0 !important; }
    [data-theme='dark'] .mb-md-0,
    [data-theme='dark'] .my-md-0 {
      margin-bottom: 0 !important; }
    [data-theme='dark'] .ml-md-0,
    [data-theme='dark'] .mx-md-0 {
      margin-left: 0 !important; }
    [data-theme='dark'] .m-md-1 {
      margin: 0.25rem !important; }
    [data-theme='dark'] .mt-md-1,
    [data-theme='dark'] .my-md-1 {
      margin-top: 0.25rem !important; }
    [data-theme='dark'] .mr-md-1,
    [data-theme='dark'] .mx-md-1 {
      margin-right: 0.25rem !important; }
    [data-theme='dark'] .mb-md-1,
    [data-theme='dark'] .my-md-1 {
      margin-bottom: 0.25rem !important; }
    [data-theme='dark'] .ml-md-1,
    [data-theme='dark'] .mx-md-1 {
      margin-left: 0.25rem !important; }
    [data-theme='dark'] .m-md-2 {
      margin: 0.5rem !important; }
    [data-theme='dark'] .mt-md-2,
    [data-theme='dark'] .my-md-2 {
      margin-top: 0.5rem !important; }
    [data-theme='dark'] .mr-md-2,
    [data-theme='dark'] .mx-md-2 {
      margin-right: 0.5rem !important; }
    [data-theme='dark'] .mb-md-2,
    [data-theme='dark'] .my-md-2 {
      margin-bottom: 0.5rem !important; }
    [data-theme='dark'] .ml-md-2,
    [data-theme='dark'] .mx-md-2 {
      margin-left: 0.5rem !important; }
    [data-theme='dark'] .m-md-3 {
      margin: 1rem !important; }
    [data-theme='dark'] .mt-md-3,
    [data-theme='dark'] .my-md-3 {
      margin-top: 1rem !important; }
    [data-theme='dark'] .mr-md-3,
    [data-theme='dark'] .mx-md-3 {
      margin-right: 1rem !important; }
    [data-theme='dark'] .mb-md-3,
    [data-theme='dark'] .my-md-3 {
      margin-bottom: 1rem !important; }
    [data-theme='dark'] .ml-md-3,
    [data-theme='dark'] .mx-md-3 {
      margin-left: 1rem !important; }
    [data-theme='dark'] .m-md-4 {
      margin: 1.5rem !important; }
    [data-theme='dark'] .mt-md-4,
    [data-theme='dark'] .my-md-4 {
      margin-top: 1.5rem !important; }
    [data-theme='dark'] .mr-md-4,
    [data-theme='dark'] .mx-md-4 {
      margin-right: 1.5rem !important; }
    [data-theme='dark'] .mb-md-4,
    [data-theme='dark'] .my-md-4 {
      margin-bottom: 1.5rem !important; }
    [data-theme='dark'] .ml-md-4,
    [data-theme='dark'] .mx-md-4 {
      margin-left: 1.5rem !important; }
    [data-theme='dark'] .m-md-5 {
      margin: 3rem !important; }
    [data-theme='dark'] .mt-md-5,
    [data-theme='dark'] .my-md-5 {
      margin-top: 3rem !important; }
    [data-theme='dark'] .mr-md-5,
    [data-theme='dark'] .mx-md-5 {
      margin-right: 3rem !important; }
    [data-theme='dark'] .mb-md-5,
    [data-theme='dark'] .my-md-5 {
      margin-bottom: 3rem !important; }
    [data-theme='dark'] .ml-md-5,
    [data-theme='dark'] .mx-md-5 {
      margin-left: 3rem !important; }
    [data-theme='dark'] .p-md-0 {
      padding: 0 !important; }
    [data-theme='dark'] .pt-md-0,
    [data-theme='dark'] .py-md-0 {
      padding-top: 0 !important; }
    [data-theme='dark'] .pr-md-0,
    [data-theme='dark'] .px-md-0 {
      padding-right: 0 !important; }
    [data-theme='dark'] .pb-md-0,
    [data-theme='dark'] .py-md-0 {
      padding-bottom: 0 !important; }
    [data-theme='dark'] .pl-md-0,
    [data-theme='dark'] .px-md-0 {
      padding-left: 0 !important; }
    [data-theme='dark'] .p-md-1 {
      padding: 0.25rem !important; }
    [data-theme='dark'] .pt-md-1,
    [data-theme='dark'] .py-md-1 {
      padding-top: 0.25rem !important; }
    [data-theme='dark'] .pr-md-1,
    [data-theme='dark'] .px-md-1 {
      padding-right: 0.25rem !important; }
    [data-theme='dark'] .pb-md-1,
    [data-theme='dark'] .py-md-1 {
      padding-bottom: 0.25rem !important; }
    [data-theme='dark'] .pl-md-1,
    [data-theme='dark'] .px-md-1 {
      padding-left: 0.25rem !important; }
    [data-theme='dark'] .p-md-2 {
      padding: 0.5rem !important; }
    [data-theme='dark'] .pt-md-2,
    [data-theme='dark'] .py-md-2 {
      padding-top: 0.5rem !important; }
    [data-theme='dark'] .pr-md-2,
    [data-theme='dark'] .px-md-2 {
      padding-right: 0.5rem !important; }
    [data-theme='dark'] .pb-md-2,
    [data-theme='dark'] .py-md-2 {
      padding-bottom: 0.5rem !important; }
    [data-theme='dark'] .pl-md-2,
    [data-theme='dark'] .px-md-2 {
      padding-left: 0.5rem !important; }
    [data-theme='dark'] .p-md-3 {
      padding: 1rem !important; }
    [data-theme='dark'] .pt-md-3,
    [data-theme='dark'] .py-md-3 {
      padding-top: 1rem !important; }
    [data-theme='dark'] .pr-md-3,
    [data-theme='dark'] .px-md-3 {
      padding-right: 1rem !important; }
    [data-theme='dark'] .pb-md-3,
    [data-theme='dark'] .py-md-3 {
      padding-bottom: 1rem !important; }
    [data-theme='dark'] .pl-md-3,
    [data-theme='dark'] .px-md-3 {
      padding-left: 1rem !important; }
    [data-theme='dark'] .p-md-4 {
      padding: 1.5rem !important; }
    [data-theme='dark'] .pt-md-4,
    [data-theme='dark'] .py-md-4 {
      padding-top: 1.5rem !important; }
    [data-theme='dark'] .pr-md-4,
    [data-theme='dark'] .px-md-4 {
      padding-right: 1.5rem !important; }
    [data-theme='dark'] .pb-md-4,
    [data-theme='dark'] .py-md-4 {
      padding-bottom: 1.5rem !important; }
    [data-theme='dark'] .pl-md-4,
    [data-theme='dark'] .px-md-4 {
      padding-left: 1.5rem !important; }
    [data-theme='dark'] .p-md-5 {
      padding: 3rem !important; }
    [data-theme='dark'] .pt-md-5,
    [data-theme='dark'] .py-md-5 {
      padding-top: 3rem !important; }
    [data-theme='dark'] .pr-md-5,
    [data-theme='dark'] .px-md-5 {
      padding-right: 3rem !important; }
    [data-theme='dark'] .pb-md-5,
    [data-theme='dark'] .py-md-5 {
      padding-bottom: 3rem !important; }
    [data-theme='dark'] .pl-md-5,
    [data-theme='dark'] .px-md-5 {
      padding-left: 3rem !important; }
    [data-theme='dark'] .m-md-n1 {
      margin: -0.25rem !important; }
    [data-theme='dark'] .mt-md-n1,
    [data-theme='dark'] .my-md-n1 {
      margin-top: -0.25rem !important; }
    [data-theme='dark'] .mr-md-n1,
    [data-theme='dark'] .mx-md-n1 {
      margin-right: -0.25rem !important; }
    [data-theme='dark'] .mb-md-n1,
    [data-theme='dark'] .my-md-n1 {
      margin-bottom: -0.25rem !important; }
    [data-theme='dark'] .ml-md-n1,
    [data-theme='dark'] .mx-md-n1 {
      margin-left: -0.25rem !important; }
    [data-theme='dark'] .m-md-n2 {
      margin: -0.5rem !important; }
    [data-theme='dark'] .mt-md-n2,
    [data-theme='dark'] .my-md-n2 {
      margin-top: -0.5rem !important; }
    [data-theme='dark'] .mr-md-n2,
    [data-theme='dark'] .mx-md-n2 {
      margin-right: -0.5rem !important; }
    [data-theme='dark'] .mb-md-n2,
    [data-theme='dark'] .my-md-n2 {
      margin-bottom: -0.5rem !important; }
    [data-theme='dark'] .ml-md-n2,
    [data-theme='dark'] .mx-md-n2 {
      margin-left: -0.5rem !important; }
    [data-theme='dark'] .m-md-n3 {
      margin: -1rem !important; }
    [data-theme='dark'] .mt-md-n3,
    [data-theme='dark'] .my-md-n3 {
      margin-top: -1rem !important; }
    [data-theme='dark'] .mr-md-n3,
    [data-theme='dark'] .mx-md-n3 {
      margin-right: -1rem !important; }
    [data-theme='dark'] .mb-md-n3,
    [data-theme='dark'] .my-md-n3 {
      margin-bottom: -1rem !important; }
    [data-theme='dark'] .ml-md-n3,
    [data-theme='dark'] .mx-md-n3 {
      margin-left: -1rem !important; }
    [data-theme='dark'] .m-md-n4 {
      margin: -1.5rem !important; }
    [data-theme='dark'] .mt-md-n4,
    [data-theme='dark'] .my-md-n4 {
      margin-top: -1.5rem !important; }
    [data-theme='dark'] .mr-md-n4,
    [data-theme='dark'] .mx-md-n4 {
      margin-right: -1.5rem !important; }
    [data-theme='dark'] .mb-md-n4,
    [data-theme='dark'] .my-md-n4 {
      margin-bottom: -1.5rem !important; }
    [data-theme='dark'] .ml-md-n4,
    [data-theme='dark'] .mx-md-n4 {
      margin-left: -1.5rem !important; }
    [data-theme='dark'] .m-md-n5 {
      margin: -3rem !important; }
    [data-theme='dark'] .mt-md-n5,
    [data-theme='dark'] .my-md-n5 {
      margin-top: -3rem !important; }
    [data-theme='dark'] .mr-md-n5,
    [data-theme='dark'] .mx-md-n5 {
      margin-right: -3rem !important; }
    [data-theme='dark'] .mb-md-n5,
    [data-theme='dark'] .my-md-n5 {
      margin-bottom: -3rem !important; }
    [data-theme='dark'] .ml-md-n5,
    [data-theme='dark'] .mx-md-n5 {
      margin-left: -3rem !important; }
    [data-theme='dark'] .m-md-auto {
      margin: auto !important; }
    [data-theme='dark'] .mt-md-auto,
    [data-theme='dark'] .my-md-auto {
      margin-top: auto !important; }
    [data-theme='dark'] .mr-md-auto,
    [data-theme='dark'] .mx-md-auto {
      margin-right: auto !important; }
    [data-theme='dark'] .mb-md-auto,
    [data-theme='dark'] .my-md-auto {
      margin-bottom: auto !important; }
    [data-theme='dark'] .ml-md-auto,
    [data-theme='dark'] .mx-md-auto {
      margin-left: auto !important; } }
  @media (min-width: 992px) {
    [data-theme='dark'] .m-lg-0 {
      margin: 0 !important; }
    [data-theme='dark'] .mt-lg-0,
    [data-theme='dark'] .my-lg-0 {
      margin-top: 0 !important; }
    [data-theme='dark'] .mr-lg-0,
    [data-theme='dark'] .mx-lg-0 {
      margin-right: 0 !important; }
    [data-theme='dark'] .mb-lg-0,
    [data-theme='dark'] .my-lg-0 {
      margin-bottom: 0 !important; }
    [data-theme='dark'] .ml-lg-0,
    [data-theme='dark'] .mx-lg-0 {
      margin-left: 0 !important; }
    [data-theme='dark'] .m-lg-1 {
      margin: 0.25rem !important; }
    [data-theme='dark'] .mt-lg-1,
    [data-theme='dark'] .my-lg-1 {
      margin-top: 0.25rem !important; }
    [data-theme='dark'] .mr-lg-1,
    [data-theme='dark'] .mx-lg-1 {
      margin-right: 0.25rem !important; }
    [data-theme='dark'] .mb-lg-1,
    [data-theme='dark'] .my-lg-1 {
      margin-bottom: 0.25rem !important; }
    [data-theme='dark'] .ml-lg-1,
    [data-theme='dark'] .mx-lg-1 {
      margin-left: 0.25rem !important; }
    [data-theme='dark'] .m-lg-2 {
      margin: 0.5rem !important; }
    [data-theme='dark'] .mt-lg-2,
    [data-theme='dark'] .my-lg-2 {
      margin-top: 0.5rem !important; }
    [data-theme='dark'] .mr-lg-2,
    [data-theme='dark'] .mx-lg-2 {
      margin-right: 0.5rem !important; }
    [data-theme='dark'] .mb-lg-2,
    [data-theme='dark'] .my-lg-2 {
      margin-bottom: 0.5rem !important; }
    [data-theme='dark'] .ml-lg-2,
    [data-theme='dark'] .mx-lg-2 {
      margin-left: 0.5rem !important; }
    [data-theme='dark'] .m-lg-3 {
      margin: 1rem !important; }
    [data-theme='dark'] .mt-lg-3,
    [data-theme='dark'] .my-lg-3 {
      margin-top: 1rem !important; }
    [data-theme='dark'] .mr-lg-3,
    [data-theme='dark'] .mx-lg-3 {
      margin-right: 1rem !important; }
    [data-theme='dark'] .mb-lg-3,
    [data-theme='dark'] .my-lg-3 {
      margin-bottom: 1rem !important; }
    [data-theme='dark'] .ml-lg-3,
    [data-theme='dark'] .mx-lg-3 {
      margin-left: 1rem !important; }
    [data-theme='dark'] .m-lg-4 {
      margin: 1.5rem !important; }
    [data-theme='dark'] .mt-lg-4,
    [data-theme='dark'] .my-lg-4 {
      margin-top: 1.5rem !important; }
    [data-theme='dark'] .mr-lg-4,
    [data-theme='dark'] .mx-lg-4 {
      margin-right: 1.5rem !important; }
    [data-theme='dark'] .mb-lg-4,
    [data-theme='dark'] .my-lg-4 {
      margin-bottom: 1.5rem !important; }
    [data-theme='dark'] .ml-lg-4,
    [data-theme='dark'] .mx-lg-4 {
      margin-left: 1.5rem !important; }
    [data-theme='dark'] .m-lg-5 {
      margin: 3rem !important; }
    [data-theme='dark'] .mt-lg-5,
    [data-theme='dark'] .my-lg-5 {
      margin-top: 3rem !important; }
    [data-theme='dark'] .mr-lg-5,
    [data-theme='dark'] .mx-lg-5 {
      margin-right: 3rem !important; }
    [data-theme='dark'] .mb-lg-5,
    [data-theme='dark'] .my-lg-5 {
      margin-bottom: 3rem !important; }
    [data-theme='dark'] .ml-lg-5,
    [data-theme='dark'] .mx-lg-5 {
      margin-left: 3rem !important; }
    [data-theme='dark'] .p-lg-0 {
      padding: 0 !important; }
    [data-theme='dark'] .pt-lg-0,
    [data-theme='dark'] .py-lg-0 {
      padding-top: 0 !important; }
    [data-theme='dark'] .pr-lg-0,
    [data-theme='dark'] .px-lg-0 {
      padding-right: 0 !important; }
    [data-theme='dark'] .pb-lg-0,
    [data-theme='dark'] .py-lg-0 {
      padding-bottom: 0 !important; }
    [data-theme='dark'] .pl-lg-0,
    [data-theme='dark'] .px-lg-0 {
      padding-left: 0 !important; }
    [data-theme='dark'] .p-lg-1 {
      padding: 0.25rem !important; }
    [data-theme='dark'] .pt-lg-1,
    [data-theme='dark'] .py-lg-1 {
      padding-top: 0.25rem !important; }
    [data-theme='dark'] .pr-lg-1,
    [data-theme='dark'] .px-lg-1 {
      padding-right: 0.25rem !important; }
    [data-theme='dark'] .pb-lg-1,
    [data-theme='dark'] .py-lg-1 {
      padding-bottom: 0.25rem !important; }
    [data-theme='dark'] .pl-lg-1,
    [data-theme='dark'] .px-lg-1 {
      padding-left: 0.25rem !important; }
    [data-theme='dark'] .p-lg-2 {
      padding: 0.5rem !important; }
    [data-theme='dark'] .pt-lg-2,
    [data-theme='dark'] .py-lg-2 {
      padding-top: 0.5rem !important; }
    [data-theme='dark'] .pr-lg-2,
    [data-theme='dark'] .px-lg-2 {
      padding-right: 0.5rem !important; }
    [data-theme='dark'] .pb-lg-2,
    [data-theme='dark'] .py-lg-2 {
      padding-bottom: 0.5rem !important; }
    [data-theme='dark'] .pl-lg-2,
    [data-theme='dark'] .px-lg-2 {
      padding-left: 0.5rem !important; }
    [data-theme='dark'] .p-lg-3 {
      padding: 1rem !important; }
    [data-theme='dark'] .pt-lg-3,
    [data-theme='dark'] .py-lg-3 {
      padding-top: 1rem !important; }
    [data-theme='dark'] .pr-lg-3,
    [data-theme='dark'] .px-lg-3 {
      padding-right: 1rem !important; }
    [data-theme='dark'] .pb-lg-3,
    [data-theme='dark'] .py-lg-3 {
      padding-bottom: 1rem !important; }
    [data-theme='dark'] .pl-lg-3,
    [data-theme='dark'] .px-lg-3 {
      padding-left: 1rem !important; }
    [data-theme='dark'] .p-lg-4 {
      padding: 1.5rem !important; }
    [data-theme='dark'] .pt-lg-4,
    [data-theme='dark'] .py-lg-4 {
      padding-top: 1.5rem !important; }
    [data-theme='dark'] .pr-lg-4,
    [data-theme='dark'] .px-lg-4 {
      padding-right: 1.5rem !important; }
    [data-theme='dark'] .pb-lg-4,
    [data-theme='dark'] .py-lg-4 {
      padding-bottom: 1.5rem !important; }
    [data-theme='dark'] .pl-lg-4,
    [data-theme='dark'] .px-lg-4 {
      padding-left: 1.5rem !important; }
    [data-theme='dark'] .p-lg-5 {
      padding: 3rem !important; }
    [data-theme='dark'] .pt-lg-5,
    [data-theme='dark'] .py-lg-5 {
      padding-top: 3rem !important; }
    [data-theme='dark'] .pr-lg-5,
    [data-theme='dark'] .px-lg-5 {
      padding-right: 3rem !important; }
    [data-theme='dark'] .pb-lg-5,
    [data-theme='dark'] .py-lg-5 {
      padding-bottom: 3rem !important; }
    [data-theme='dark'] .pl-lg-5,
    [data-theme='dark'] .px-lg-5 {
      padding-left: 3rem !important; }
    [data-theme='dark'] .m-lg-n1 {
      margin: -0.25rem !important; }
    [data-theme='dark'] .mt-lg-n1,
    [data-theme='dark'] .my-lg-n1 {
      margin-top: -0.25rem !important; }
    [data-theme='dark'] .mr-lg-n1,
    [data-theme='dark'] .mx-lg-n1 {
      margin-right: -0.25rem !important; }
    [data-theme='dark'] .mb-lg-n1,
    [data-theme='dark'] .my-lg-n1 {
      margin-bottom: -0.25rem !important; }
    [data-theme='dark'] .ml-lg-n1,
    [data-theme='dark'] .mx-lg-n1 {
      margin-left: -0.25rem !important; }
    [data-theme='dark'] .m-lg-n2 {
      margin: -0.5rem !important; }
    [data-theme='dark'] .mt-lg-n2,
    [data-theme='dark'] .my-lg-n2 {
      margin-top: -0.5rem !important; }
    [data-theme='dark'] .mr-lg-n2,
    [data-theme='dark'] .mx-lg-n2 {
      margin-right: -0.5rem !important; }
    [data-theme='dark'] .mb-lg-n2,
    [data-theme='dark'] .my-lg-n2 {
      margin-bottom: -0.5rem !important; }
    [data-theme='dark'] .ml-lg-n2,
    [data-theme='dark'] .mx-lg-n2 {
      margin-left: -0.5rem !important; }
    [data-theme='dark'] .m-lg-n3 {
      margin: -1rem !important; }
    [data-theme='dark'] .mt-lg-n3,
    [data-theme='dark'] .my-lg-n3 {
      margin-top: -1rem !important; }
    [data-theme='dark'] .mr-lg-n3,
    [data-theme='dark'] .mx-lg-n3 {
      margin-right: -1rem !important; }
    [data-theme='dark'] .mb-lg-n3,
    [data-theme='dark'] .my-lg-n3 {
      margin-bottom: -1rem !important; }
    [data-theme='dark'] .ml-lg-n3,
    [data-theme='dark'] .mx-lg-n3 {
      margin-left: -1rem !important; }
    [data-theme='dark'] .m-lg-n4 {
      margin: -1.5rem !important; }
    [data-theme='dark'] .mt-lg-n4,
    [data-theme='dark'] .my-lg-n4 {
      margin-top: -1.5rem !important; }
    [data-theme='dark'] .mr-lg-n4,
    [data-theme='dark'] .mx-lg-n4 {
      margin-right: -1.5rem !important; }
    [data-theme='dark'] .mb-lg-n4,
    [data-theme='dark'] .my-lg-n4 {
      margin-bottom: -1.5rem !important; }
    [data-theme='dark'] .ml-lg-n4,
    [data-theme='dark'] .mx-lg-n4 {
      margin-left: -1.5rem !important; }
    [data-theme='dark'] .m-lg-n5 {
      margin: -3rem !important; }
    [data-theme='dark'] .mt-lg-n5,
    [data-theme='dark'] .my-lg-n5 {
      margin-top: -3rem !important; }
    [data-theme='dark'] .mr-lg-n5,
    [data-theme='dark'] .mx-lg-n5 {
      margin-right: -3rem !important; }
    [data-theme='dark'] .mb-lg-n5,
    [data-theme='dark'] .my-lg-n5 {
      margin-bottom: -3rem !important; }
    [data-theme='dark'] .ml-lg-n5,
    [data-theme='dark'] .mx-lg-n5 {
      margin-left: -3rem !important; }
    [data-theme='dark'] .m-lg-auto {
      margin: auto !important; }
    [data-theme='dark'] .mt-lg-auto,
    [data-theme='dark'] .my-lg-auto {
      margin-top: auto !important; }
    [data-theme='dark'] .mr-lg-auto,
    [data-theme='dark'] .mx-lg-auto {
      margin-right: auto !important; }
    [data-theme='dark'] .mb-lg-auto,
    [data-theme='dark'] .my-lg-auto {
      margin-bottom: auto !important; }
    [data-theme='dark'] .ml-lg-auto,
    [data-theme='dark'] .mx-lg-auto {
      margin-left: auto !important; } }
  @media (min-width: 1200px) {
    [data-theme='dark'] .m-xl-0 {
      margin: 0 !important; }
    [data-theme='dark'] .mt-xl-0,
    [data-theme='dark'] .my-xl-0 {
      margin-top: 0 !important; }
    [data-theme='dark'] .mr-xl-0,
    [data-theme='dark'] .mx-xl-0 {
      margin-right: 0 !important; }
    [data-theme='dark'] .mb-xl-0,
    [data-theme='dark'] .my-xl-0 {
      margin-bottom: 0 !important; }
    [data-theme='dark'] .ml-xl-0,
    [data-theme='dark'] .mx-xl-0 {
      margin-left: 0 !important; }
    [data-theme='dark'] .m-xl-1 {
      margin: 0.25rem !important; }
    [data-theme='dark'] .mt-xl-1,
    [data-theme='dark'] .my-xl-1 {
      margin-top: 0.25rem !important; }
    [data-theme='dark'] .mr-xl-1,
    [data-theme='dark'] .mx-xl-1 {
      margin-right: 0.25rem !important; }
    [data-theme='dark'] .mb-xl-1,
    [data-theme='dark'] .my-xl-1 {
      margin-bottom: 0.25rem !important; }
    [data-theme='dark'] .ml-xl-1,
    [data-theme='dark'] .mx-xl-1 {
      margin-left: 0.25rem !important; }
    [data-theme='dark'] .m-xl-2 {
      margin: 0.5rem !important; }
    [data-theme='dark'] .mt-xl-2,
    [data-theme='dark'] .my-xl-2 {
      margin-top: 0.5rem !important; }
    [data-theme='dark'] .mr-xl-2,
    [data-theme='dark'] .mx-xl-2 {
      margin-right: 0.5rem !important; }
    [data-theme='dark'] .mb-xl-2,
    [data-theme='dark'] .my-xl-2 {
      margin-bottom: 0.5rem !important; }
    [data-theme='dark'] .ml-xl-2,
    [data-theme='dark'] .mx-xl-2 {
      margin-left: 0.5rem !important; }
    [data-theme='dark'] .m-xl-3 {
      margin: 1rem !important; }
    [data-theme='dark'] .mt-xl-3,
    [data-theme='dark'] .my-xl-3 {
      margin-top: 1rem !important; }
    [data-theme='dark'] .mr-xl-3,
    [data-theme='dark'] .mx-xl-3 {
      margin-right: 1rem !important; }
    [data-theme='dark'] .mb-xl-3,
    [data-theme='dark'] .my-xl-3 {
      margin-bottom: 1rem !important; }
    [data-theme='dark'] .ml-xl-3,
    [data-theme='dark'] .mx-xl-3 {
      margin-left: 1rem !important; }
    [data-theme='dark'] .m-xl-4 {
      margin: 1.5rem !important; }
    [data-theme='dark'] .mt-xl-4,
    [data-theme='dark'] .my-xl-4 {
      margin-top: 1.5rem !important; }
    [data-theme='dark'] .mr-xl-4,
    [data-theme='dark'] .mx-xl-4 {
      margin-right: 1.5rem !important; }
    [data-theme='dark'] .mb-xl-4,
    [data-theme='dark'] .my-xl-4 {
      margin-bottom: 1.5rem !important; }
    [data-theme='dark'] .ml-xl-4,
    [data-theme='dark'] .mx-xl-4 {
      margin-left: 1.5rem !important; }
    [data-theme='dark'] .m-xl-5 {
      margin: 3rem !important; }
    [data-theme='dark'] .mt-xl-5,
    [data-theme='dark'] .my-xl-5 {
      margin-top: 3rem !important; }
    [data-theme='dark'] .mr-xl-5,
    [data-theme='dark'] .mx-xl-5 {
      margin-right: 3rem !important; }
    [data-theme='dark'] .mb-xl-5,
    [data-theme='dark'] .my-xl-5 {
      margin-bottom: 3rem !important; }
    [data-theme='dark'] .ml-xl-5,
    [data-theme='dark'] .mx-xl-5 {
      margin-left: 3rem !important; }
    [data-theme='dark'] .p-xl-0 {
      padding: 0 !important; }
    [data-theme='dark'] .pt-xl-0,
    [data-theme='dark'] .py-xl-0 {
      padding-top: 0 !important; }
    [data-theme='dark'] .pr-xl-0,
    [data-theme='dark'] .px-xl-0 {
      padding-right: 0 !important; }
    [data-theme='dark'] .pb-xl-0,
    [data-theme='dark'] .py-xl-0 {
      padding-bottom: 0 !important; }
    [data-theme='dark'] .pl-xl-0,
    [data-theme='dark'] .px-xl-0 {
      padding-left: 0 !important; }
    [data-theme='dark'] .p-xl-1 {
      padding: 0.25rem !important; }
    [data-theme='dark'] .pt-xl-1,
    [data-theme='dark'] .py-xl-1 {
      padding-top: 0.25rem !important; }
    [data-theme='dark'] .pr-xl-1,
    [data-theme='dark'] .px-xl-1 {
      padding-right: 0.25rem !important; }
    [data-theme='dark'] .pb-xl-1,
    [data-theme='dark'] .py-xl-1 {
      padding-bottom: 0.25rem !important; }
    [data-theme='dark'] .pl-xl-1,
    [data-theme='dark'] .px-xl-1 {
      padding-left: 0.25rem !important; }
    [data-theme='dark'] .p-xl-2 {
      padding: 0.5rem !important; }
    [data-theme='dark'] .pt-xl-2,
    [data-theme='dark'] .py-xl-2 {
      padding-top: 0.5rem !important; }
    [data-theme='dark'] .pr-xl-2,
    [data-theme='dark'] .px-xl-2 {
      padding-right: 0.5rem !important; }
    [data-theme='dark'] .pb-xl-2,
    [data-theme='dark'] .py-xl-2 {
      padding-bottom: 0.5rem !important; }
    [data-theme='dark'] .pl-xl-2,
    [data-theme='dark'] .px-xl-2 {
      padding-left: 0.5rem !important; }
    [data-theme='dark'] .p-xl-3 {
      padding: 1rem !important; }
    [data-theme='dark'] .pt-xl-3,
    [data-theme='dark'] .py-xl-3 {
      padding-top: 1rem !important; }
    [data-theme='dark'] .pr-xl-3,
    [data-theme='dark'] .px-xl-3 {
      padding-right: 1rem !important; }
    [data-theme='dark'] .pb-xl-3,
    [data-theme='dark'] .py-xl-3 {
      padding-bottom: 1rem !important; }
    [data-theme='dark'] .pl-xl-3,
    [data-theme='dark'] .px-xl-3 {
      padding-left: 1rem !important; }
    [data-theme='dark'] .p-xl-4 {
      padding: 1.5rem !important; }
    [data-theme='dark'] .pt-xl-4,
    [data-theme='dark'] .py-xl-4 {
      padding-top: 1.5rem !important; }
    [data-theme='dark'] .pr-xl-4,
    [data-theme='dark'] .px-xl-4 {
      padding-right: 1.5rem !important; }
    [data-theme='dark'] .pb-xl-4,
    [data-theme='dark'] .py-xl-4 {
      padding-bottom: 1.5rem !important; }
    [data-theme='dark'] .pl-xl-4,
    [data-theme='dark'] .px-xl-4 {
      padding-left: 1.5rem !important; }
    [data-theme='dark'] .p-xl-5 {
      padding: 3rem !important; }
    [data-theme='dark'] .pt-xl-5,
    [data-theme='dark'] .py-xl-5 {
      padding-top: 3rem !important; }
    [data-theme='dark'] .pr-xl-5,
    [data-theme='dark'] .px-xl-5 {
      padding-right: 3rem !important; }
    [data-theme='dark'] .pb-xl-5,
    [data-theme='dark'] .py-xl-5 {
      padding-bottom: 3rem !important; }
    [data-theme='dark'] .pl-xl-5,
    [data-theme='dark'] .px-xl-5 {
      padding-left: 3rem !important; }
    [data-theme='dark'] .m-xl-n1 {
      margin: -0.25rem !important; }
    [data-theme='dark'] .mt-xl-n1,
    [data-theme='dark'] .my-xl-n1 {
      margin-top: -0.25rem !important; }
    [data-theme='dark'] .mr-xl-n1,
    [data-theme='dark'] .mx-xl-n1 {
      margin-right: -0.25rem !important; }
    [data-theme='dark'] .mb-xl-n1,
    [data-theme='dark'] .my-xl-n1 {
      margin-bottom: -0.25rem !important; }
    [data-theme='dark'] .ml-xl-n1,
    [data-theme='dark'] .mx-xl-n1 {
      margin-left: -0.25rem !important; }
    [data-theme='dark'] .m-xl-n2 {
      margin: -0.5rem !important; }
    [data-theme='dark'] .mt-xl-n2,
    [data-theme='dark'] .my-xl-n2 {
      margin-top: -0.5rem !important; }
    [data-theme='dark'] .mr-xl-n2,
    [data-theme='dark'] .mx-xl-n2 {
      margin-right: -0.5rem !important; }
    [data-theme='dark'] .mb-xl-n2,
    [data-theme='dark'] .my-xl-n2 {
      margin-bottom: -0.5rem !important; }
    [data-theme='dark'] .ml-xl-n2,
    [data-theme='dark'] .mx-xl-n2 {
      margin-left: -0.5rem !important; }
    [data-theme='dark'] .m-xl-n3 {
      margin: -1rem !important; }
    [data-theme='dark'] .mt-xl-n3,
    [data-theme='dark'] .my-xl-n3 {
      margin-top: -1rem !important; }
    [data-theme='dark'] .mr-xl-n3,
    [data-theme='dark'] .mx-xl-n3 {
      margin-right: -1rem !important; }
    [data-theme='dark'] .mb-xl-n3,
    [data-theme='dark'] .my-xl-n3 {
      margin-bottom: -1rem !important; }
    [data-theme='dark'] .ml-xl-n3,
    [data-theme='dark'] .mx-xl-n3 {
      margin-left: -1rem !important; }
    [data-theme='dark'] .m-xl-n4 {
      margin: -1.5rem !important; }
    [data-theme='dark'] .mt-xl-n4,
    [data-theme='dark'] .my-xl-n4 {
      margin-top: -1.5rem !important; }
    [data-theme='dark'] .mr-xl-n4,
    [data-theme='dark'] .mx-xl-n4 {
      margin-right: -1.5rem !important; }
    [data-theme='dark'] .mb-xl-n4,
    [data-theme='dark'] .my-xl-n4 {
      margin-bottom: -1.5rem !important; }
    [data-theme='dark'] .ml-xl-n4,
    [data-theme='dark'] .mx-xl-n4 {
      margin-left: -1.5rem !important; }
    [data-theme='dark'] .m-xl-n5 {
      margin: -3rem !important; }
    [data-theme='dark'] .mt-xl-n5,
    [data-theme='dark'] .my-xl-n5 {
      margin-top: -3rem !important; }
    [data-theme='dark'] .mr-xl-n5,
    [data-theme='dark'] .mx-xl-n5 {
      margin-right: -3rem !important; }
    [data-theme='dark'] .mb-xl-n5,
    [data-theme='dark'] .my-xl-n5 {
      margin-bottom: -3rem !important; }
    [data-theme='dark'] .ml-xl-n5,
    [data-theme='dark'] .mx-xl-n5 {
      margin-left: -3rem !important; }
    [data-theme='dark'] .m-xl-auto {
      margin: auto !important; }
    [data-theme='dark'] .mt-xl-auto,
    [data-theme='dark'] .my-xl-auto {
      margin-top: auto !important; }
    [data-theme='dark'] .mr-xl-auto,
    [data-theme='dark'] .mx-xl-auto {
      margin-right: auto !important; }
    [data-theme='dark'] .mb-xl-auto,
    [data-theme='dark'] .my-xl-auto {
      margin-bottom: auto !important; }
    [data-theme='dark'] .ml-xl-auto,
    [data-theme='dark'] .mx-xl-auto {
      margin-left: auto !important; } }
  [data-theme='dark'] .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0); }
  [data-theme='dark'] .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }
  [data-theme='dark'] .text-justify {
    text-align: justify !important; }
  [data-theme='dark'] .text-wrap {
    white-space: normal !important; }
  [data-theme='dark'] .text-nowrap {
    white-space: nowrap !important; }
  [data-theme='dark'] .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  [data-theme='dark'] .text-left {
    text-align: left !important; }
  [data-theme='dark'] .text-right {
    text-align: right !important; }
  [data-theme='dark'] .text-center {
    text-align: center !important; }
  @media (min-width: 576px) {
    [data-theme='dark'] .text-sm-left {
      text-align: left !important; }
    [data-theme='dark'] .text-sm-right {
      text-align: right !important; }
    [data-theme='dark'] .text-sm-center {
      text-align: center !important; } }
  @media (min-width: 768px) {
    [data-theme='dark'] .text-md-left {
      text-align: left !important; }
    [data-theme='dark'] .text-md-right {
      text-align: right !important; }
    [data-theme='dark'] .text-md-center {
      text-align: center !important; } }
  @media (min-width: 992px) {
    [data-theme='dark'] .text-lg-left {
      text-align: left !important; }
    [data-theme='dark'] .text-lg-right {
      text-align: right !important; }
    [data-theme='dark'] .text-lg-center {
      text-align: center !important; } }
  @media (min-width: 1200px) {
    [data-theme='dark'] .text-xl-left {
      text-align: left !important; }
    [data-theme='dark'] .text-xl-right {
      text-align: right !important; }
    [data-theme='dark'] .text-xl-center {
      text-align: center !important; } }
  [data-theme='dark'] .text-lowercase {
    text-transform: lowercase !important; }
  [data-theme='dark'] .text-uppercase {
    text-transform: uppercase !important; }
  [data-theme='dark'] .text-capitalize {
    text-transform: capitalize !important; }
  [data-theme='dark'] .font-weight-light {
    font-weight: 300 !important; }
  [data-theme='dark'] .font-weight-lighter {
    font-weight: lighter !important; }
  [data-theme='dark'] .font-weight-normal {
    font-weight: 400 !important; }
  [data-theme='dark'] .font-weight-bold {
    font-weight: 700 !important; }
  [data-theme='dark'] .font-weight-bolder {
    font-weight: bolder !important; }
  [data-theme='dark'] .font-italic {
    font-style: italic !important; }
  [data-theme='dark'] .text-white {
    color: #fff !important; }
  [data-theme='dark'] .text-primary {
    color: #3A52A2 !important; }
  [data-theme='dark'] a.text-primary:hover, [data-theme='dark'] a.text-primary:focus {
    color: #26356a !important; }
  [data-theme='dark'] .text-secondary {
    color: #6c757d !important; }
  [data-theme='dark'] a.text-secondary:hover, [data-theme='dark'] a.text-secondary:focus {
    color: #494f54 !important; }
  [data-theme='dark'] .text-success {
    color: #3AB54A !important; }
  [data-theme='dark'] a.text-success:hover, [data-theme='dark'] a.text-success:focus {
    color: #277b32 !important; }
  [data-theme='dark'] .text-info {
    color: #25AAE2 !important; }
  [data-theme='dark'] a.text-info:hover, [data-theme='dark'] a.text-info:focus {
    color: #167aa5 !important; }
  [data-theme='dark'] .text-warning {
    color: #FFE89B !important; }
  [data-theme='dark'] a.text-warning:hover, [data-theme='dark'] a.text-warning:focus {
    color: #ffd64f !important; }
  [data-theme='dark'] .text-danger {
    color: #BF1E2E !important; }
  [data-theme='dark'] a.text-danger:hover, [data-theme='dark'] a.text-danger:focus {
    color: #7d141e !important; }
  [data-theme='dark'] .text-light, [data-theme='dark'] .text-themed {
    color: #f8f9fa !important; }
  [data-theme='dark'] a.text-light:hover, [data-theme='dark'] a.text-themed:hover, [data-theme='dark'] a.text-light:focus, [data-theme='dark'] a.text-themed:focus {
    color: #cbd3da !important; }
  [data-theme='dark'] .text-dark, [data-theme='dark'] .text-themed-inverted {
    color: #343a40 !important; }
  [data-theme='dark'] a.text-dark:hover, [data-theme='dark'] a.text-themed-inverted:hover, [data-theme='dark'] a.text-dark:focus, [data-theme='dark'] a.text-themed-inverted:focus {
    color: #121416 !important; }
  [data-theme='dark'] .text-body {
    color: #d3d3d3 !important; }
  [data-theme='dark'] .text-muted {
    color: #6c757d !important; }
  [data-theme='dark'] .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important; }
  [data-theme='dark'] .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important; }
  [data-theme='dark'] .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0; }
  [data-theme='dark'] .text-decoration-none {
    text-decoration: none !important; }
  [data-theme='dark'] .text-break {
    word-break: break-word !important;
    word-wrap: break-word !important; }
  [data-theme='dark'] .text-reset {
    color: inherit !important; }
  [data-theme='dark'] .visible {
    visibility: visible !important; }
  [data-theme='dark'] .invisible {
    visibility: hidden !important; }
  @media print {
    [data-theme='dark'] *,
    [data-theme='dark'] *::before,
    [data-theme='dark'] *::after {
      text-shadow: none !important;
      box-shadow: none !important; }
    [data-theme='dark'] a:not(.btn) {
      text-decoration: underline; }
    [data-theme='dark'] abbr[title]::after {
      content: " (" attr(title) ")"; }
    [data-theme='dark'] pre {
      white-space: pre-wrap !important; }
    [data-theme='dark'] pre,
    [data-theme='dark'] blockquote {
      border: 1px solid #adb5bd;
      page-break-inside: avoid; }
    [data-theme='dark'] tr,
    [data-theme='dark'] img {
      page-break-inside: avoid; }
    [data-theme='dark'] p,
    [data-theme='dark'] h2,
    [data-theme='dark'] h3 {
      orphans: 3;
      widows: 3; }
    [data-theme='dark'] h2,
    [data-theme='dark'] h3 {
      page-break-after: avoid; }
    @page {
      [data-theme='dark'] {
        size: a3; } }
    [data-theme='dark'] body {
      min-width: 992px !important; }
    [data-theme='dark'] .container {
      min-width: 992px !important; }
    [data-theme='dark'] .navbar {
      display: none; }
    [data-theme='dark'] .badge {
      border: 1px solid #000; }
    [data-theme='dark'] .table {
      border-collapse: collapse !important; }
      [data-theme='dark'] .table td,
      [data-theme='dark'] .table th {
        background-color: #fff !important; }
    [data-theme='dark'] .table-bordered th,
    [data-theme='dark'] .table-bordered td {
      border: 1px solid #dee2e6 !important; }
    [data-theme='dark'] .table-dark {
      color: inherit; }
      [data-theme='dark'] .table-dark th,
      [data-theme='dark'] .table-dark td,
      [data-theme='dark'] .table-dark thead th,
      [data-theme='dark'] .table-dark tbody + tbody {
        border-color: #343a40; }
    [data-theme='dark'] .table .thead-dark th {
      color: inherit;
      border-color: #343a40; } }
  [data-theme='dark'] .navbar {
    background-color: #111111 !important; }
  [data-theme='dark'] .dropdown-menu {
    box-shadow: 0 0.5rem 1rem black;
    color: #f8f9fa;
    background: #121416; }
  [data-theme='dark'] .form-control:not(.btn) {
    background-color: #121416;
    color: #f8f9fa; }
  [data-theme='dark'] .btn-light, [data-theme='dark'] .popover, [data-theme='dark'] .popover-header, [data-theme='dark'] .popover-body, [data-theme='dark'] .status {
    background-color: #121416 !important;
    color: #f8f9fa !important;
    border-color: #121416; }
  [data-theme='dark'] .btn-link {
    color: white; }
  [data-theme='dark'] .bs-popover-right > .arrow::after, [data-theme='dark'] .bs-popover-auto[x-placement^="right"] > .arrow::after {
    border-right-color: #121416; }
  [data-theme='dark'] .bs-popover-left > .arrow::after, [data-theme='dark'] .bs-popover-auto[x-placement^="left"] > .arrow::after {
    border-left-color: #121416; }
  [data-theme='dark'] .bs-popover-top > .arrow::after, [data-theme='dark'] .bs-popover-auto[x-placement^="top"] > .arrow::after {
    border-top-color: #121416; }
  [data-theme='dark'] .bs-popover-bottom > .arrow::after, [data-theme='dark'] .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
    border-bottom-color: #121416; }
  [data-theme='dark'] a {
    color: #3395ff; }
  [data-theme='dark'] .badge, [data-theme='dark'] .btn-success, [data-theme='dark'] .bg-warning {
    color: black; }
  [data-theme='dark'] .badge-dark, [data-theme='dark'] .badge-secondary, [data-theme='dark'] .btn-primary {
    color: #f8f9fa; }
  [data-theme='dark'] .input-group-text {
    border-color: #121416; }
  [data-theme='dark'] .btn-secondary, [data-theme='dark'] .btn-info {
    color: white; }
  [data-theme='dark'] .page-item.disabled {
    opacity: 0.5; }
  [data-theme='dark'] .bg-light, [data-theme='dark'] .bg-themed-inverted {
    color: black;
    background-color: #e9ecef !important;
    border-radius: 0.2em; }
  [data-theme='dark'] .fc-day-today {
    background-color: transparent !important;
    border: 0.2em solid #17a2b8 !important; }
  [data-theme='dark'] .fc-daygrid-dot-event {
    color: white !important; }
  [data-theme='dark'] .table {
    border-collapse: separate !important;
    border-spacing: 0; }
  [data-theme='dark'] table.table-primary > td, [data-theme='dark'] table.table-primary th {
    border: 0.3em solid #99a5cf !important; }
  [data-theme='dark'] table.table-primary > * {
    color: white !important;
    background-color: #222 !important; }
  [data-theme='dark'] #event_row.table-primary {
    border: 0.3em solid #99a5cf !important;
    background-color: #222 !important;
    color: white !important; }
  [data-theme='dark'] table.table-secondary > td, [data-theme='dark'] table.table-secondary th {
    border: 0.3em solid #b3b7bb !important; }
  [data-theme='dark'] table.table-secondary > * {
    color: white !important;
    background-color: #222 !important; }
  [data-theme='dark'] #event_row.table-secondary {
    border: 0.3em solid #b3b7bb !important;
    background-color: #222 !important;
    color: white !important; }
  [data-theme='dark'] table.table-success > td, [data-theme='dark'] table.table-success th {
    border: 0.3em solid #99d9a1 !important; }
  [data-theme='dark'] table.table-success > * {
    color: white !important;
    background-color: #222 !important; }
  [data-theme='dark'] #event_row.table-success {
    border: 0.3em solid #99d9a1 !important;
    background-color: #222 !important;
    color: white !important; }
  [data-theme='dark'] table.table-info > td, [data-theme='dark'] table.table-info th {
    border: 0.3em solid #8ed3f0 !important; }
  [data-theme='dark'] table.table-info > * {
    color: white !important;
    background-color: #222 !important; }
  [data-theme='dark'] #event_row.table-info {
    border: 0.3em solid #8ed3f0 !important;
    background-color: #222 !important;
    color: white !important; }
  [data-theme='dark'] table.table-warning > td, [data-theme='dark'] table.table-warning th {
    border: 0.3em solid #fff3cb !important; }
  [data-theme='dark'] table.table-warning > * {
    color: white !important;
    background-color: #222 !important; }
  [data-theme='dark'] #event_row.table-warning {
    border: 0.3em solid #fff3cb !important;
    background-color: #222 !important;
    color: white !important; }
  [data-theme='dark'] table.table-danger > td, [data-theme='dark'] table.table-danger th {
    border: 0.3em solid #de8a92 !important; }
  [data-theme='dark'] table.table-danger > * {
    color: white !important;
    background-color: #222 !important; }
  [data-theme='dark'] #event_row.table-danger {
    border: 0.3em solid #de8a92 !important;
    background-color: #222 !important;
    color: white !important; }
  [data-theme='dark'] table.table-light > td, [data-theme='dark'] table.table-light th {
    border: 0.3em solid #fbfcfc !important; }
  [data-theme='dark'] table.table-light > * {
    color: white !important;
    background-color: #222 !important; }
  [data-theme='dark'] #event_row.table-light {
    border: 0.3em solid #fbfcfc !important;
    background-color: #222 !important;
    color: white !important; }
  [data-theme='dark'] table.table-dark > td, [data-theme='dark'] table.table-dark th {
    border: 0.3em solid #95999c !important; }
  [data-theme='dark'] table.table-dark > * {
    color: white !important;
    background-color: #222 !important; }
  [data-theme='dark'] #event_row.table-dark {
    border: 0.3em solid #95999c !important;
    background-color: #222 !important;
    color: white !important; }
  [data-theme='dark'] del {
    color: black;
    background-color: #dc3545;
    border-radius: 3px; }
  [data-theme='dark'] ins {
    color: black;
    background-color: #28a745;
    border-radius: 3px; }
  [data-theme='dark'] pre {
    background: #6c757d;
    color: white; }
  [data-theme='dark'] .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(51, 149, 255, 0.7) !important; }
  [data-theme='dark'] .source {
    color: white !important; }
  [data-theme='dark'] .embed_container {
    border-color: #3853a4 !important;
    background: #222;
    color: #f8f9fa; }
  [data-theme='dark'] input:-webkit-autofill,
  [data-theme='dark'] input:-webkit-autofill:hover,
  [data-theme='dark'] input:-webkit-autofill:focus,
  [data-theme='dark'] textarea:-webkit-autofill,
  [data-theme='dark'] textarea:-webkit-autofill:hover,
  [data-theme='dark'] textarea:-webkit-autofill:focus,
  [data-theme='dark'] select:-webkit-autofill,
  [data-theme='dark'] select:-webkit-autofill:hover,
  [data-theme='dark'] select:-webkit-autofill:focus {
    border: 1px solid #17a2b8;
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px rgba(23, 162, 184, 0.3) inset;
    transition: background-color 5000s ease-in-out 0s; }
  [data-theme='dark'] .editor-toolbar > a {
    color: white !important; }
  [data-theme='dark'] .editor-toolbar > a:hover {
    background: transparent !important; }
  [data-theme='dark'] .editor-toolbar > a.active {
    background: #17a2b8 !important; }
  [data-theme='dark'] .cm-s-easymde {
    color: white;
    background-color: #121416;
    border-color: #bbb; }
  [data-theme='dark'] .CodeMirror-cursor {
    border-color: white !important; }
  [data-theme='dark'] .modal {
    overflow-y: auto !important; }
  [data-theme='dark'] .text-muted {
    color: #c9c9c9 !important; }
