[data-theme='dark'] {
    $theme-colors: (
      "success": #3AB54A,
      "warning": #FFE89B,
      "danger": #BF1E2E,
      "info": #25AAE2,
      "primary": #3A52A2
    );
    @import "custom-variables";
    @import 'node_modules/@forevolve/bootstrap-dark/scss/bootstrap-dark.scss';
    background: #222;
    color: $gray-100;
    $darktheme: #121416;
    .navbar {
        background-color: #111111 !important;
    }
    .dropdown-menu {
        box-shadow: 0 0.5rem 1rem black;
        color: $gray-100;
        background: $darktheme;
    }
    .form-control:not(.btn) {
        background-color: $darktheme;
        color: $gray-100;
    }
    .btn-light, .popover, .popover-header, .popover-body, .status {
        background-color: $darktheme !important;
        color: $gray-100 !important;
        border-color: $darktheme;
    }
    .btn-link {
        color: white;
    }
    .bs-popover-right > .arrow::after {
        border-right-color: $darktheme;
    }
    .bs-popover-left > .arrow::after {
        border-left-color: $darktheme;
    }
    .bs-popover-top > .arrow::after {
        border-top-color: $darktheme;
    }
    .bs-popover-bottom > .arrow::after {
        border-bottom-color: $darktheme;
    }
    a {
        color: $blue;
    }
    .badge, .btn-success, .bg-warning {
        color: black;
    }
    .badge-dark, .badge-secondary, .btn-primary {
        color: $gray-100;
    }
    .input-group-text {
        border-color: $darktheme;
    }
    .btn-secondary, .btn-info {
        color: white;
    }
    .page-item.disabled {
        opacity: 0.5;
    }
    .bg-light {
        color: black;
        background-color: $gray-200 !important;
        border-radius: 0.2em;
    }
    .fc-day-today {
        background-color: transparent !important;
        border: 0.2em solid $info !important;
    }
    .fc-daygrid-dot-event {
        color: white !important;
    }
    .table {
        border-collapse: separate !important;
        border-spacing: 0;
    }
    @each $color, $value in $theme-colors {
        table.table-#{$color} {
            > td,th {
                border: 0.3em solid theme-color-level($color, -6) !important;
            }
            > * {
                color: white !important;
                background-color: #222 !important;
            }
        }
        #event_row.table-#{$color} {
            border: 0.3em solid theme-color-level($color, -6) !important;
            background-color: #222 !important;
            color: white !important;
        }
    }
    del {
      color: black;
      background-color: $danger;
      border-radius: 3px;
    }
    ins {
      color: black;
      background-color: $success;
      border-radius: 3px;
    }
    pre {
        background: $gray-600;
        color: white;
    }
    .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 $input-focus-width rgba($primary, 0.7) !important;
    }
    .source {
        color: white !important;
    }
    .embed_container {
        border-color: #3853a4 !important;
        background: #222;
        color: $gray-100;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: 1px solid $info;
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0px 1000px rgba($info, .3) inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    .editor-toolbar > a {
        color: white !important;
    }
    .editor-toolbar > a:hover {
        background: transparent !important;
    }
    .editor-toolbar > a.active {
        background: $info !important;
    }
    .cm-s-easymde {
        color: white;
        background-color: $darktheme;
        border-color: #bbb;
    }
    .CodeMirror-cursor {
        border-color: white !important;
    }
    .modal {
      overflow-y: auto !important; //Bootstrap Dark Theme overrides this to none for some insane reason so we need to change it back
    }
    .text-muted {
        color: #c9c9c9 !important;
    }
}
