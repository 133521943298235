$font-family-sans-serif:  "Open Sans", sans-serif;
//Make it look less primary school
$font-size-base: 0.85rem;
$theme-colors: (
  "success": #5cb85c,
  "warning": #f0ad4e,
  "danger": #d9534f,
  "info": #5bc0de,
  "primary": #3A52A2
) !default;
$enable-shadows: true;
$alert-color-level: 10;
